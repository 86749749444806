import * as React from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useVirtualizer } from '@tanstack/react-virtual';
import useFetch from '../../hooks/useFetch';
import { Account, DataVersion, ReactionExtended, Reflection, ReflectionsListEvent } from '../../store/Models';
import { debounce, getClassRussian, makeDateHeaderRussian, makeDateTimeRussian } from '../../utils';
import ReflectionComponent from '../Reflection';
import FocusLongPressMenu from './FocusLongPressMenu';
import ReactionMenu from './ReactionMenu';

interface ReflectionModuleProps {
    currentUser: Account;
    visible: boolean;
    selectedReflection: Reflection | null;
    items: Reflection[];
    onSelectReflection: (item: Reflection | null) => void;
    showContextMenu: boolean;
    onOpenContextMenu: () => void;
    onCloseContextMenu: () => void;
    onDeleteReflection: (item: Reflection) => void;
    onStartEdit: (item: Reflection) => void;
    onStartReply: (item: Reflection) => void;
    onAccountClick: (account: Account) => void;
}

const ReflectionModule: React.FC<ReflectionModuleProps> = ({ currentUser, visible,
    selectedReflection, items, onSelectReflection, showContextMenu, onOpenContextMenu, onCloseContextMenu,
    onDeleteReflection, onStartEdit, onStartReply, onAccountClick }) => {
    const fetch = useFetch();

    const virtuosoRef = React.useRef<VirtuosoHandle>(null);
    const reflectionsListRef = React.useRef<HTMLDivElement>(null);

    const tanstackListRef = React.useRef<HTMLDivElement>(null);
    const rowVirtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => tanstackListRef.current,
        estimateSize: () => 150 // Estimate average size
    });

    const [lastScrollPosition, setLastScrollPosition] = React.useState<number>(0);
    const [showScroller, setShowScroller] = React.useState<boolean>(false);

    const [selectedReactions, setSelectedReactions] = React.useState<ReactionExtended[]>([]);

    const showSelectedReactions = (postId: number, reactionCode: number) => {
        fetch('api/reflection/getReactions?postId=' + postId + '&reactionCode=' + reactionCode)
            .then(res => res.json() as Promise<ReactionExtended[]>)
            .then(data => {
                setSelectedReactions(data);
            });
    }

    React.useEffect(() => {
        let unreadReflections = getUnreadReflections();
        if (reflectionsListRef.current != null) {
            if (visible) {
                reflectionsListRef.current.style.display = '';
                if (unreadReflections.length > 0) { //scroll to first unread message and show scroller with unread counter
                    setToReflection(unreadReflections[0].id);
                    window.setTimeout(() => {
                        setShowScroller(true);
                    }, 100);
                }
                else {
                    reflectionsListRef.current.scrollTop = reflectionsListRef.current.scrollHeight;
                }
            }
            else {
                reflectionsListRef.current.style.display = 'none';
            }
        }
        else if (tanstackListRef.current != null) {
            if (visible) {
                tanstackListRef.current.style.display = '';
                if (unreadReflections.length > 0) {
                    setToReflection(unreadReflections[0].id);
                }
                else {
                    window.setTimeout(() => {
                        rowVirtualizer.scrollToIndex(items.length - 1, { align: 'end' });
                    }, 100);
                }
            }
            else {
                tanstackListRef.current.style.display = 'none';
            }
        }
    }, [visible]);

    const getInitialTopMostIndex = (): number => {
        let unreadReflections = getUnreadReflections();
        if (unreadReflections.length > 0) {
            return items.findIndex(i => i.id === unreadReflections[0].id);
        }
        else return items.length - 1;
    }

    const pressTimer = React.useRef(0);

    const onContextMenu = (reflection: Reflection, isImmediate: boolean) => {
        //prevent window collapsing on iOS
        if (window.Telegram.WebApp.platform === 'ios') {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        }

        if (reflection.id == 0) { //not allow to use context menu on temporary object dummy
            return;
        }

        if (isImmediate == false) {
            pressTimer.current = window.setTimeout(() => {
                onSelectReflection(reflection);
                onOpenContextMenu();
            }, 800);
        }
        else {
            onSelectReflection(reflection);
            onOpenContextMenu();
        }
    }

    const onTouchMove = () => {
        if (selectedReflection != null) {
            onSelectReflection(null);
        }
        window.clearTimeout(pressTimer.current);
    }

    const onTouchStop = () => {
        window.clearTimeout(pressTimer.current);
    }

    const scrollToReflection = (id: number) => {
        if (reflectionsListRef.current != null) {
            var targetElement = document.getElementById(id.toString());

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        else if (virtuosoRef.current != null) {
            var targetIndex = items.findIndex(i => i.id === id);
            virtuosoRef.current.scrollToIndex(targetIndex);
        }
        else if (tanstackListRef.current != null) {
            var targetIndex = items.findIndex(i => i.id === id);
            rowVirtualizer.scrollToIndex(targetIndex, { align: 'center' });
        }
    }

    const setToReflection = (id: number) => {
        if (reflectionsListRef.current != null) {
            var targetElement = document.getElementById(id.toString());

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
            }
        }
        else if (virtuosoRef.current != null) {
            var targetIndex = items.findIndex(i => i.id === id);
            virtuosoRef.current.scrollToIndex(targetIndex);
        }
        else if (tanstackListRef.current != null) {
            var targetIndex = items.findIndex(i => i.id === id);
            rowVirtualizer.scrollToIndex(targetIndex, { align: 'center' });
        }
    }

    const scrollToBottom = () => {
        if (reflectionsListRef.current != null) {
            reflectionsListRef.current.scrollTo({
                top: reflectionsListRef.current.scrollHeight,
                behavior: 'smooth'
            });
            setShowScroller(false);
        }
        else if (virtuosoRef.current != null) {
            virtuosoRef.current.scrollToIndex(items.length - 1);
        }
        else if (tanstackListRef.current != null) {
            rowVirtualizer.scrollToIndex(items.length - 1, { align: 'end' });
        }
        readAllUnreadReflections();
    }

    const onScroll = () => {
        if (reflectionsListRef.current != null) {
            const scrollTop = reflectionsListRef.current.scrollTop;
            const scrollHeight = reflectionsListRef.current.scrollHeight;
            const offsetHeight = reflectionsListRef.current.offsetHeight;

            if (scrollTop < scrollHeight - offsetHeight - 30) {
                setShowScroller(true);
            } else {
                setShowScroller(false);
            }

            setLastScrollPosition(scrollTop <= 0 ? 0 : scrollTop);
        }
        else if (tanstackListRef.current != null) {
            const scrollTop = tanstackListRef.current.scrollTop;
            const scrollHeight = tanstackListRef.current.scrollHeight;
            const offsetHeight = tanstackListRef.current.offsetHeight;

            if (scrollTop < scrollHeight - offsetHeight - 30) {
                setShowScroller(true);
            } else {
                setShowScroller(false);
            }
        }
    }

    const handleRangeChanged = (range: { startIndex: number; endIndex: number }) => {
        console.log('Visible range:', range);
        // You can use this to track where the user has scrolled
        setShowScroller(range.endIndex < items.length - 1);
    };

    const getUnreadReflections = (): Reflection[] => {
        return items.filter(r => r.isRead == 0 && r.post.publishAccount.id != currentUser.id && new Date(r.post.publishDateTime) > new Date(currentUser.activeFrom));
    }

    const readAllUnreadReflections = () => {
        let unreadReflections = getUnreadReflections();
        for (let i = 0; i < unreadReflections.length; i++) {
            unreadReflections[i].isRead = 1;
            fetch('/api/counter/readPost?postId=' + unreadReflections[i].post.id);
        }
    }

    const isFirstOnItsDateInLocalTime = (item: Reflection): boolean => {
        // Extract the target date from the target object
        let targetDateCopy = new Date(item.post.publishDateTime);
        targetDateCopy.setHours(targetDateCopy.getHours() - targetDateCopy.getTimezoneOffset() / 60); // to local time!
        const targetDate = new Date(targetDateCopy);

        // Filter all objects that share the same date as the target object
        const sameDateObjects = items.filter(r => {
            let dateCopy = new Date(r.post.publishDateTime);
            dateCopy.setHours(dateCopy.getHours() - dateCopy.getTimezoneOffset() / 60); // to local time!
            const objDate = new Date(dateCopy);
            return objDate.toDateString() === targetDate.toDateString();
        });

        // Find the object with the minimal date and time among the same date objects
        const minDateObject = sameDateObjects.reduce((minObj, obj) => {
            const objDate = new Date(obj.post.publishDateTime);
            return objDate < new Date(minObj.post.publishDateTime) ? obj : minObj;
        }, sameDateObjects[0]);

        // Check if the target object is the one with the minimal date
        return item === minDateObject;
    }

    const isFirstByUser = (item: Reflection): boolean => {
        // conditions:
        // 1. user that wrote reflection is newbie after 2024-09-01
        // 2. user is not admin, reflection is not reply
        // 3. this is first reflection posted by this user since 2024-09-01
        var candidates = items.filter(i => i.post.publishAccount.id == item.post.publishAccount.id &&
            new Date(item.post.publishAccount.activeFrom) > new Date(2024, 8, 1) &&
            i.post.publishAccount.isAdmin == 0 && i.replyReflection == null);
        return candidates.length > 0 && item.id == candidates[0].id;
    }

    const isSecondByUser = (item: Reflection): boolean => {
        // conditions:
        // 1. user that wrote reflection is newbie after 2024-09-01
        // 2. user is not admin, reflection is not reply
        // 3. this is second reflection posted by this user since 2024-09-01
        var candidates = items.filter(i => i.post.publishAccount.id == item.post.publishAccount.id &&
            new Date(item.post.publishAccount.activeFrom) > new Date(2024, 8, 1) &&
            i.post.publishAccount.isAdmin == 0 && i.replyReflection == null);
        return candidates.length > 1 && item.id == candidates[1].id;
    }

    const batchSize = 50;

    return (
        <div ref={reflectionsListRef} className="reflection-module-layout" onScroll={onScroll}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {items.slice(items.length - batchSize, items.length).map((reflection: Reflection, index: number) =>
                            <div className="mt-1 mb-1" key={reflection.id} data-id={reflection.id}
                                data-my={reflection.post.publishAccount.id === currentUser.id}>
                                {isFirstOnItsDateInLocalTime(reflection) && <div className="date-header">
                                    <span className="date-header-span-reflections">{makeDateHeaderRussian(reflection.post.publishDateTime.toString())}</span></div>}
                                <ReflectionComponent currentUser={currentUser} reflection={reflection}
                                    isFirst={isFirstByUser(reflection)}
                                    isSecond={isSecondByUser(reflection)}
                                    isExpanded={false}
                                    onContextMenu={onContextMenu}
                                    onReactionContextMenu={showSelectedReactions}
                                    onTouchMove={onTouchMove}
                                    onTouchStop={onTouchStop}
                                    onGoToReply={(r) => { scrollToReflection(r.id); }}
                                    onAccountClick={onAccountClick}
                                    onExpand={() => { }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {(showContextMenu && selectedReflection != null) && <FocusLongPressMenu user={currentUser} visible={true}
                reflection={selectedReflection}
                isFirst={isFirstByUser(selectedReflection)}
                isSecond={isSecondByUser(selectedReflection)}
                onWriteReply={() => { onStartReply(selectedReflection) }}
                onGoToProfile={() => { }}
                onStartEdit={() => { onStartEdit(selectedReflection) }}
                onDelete={() => { onDeleteReflection(selectedReflection) }}
                onGoToReply={() => {
                    if (selectedReflection.replyReflection != null) {
                        scrollToReflection(selectedReflection.replyReflection.id)
                    }
                }}
                onClose={onCloseContextMenu}
            />}
            {selectedReactions.length > 0 && <ReactionMenu reactionCode={selectedReactions[0].reactionCode}
                accounts={selectedReactions.map(s => s.account)} onClose={() => setSelectedReactions([])}
            />}
            {showScroller && <div className="bottom-scroller" onClick={scrollToBottom}>
                {getUnreadReflections().length > 0 &&
                    <div className="bottom-scroller-counter">{getUnreadReflections().length}</div>}
                <i className="fa fa-chevron-down" />
            </div>}
        </div>)
    /*
    else return (
        <div ref={reflectionsListRef} className="reflection-module-layout" onScroll={onScroll}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <VirtualizedReflectionList items={items} currentUser={currentUser}
                            selectedReflection={selectedReflection} onSelectReflection={onSelectReflection}
                            showContextMenu={showContextMenu} onOpenContextMenu={onOpenContextMenu}
                            onCloseContextMenu={onCloseContextMenu} onDeleteReflection={onDeleteReflection}
                            onStartEdit={onStartEdit} onStartReply={onStartReply}
                            onAccountClick={onAccountClick}
                        />
                    </div>
                </div>
            </div>
        </div>
        )
        */
    /*
    else return (<div style={{
        display: visible ? '' : 'none'
    }}>
        <Virtuoso
            ref={virtuosoRef}
            data={items}
            style={{ height: window.innerHeight - 142 }}
            className="reflection-module-layout"
            initialTopMostItemIndex={getInitialTopMostIndex()}
            rangeChanged={handleRangeChanged}
            increaseViewportBy={1000}
            itemContent={(_, reflection) => (
                <div className="mt-1 mb-1" key={reflection.id} data-id={reflection.id}
                    data-my={reflection.post.publishAccount.id === currentUser.id}>
                    {isFirstOnItsDateInLocalTime(reflection) && <div className="date-header">
                        <span className="date-header-span-reflections">{makeDateHeaderRussian(reflection.post.publishDateTime.toString())}</span></div>}
                    <ReflectionComponent currentUser={currentUser} reflection={reflection}
                        isFirst={isFirstByUser(reflection)}
                        isSecond={isSecondByUser(reflection)}
                        isExpanded={false}
                        onContextMenu={onContextMenu}
                        onTouchMove={onTouchMove}
                        onTouchStop={onTouchStop}
                        onGoToReply={(r) => { scrollToReflection(r.id); }}
                        onAccountClick={onAccountClick}
                        onExpand={() => { }}
                    />
                </div>
            )} />
        {(showContextMenu && selectedReflection != null) && <FocusLongPressMenu user={currentUser} visible={true}
            reflection={selectedReflection}
            isFirst={isFirstByUser(selectedReflection)}
            isSecond={isSecondByUser(selectedReflection)}
            onWriteReply={() => { onStartReply(selectedReflection) }}
            onGoToProfile={() => { }}
            onStartEdit={() => { onStartEdit(selectedReflection) }}
            onDelete={() => { onDeleteReflection(selectedReflection) }}
            onGoToReply={() => {
                if (selectedReflection.replyReflection != null) {
                    scrollToReflection(selectedReflection.replyReflection.id)
                }
            }}
            onClose={onCloseContextMenu}
        />}
        {showScroller && <div className="bottom-scroller" onClick={scrollToBottom}>
            {getUnreadReflections().length > 0 &&
                <div className="bottom-scroller-counter">{getUnreadReflections().length}</div>}
            <i className="fa fa-chevron-down" />
        </div>}
    </div>);
    */
    /*
    else {
        return (
            <div ref={tanstackListRef} className="reflection-module-layout" onScroll={onScroll}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ height: `${rowVirtualizer.getTotalSize()}px`, position: 'relative' }}>
                                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                                    const reflection = items[virtualRow.index];
                                    return (<div
                                        key={reflection.id}
                                        data-index={virtualRow.index}
                                        ref={rowVirtualizer.measureElement}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            transform: `translateY(${virtualRow.start}px)`
                                        }}
                                    >
                                        <div className="mt-1 mb-1" key={reflection.id} data-id={reflection.id}
                                            data-my={reflection.post.publishAccount.id === currentUser.id}>
                                            {isFirstOnItsDateInLocalTime(reflection) && <div className="date-header">
                                                <span className="date-header-span-reflections">{makeDateHeaderRussian(reflection.post.publishDateTime.toString())}</span></div>}
                                            <ReflectionComponent currentUser={currentUser} reflection={reflection}
                                                isFirst={isFirstByUser(reflection)}
                                                isSecond={isSecondByUser(reflection)}
                                                isExpanded={false}
                                                onContextMenu={onContextMenu}
                                                onTouchMove={onTouchMove}
                                                onTouchStop={onTouchStop}
                                                onGoToReply={(r) => { scrollToReflection(r.id); }}
                                                onAccountClick={onAccountClick}
                                                onExpand={() => { }}
                                            />
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {(showContextMenu && selectedReflection != null) && <FocusLongPressMenu user={currentUser} visible={true}
                    reflection={selectedReflection}
                    isFirst={isFirstByUser(selectedReflection)}
                    isSecond={isSecondByUser(selectedReflection)}
                    onWriteReply={() => { onStartReply(selectedReflection) }}
                    onGoToProfile={() => { }}
                    onStartEdit={() => { onStartEdit(selectedReflection) }}
                    onDelete={() => { onDeleteReflection(selectedReflection) }}
                    onGoToReply={() => {
                        if (selectedReflection.replyReflection != null) {
                            scrollToReflection(selectedReflection.replyReflection.id)
                        }
                    }}
                    onClose={onCloseContextMenu}
                />}
                {showScroller && <div className="bottom-scroller" onClick={scrollToBottom}>
                    {getUnreadReflections().length > 0 &&
                        <div className="bottom-scroller-counter">{getUnreadReflections().length}</div>}
                    <i className="fa fa-chevron-down" />
                </div>}
            </div>
        );
    }
    */
};

export default ReflectionModule;

import * as React from 'react';

interface StepperCircleProps {
    activeStep: number;
}

const StepperCircles: React.FC<StepperCircleProps> = ({ activeStep }) => {

    return (

        <ol className="stepper-stepper">
            <li className={`stepper-step ${activeStep === 1 ? 'active' : ''}`}>
                <div className="stepper-step-name">Выбор тарифа</div>
            </li>
            <li className={`stepper-step ${activeStep === 2 ? 'active' : ''}`}>
                <div className="stepper-step-name">Оплата</div>
            </li>
            <li className={`stepper-step ${activeStep === 3 ? 'active' : ''}`}>
                <div className="stepper-step-name">Квитанция</div>
            </li>
        </ol>
        )
    
};

export default StepperCircles;

import React, { useState } from 'react';
import { Account } from '../../../../../store/Models';
import { CostInfo } from '../../../../../subscriptions';
import StepperCircles from '../stepper/StepperCircles';
import './stepper.css';
import StepperRenewal1 from './StepperRenewal1';
import StepperRenewal2 from './StepperRenewal2';
import StepperRenewal3 from './StepperRenewal3';

interface StepperProps {
    currentUser: Account;
    onBack: () => void;
    onFinishPayment: (subscription: CostInfo, paymentMethod: string) => void;
}

const StepperRenewal: React.FC<StepperProps> = ({ currentUser, onBack, onFinishPayment }) => {
    const [activeStep, setActiveStep] = useState(1);
    const [selectedSubscription, setSelectedSubscription] = useState<CostInfo|null>(null);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);

    const handleNext = () => setActiveStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));
    const handleBack = () => setActiveStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));

    return (
        <div className="stepper-container">
            <StepperCircles activeStep={activeStep} />
            {activeStep == 1 && <StepperRenewal1 currentUser={currentUser} onBack={onBack} onNext={(s) => { setSelectedSubscription(s); handleNext(); }} />}
            {(activeStep == 2 && selectedSubscription != null) && <StepperRenewal2 selectedSubscription={selectedSubscription} onBack={handleBack} onNext={(p) => { setSelectedPayment(p); handleNext(); }} />}
            {(activeStep == 3 && selectedSubscription != null) && <StepperRenewal3 paymentMethod={selectedPayment.Key} subscriptionCode={selectedSubscription.code} onBack={handleBack} onNext={() => onFinishPayment(selectedSubscription, selectedPayment.Key)} />}
        </div>
    );
};

export default StepperRenewal;

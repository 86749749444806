import React, { useState, useRef, useEffect } from 'react';

interface AudioPlayerProps {
    src: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);

    // Play/pause toggle
    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    // Set volume
    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (audioRef.current) {
            const newVolume = parseFloat(event.target.value);
            audioRef.current.volume = newVolume;
            setVolume(newVolume);
        }
    };

    // Update progress on audio playback
    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    // Update audio time when changing progress
    const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (audioRef.current) {
            const newTime = (parseFloat(event.target.value) / 100) * duration;
            audioRef.current.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    // Set the duration of the audio when loaded
    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    // Toggle play/pause icon
    const playPauseIcon = isPlaying ? '⏸️' : '▶️';

    // Update state on unmount to prevent memory leaks
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    // Calculate progress percentage
    const progressPercentage = (currentTime / duration) * 100 || 0;

    return (
        <div style={{ width: '100%' }}>
            <audio
                ref={audioRef}
                src={src}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
            />
            {/* Play/Pause Button */}
            <button onClick={togglePlayPause} style={{ marginRight: '8px' }}>
                {playPauseIcon}
            </button>
            {/* Progress Bar */}
            <input
                type="range"
                value={progressPercentage}
                max={100}
                step={0.1}
                onChange={handleProgressChange}
                style={{ width: '50%', marginRight: '8px' }}
            />
            {/* Volume Control */}
            <label>
                Volume:
                <input
                    type="range"
                    value={volume}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={handleVolumeChange}
                    style={{ marginLeft: '8px' }}
                />
            </label>
        </div>
    );
};

export default AudioPlayer;
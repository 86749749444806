﻿import * as React from 'react';

export interface CostInfo {
    code: string;
    displayName: string;
    cost: number;
    rubCost: number;
    subscriptionClass: number;
    signatureValue: string;
}

interface SubscriptionsInfo {
    [key: string]: CostInfo;
}

export const subscriptionsInfo: SubscriptionsInfo = {};

subscriptionsInfo["EXCLUSIVE_INITIAL"] = {
    code: 'EXCLUSIVE_INITIAL', displayName: 'Вступление: Эксклюзив', cost: 19990, rubCost: 3990, subscriptionClass: 5, signatureValue: '0bba982ac401b9f3e300a224ae8bc05a'
};
subscriptionsInfo["BASIC_INITIAL"] = {
    code: 'BASIC_INITIAL', displayName: 'Вступление: Базовый', cost: 100000, rubCost: 20000, subscriptionClass: 10, signatureValue: '3aa2f6de1ae6a69bcb2f40102f8eaa86'
};
subscriptionsInfo["STANDARD_INITIAL"] = {
    code: 'STANDARD_INITIAL', displayName: 'Вступление: Стандарт', cost: 480000, rubCost: 96000, subscriptionClass: 20, signatureValue: '57827b63ad4f051085fa81c912acccf5'
};
subscriptionsInfo["STANDARD_PLUS_INITIAL"] = {
    code: 'STANDARD_PLUS_INITIAL', displayName: 'Вступление: Стандарт Плюс', cost: 1440000, rubCost: 288000, subscriptionClass: 25, signatureValue: 'da7f6f4733c7c2ab272fa3480c8910b8'
};
subscriptionsInfo["BUSINESS_INITIAL"] = {
    code: 'BUSINESS_INITIAL', displayName: 'Вступление: Бизнес', cost: 2400000, rubCost: 480000, subscriptionClass: 30, signatureValue: 'e05d469e3d66926208a10bd394628a5b'
};


subscriptionsInfo["EXCLUSIVE_RENEWAL"] = {
    code: 'EXCLUSIVE_RENEWAL', displayName: 'Продление: Эксклюзив', cost: 10000, rubCost: 2000, subscriptionClass: 5, signatureValue: 'a4ace0e1ee5024846957091c60639619'
};
subscriptionsInfo["BASIC_RENEWAL"] = {
    code: 'BASIC_RENEWAL', displayName: 'Продление: Базовый', cost: 10000, rubCost: 2000, subscriptionClass: 10, signatureValue: 'a4ace0e1ee5024846957091c60639619'
};
subscriptionsInfo["STANDARD_RENEWAL"] = {
    code: 'STANDARD_RENEWAL', displayName: 'Продление: Стандарт', cost: 20000, rubCost: 4000, subscriptionClass: 20, signatureValue: '2464d2d74adc07840c7074e38d5f33bb'
};
subscriptionsInfo["STANDARD_PLUS_RENEWAL"] = {
    code: 'STANDARD_PLUS_RENEWAL', displayName: 'Продление: Стандарт Плюс', cost: 50000, rubCost: 10000, subscriptionClass: 25, signatureValue: 'f4f011c47f050a7172b3523a7eb6f7c2'
};
subscriptionsInfo["BUSINESS_RENEWAL"] = {
    code: 'BUSINESS_RENEWAL', displayName: 'Продление: Бизнес', cost: 50000, rubCost: 10000, subscriptionClass: 30, signatureValue: 'f4f011c47f050a7172b3523a7eb6f7c2'
};
subscriptionsInfo["BUSINESS_PLUS_RENEWAL"] = {
    code: 'BUSINESS_PLUS_RENEWAL', displayName: 'Продление: Бизнес Плюс', cost: 500000, rubCost: 100000, subscriptionClass: 40, signatureValue: '4cacafd9a1b2b669e51ea924640ee62a'
};

subscriptionsInfo["BUSINESS_FROM_EXCLUSIVE_UPGRADE"] = {
    code: 'BUSINESS_FROM_EXCLUSIVE_UPGRADE', displayName: 'Апгрейд на Бизнес с Эксклюзива', cost: 2380010, rubCost: 476010, subscriptionClass: 30, signatureValue: '3ebe41799ec4f96f05e9efeb64006828'
};
subscriptionsInfo["BUSINESS_FROM_BASIC_UPGRADE"] = {
    code: 'BUSINESS_FROM_BASIC_UPGRADE', displayName: 'Апгрейд на Бизнес с Базового', cost: 2300000, rubCost: 460000, subscriptionClass: 30, signatureValue: '632e0ec11de4fa049671de7fe399c334'
};
subscriptionsInfo["BUSINESS_FROM_STANDARD_UPGRADE"] = {
    code: 'BUSINESS_FROM_STANDARD_UPGRADE', displayName: 'Апгрейд на Бизнес со Стандарта', cost: 1920000, rubCost: 384000, subscriptionClass: 30, signatureValue: 'a7a35837b23bcbf1d2c8afaa9a82ed91'
};
subscriptionsInfo["BUSINESS_FROM_STANDARD_PLUS_UPGRADE"] = {
    code: 'BUSINESS_FROM_STANDARD_PLUS_UPGRADE', displayName: 'Апгрейд на Бизнес со Стандарт Плюс', cost: 960000, rubCost: 192000, subscriptionClass: 30, signatureValue: '6c508307bbf6af34f97b6d50be9bb2c3'
};
subscriptionsInfo["STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE"] = {
    code: 'STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE', displayName: 'Апгрейд на Стандарт Плюс с Эксклюзива', cost: 1420010, rubCost: 284000, subscriptionClass: 25, signatureValue: 'a46b7623ade39424842f1160205242a9'
};
subscriptionsInfo["STANDARD_PLUS_FROM_BASIC_UPGRADE"] = {
    code: 'STANDARD_PLUS_FROM_BASIC_UPGRADE', displayName: 'Апгрейд на Стандарт Плюс с Базового', cost: 1340000, rubCost: 268000, subscriptionClass: 25, signatureValue: 'e21af18d57c2c4e4baeff815541ee7bd'
};
subscriptionsInfo["STANDARD_PLUS_FROM_STANDARD_UPGRADE"] = {
    code: 'STANDARD_PLUS_FROM_STANDARD_UPGRADE', displayName: 'Апгрейд на Стандарт Плюс со Стандарта', cost: 960000, rubCost: 192000, subscriptionClass: 25, signatureValue: '6c508307bbf6af34f97b6d50be9bb2c3'
};
subscriptionsInfo["STANDARD_FROM_EXCLUSIVE_UPGRADE"] = {
    code: 'STANDARD_FROM_EXCLUSIVE_UPGRADE', displayName: 'Апгрейд на Стандарт с Эксклюзива', cost: 460010, rubCost: 92010, subscriptionClass: 20, signatureValue: 'f5d455b2e47eef5652d89bd740652d13'
};
subscriptionsInfo["STANDARD_FROM_BASIC_UPGRADE"] = {
    code: 'STANDARD_FROM_BASIC_UPGRADE', displayName: 'Апгрейд на Стандарт с Базового', cost: 380000, rubCost: 76000, subscriptionClass: 20, signatureValue: '1a7da57b16b13dc9133e8ca626d60b3f'
};
subscriptionsInfo["BASIC_FROM_EXCLUSIVE_UPGRADE"] = {
    code: 'BASIC_FROM_EXCLUSIVE_UPGRADE', displayName: 'Апгрейд на Базовый с Эксклюзива', cost: 80010, rubCost: 16010, subscriptionClass: 10, signatureValue: '6616e4176004f67590319d052f5b9f23'
};

export const getSubscriptionCodeByClass = (cl: number): string => {
    switch (cl) {
        case 5:
            return 'EXCLUSIVE';
        case 10:
            return 'BASIC';
        case 20:
            return 'STANDARD';
        case 25:
            return 'STANDARD_PLUS';
        case 30:
            return 'BUSINESS';
        case 40:
            return 'BUSINESS_PLUS';
        default:
            return 'UNKNOWN';
    }
}

export const getAvailableSubscriptionsForClass = (cl: number): CostInfo[] => {
    if (cl <= 0) { //newbie
        return [
            subscriptionsInfo["EXCLUSIVE_INITIAL"],
            subscriptionsInfo["BASIC_INITIAL"],
            subscriptionsInfo["STANDARD_INITIAL"],
            subscriptionsInfo["STANDARD_PLUS_INITIAL"],
            subscriptionsInfo["BUSINESS_INITIAL"]
        ];
    }
    else {
        switch (cl) {
            case 5:
                return [
                    subscriptionsInfo["EXCLUSIVE_RENEWAL"],
                    subscriptionsInfo["BASIC_FROM_EXCLUSIVE_UPGRADE"],
                    subscriptionsInfo["STANDARD_FROM_EXCLUSIVE_UPGRADE"],
                    subscriptionsInfo["STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE"],
                    subscriptionsInfo["BUSINESS_FROM_EXCLUSIVE_UPGRADE"]
                ];
            case 10:
                return [
                    subscriptionsInfo["BASIC_RENEWAL"],
                    subscriptionsInfo["STANDARD_FROM_BASIC_UPGRADE"],
                    subscriptionsInfo["STANDARD_PLUS_FROM_BASIC_UPGRADE"],
                    subscriptionsInfo["BUSINESS_FROM_BASIC_UPGRADE"]
                ];
            case 20:
                return [
                    subscriptionsInfo["STANDARD_RENEWAL"],
                    subscriptionsInfo["STANDARD_PLUS_FROM_STANDARD_UPGRADE"],
                    subscriptionsInfo["BUSINESS_FROM_STANDARD_UPGRADE"]
                ];
            case 25:
                return [
                    subscriptionsInfo["STANDARD_PLUS_RENEWAL"],
                    subscriptionsInfo["BUSINESS_FROM_STANDARD_PLUS_UPGRADE"]
                ];
            case 30:
                return [
                    subscriptionsInfo["BUSINESS_RENEWAL"]
                ];
            case 40:
                return [
                    subscriptionsInfo["BUSINESS_PLUS_RENEWAL"]
                ];
            default:
                return [];
        }
    }
}

export const getNominalBySubscriptionCode = (code: string): number => {
    if (code.indexOf('EXCLUSIVE') == 0)
        return 40;
    else if (code.indexOf('BASIC') == 0)
        return 200;
    else if (code.indexOf('STANDARD_PLUS') == 0)
        return 3000;
    else if (code.indexOf('STANDARD') == 0)
        return 1000;
    else if (code.indexOf('BUSINESS_PLUS') == 0)
        return 15000;
    else if (code.indexOf('BUSINESS') == 0)
        return 5000;
    else return 0;
}

import * as React from 'react';
import Countdown from '../../simple/Countdown';

interface Reg3Props {
    onFinishReg: () => void;
}

const Reg3: React.FC<Reg3Props> = ({ onFinishReg }) => {

    const [state, setState] = React.useState<number>(0);

    return (
        <div className="reg-module-container">
            {state == 0 &&
                <React.Fragment>
                    <div className="reg-module-view-header"><b>ОЖИДАНИЕ ПОДТВЕРЖДЕНИЯ ОПЛАТЫ</b></div>
                    <div className="reg-module-wait">Пожалуйста, не закрывайте приложение.<br />Ожидайте подтверждения оплаты Администрацией.<br />
                        <Countdown startValue={60} endValue={57} onElapsed={() => setState(1)} />
                        <div>Если подтверждение не произошло в течение 60 секунд, обратитесь в Службу Заботы.</div>
                    </div>
                </React.Fragment>}
            {state == 1 &&
                <React.Fragment>
                    <div className="reg-module-view-header"><b>ЗАВЕРШЕНИЕ ОПЛАТЫ</b></div>
                    <div className="reg-module-wait">Благодарим!<br />Ваш платёж принят.<br />
                        <a href="#" onClick={onFinishReg}>Перейти в Клуб</a>
                    </div>
                </React.Fragment>}
        </div>
    )
};

export default Reg3;

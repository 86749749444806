import React from 'react';

// Define the shape of the option object
interface OptionType {
    value: string;
    label: string;
}

interface AutocompleteProps {
    options: OptionType[];
    placeholder: string;
    customValuesAllowed: boolean;
    onValueSelected: (value: string) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ options, placeholder, customValuesAllowed, onValueSelected }) => {

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [currentText, setCurrentText] = React.useState<string>('');
    const [showOptions, setShowOptions] = React.useState<boolean>(false);
    const [valueSelected, setValueSelected] = React.useState<boolean>(false);

    const onTextChange = (newText: string) => {
        setCurrentText(newText);
        setValueSelected(false);
    }

    const selectOption = (event: React.MouseEvent<HTMLDivElement>) => {
        var optionValue = event.currentTarget.getAttribute("data-value");
        if (optionValue) {
            setCurrentText(event.currentTarget.innerText);
            setValueSelected(true);
            onValueSelected(optionValue);
        }
    }

    const selectCustomOption = () => {
        setValueSelected(true);
        onValueSelected(currentText);
    }

    return (
        <div className="reg-module-autocomplete">
            <div className="reg-module-autocomplete-input">
                <input ref={inputRef} className="reg-module-input" placeholder={placeholder} onChange={(e) => onTextChange(e.target.value)} value={currentText}
                    onFocus={() => setShowOptions(true)} onBlur={() => setShowOptions(false)} />
                <i className="fas fa-chevron-down" onClick={() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }} />
            </div>
            <div className="reg-module-autocomplete-options" style={{ display: showOptions ? '' : 'none' }}>
                {options.filter(o => o.label.toLowerCase().includes(currentText.toLowerCase())).map(item => (
                    <div className="reg-module-autocomplete-option" onMouseDown={selectOption} data-value={item.value}>{item.label}</div>
                ))}
                {(customValuesAllowed && options.filter(o => o.label.toLowerCase().includes(currentText.toLowerCase())).length == 0)
                    && <div className="reg-module-autocomplete-option" onMouseDown={selectCustomOption}>{currentText} (свой вариант)</div>}
            </div>
        </div>
    );
};

export default Autocomplete;
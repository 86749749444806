import * as React from 'react';

interface Reg1Props {
    firstEntry: boolean;
    canProceedToPayment: boolean;
    onViewFormData: () => void;
    onProceedToPayment: () => void;
}

const Reg1: React.FC<Reg1Props> = ({ firstEntry, canProceedToPayment, onViewFormData, onProceedToPayment }) => {
    return (
        <div className="reg-module-container" style={{ height: "calc(100% - 44px)"}}>
            <div className="stepper-empty-space"><div className="reg-module-thx">
                {firstEntry ? "Спасибо! Ваши данные были внесены в базу." : "Ваши данные были внесены в базу ранее."}</div></div>
            <div className="stepper-button-container">
                {firstEntry && <button className="stepper-small-button" onClick={onViewFormData}><i className="fas fa-chevron-left" /></button>}
                <button className="stepper-button" onClick={onProceedToPayment} disabled={!canProceedToPayment}>
                    Перейти к оплате
                </button>
            </div>
        </div>
        )
};

export default Reg1;

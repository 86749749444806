import React from 'react';

interface DatePickerProps {
    onValueChanged: (value: string) => void;
}

const Datepicker: React.FC<DatePickerProps> = ({ onValueChanged }) => {

    const [dayValue, setDayValue] = React.useState<string>('');
    const [monthValue, setMonthValue] = React.useState<string>('');
    const [yearValue, setYearValue] = React.useState<string>('');

    const days = [];
    for (var i = 0; i < 31; i++) {
        days.push(i+1);
    }

    const years = [];
    const now = new Date();
    for (var i = now.getFullYear() - 18; i >= now.getFullYear()-100; i--) {
        years.push(i);
    }

    const onDaySelect = (value: string) => {
        setDayValue(value);
        if (monthValue.length > 0 && yearValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + value);
        }
    }

    const onMonthSelect = (value: string) => {
        setMonthValue(value);
        if (dayValue.length > 0 && yearValue.length > 0) {
            onValueChanged(yearValue + '-' + value + '-' + dayValue);
        }
    }

    const onYearSelect = (value: string) => {
        setYearValue(value);
        if (dayValue.length > 0 && monthValue.length > 0) {
            onValueChanged(value + '-' + monthValue + '-' + dayValue);
        }
    }

    return (
        <div className="reg-module-datepicker">
            <select className="reg-module-datepicker-day" onChange={(e) => onDaySelect(e.target.value)}>
                <option style={{ display: 'none' }} value="" selected></option>
                {days.map(i => <option value={i}>{i}</option>)}
            </select>
            <select className="reg-module-datepicker-month" onChange={(e) => onMonthSelect(e.target.value)}>
                <option style={{ display: 'none' }} value="" selected></option>
                <option value="1">Январь</option>
                <option value="2">Февраль</option>
                <option value="3">Март</option>
                <option value="4">Апрель</option>
                <option value="5">Май</option>
                <option value="6">Июнь</option>
                <option value="7">Июль</option>
                <option value="8">Август</option>
                <option value="9">Сентябрь</option>
                <option value="10">Октябрь</option>
                <option value="11">Ноябрь</option>
                <option value="12">Декабрь</option>
            </select>
            <select className="reg-module-datepicker-year" onChange={(e) => onYearSelect(e.target.value)}>
                <option style={{ display: 'none' }} value="" selected></option>
                {years.map(i => <option value={i}>{i}</option>)}
            </select>
        </div>
    );
};

export default Datepicker;
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React, { useRef, useEffect } from 'react';

interface CustomModalProps {
    show: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({ show, onClose, title, children }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const modalInstanceRef = useRef<any>(null);

    useEffect(() => {
        const bootstrap = require('bootstrap');

        if (modalRef.current) {
            if (!modalInstanceRef.current) {
                modalInstanceRef.current = new bootstrap.Modal(modalRef.current, {
                    backdrop: 'static',
                    keyboard: false,
                });
            }

            if (show) {
                modalInstanceRef.current.show();
            } else {
                modalInstanceRef.current.hide();
            }
        }

    }, [show]);

    const handleClose = () => {
        if (modalInstanceRef.current) {
            modalInstanceRef.current.hide();
        }
        onClose();
    };

    return (
        <div
            ref={modalRef}
            className="modal fade"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account } from '../../store/Models';
import { eventTarget, getClassSingle, getPtsTextRussian } from '../../utils';
import AccountAvatar from './AccountAvatar';

interface CoinChallengeTableViewProps {
    currentUser: Account;
    viewMode: number;
    onAccountClick: (account: Account) => void;
}

interface CoinChallengeTotal {
    account: Account;
    total: number;
}

const CoinChallengeTableView: React.FC<CoinChallengeTableViewProps> = ({ currentUser, viewMode, onAccountClick }) => {
    const fetch = useFetch();

    const [coinChallengeFullView, setCoinChallengeFullView] = React.useState<boolean>(false);

    const [coinChallengeData, setCoinChallengeData] = React.useState<Account[]>([]);
    const [coinChallengeTotalData, setCoinChallengeTotalData] = React.useState<CoinChallengeTotal[]>([]);

    React.useEffect(() => {
        fetch('/api/challenge/getERACoinsChallengeCurrentBalance')
            .then(response => response.json() as Promise<Account[]>)
            .then(data => {
                setCoinChallengeData(data);
            });
        fetch('/api/challenge/getERACoinsChallengeTotalEarned')
            .then(response => response.json() as Promise<CoinChallengeTotal[]>)
            .then(data => {
                setCoinChallengeTotalData(data);
                eventTarget.dispatchEvent(new CustomEvent('read_coin_challenge'));
            });
    }, []);

    React.useEffect(() => {
        setCoinChallengeFullView(false);
    }, [viewMode]);

    return (
        <div className="challenge-table-container">
            {viewMode == 0 && <table className="table table-striped challenge-view-table coin-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th>Тариф</th>
                        <th><i className="fas fa-coins challenge-coin-golden" /></th>
                    </tr>
                </thead>
                <tbody>
                    {coinChallengeData.map((item, i) => {
                        if ((i < 3 || (i > coinChallengeData.filter(b => b.eraCoins > 0).length - 4 && i < coinChallengeData.filter(b => b.eraCoins > 0).length)
                            || coinChallengeFullView) && item.id != currentUser.id) {
                            return (<tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item) }}>{item.name}</span></div>
                                </td>
                                <td>{getClassSingle(item.class)}</td>
                                <td>{item.eraCoins}</td>
                            </tr>)
                        }
                        else if (item.id == currentUser.id) {
                            return (<tr key={i}>
                                <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                <td style={{ color: '#047ff9' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.name} (Я)</div>
                                </td>
                                <td style={{ color: '#047ff9' }}>{getClassSingle(item.class)}</td>
                                <td style={{ color: '#047ff9' }}>{item.eraCoins}</td>
                            </tr>)
                        }
                        else if (coinChallengeData.findIndex(b => b.id == currentUser.id) > -1
                            && coinChallengeData.findIndex(b => b.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (coinChallengeData.findIndex(b => b.id == currentUser.id) > coinChallengeData.filter(b => b.eraCoins > 0).length - 4
                            && coinChallengeData.findIndex(b => b.id == currentUser.id) < coinChallengeData.filter(b => b.eraCoins > 0).length && i == 3) { // me at bottom non-zero result 3: break is one row
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (coinChallengeData.filter(b => b.id == currentUser.id).length > 0 &&
                            coinChallengeData.filter(b => b.id == currentUser.id)[0].eraCoins > 0
                            && Math.abs(i - coinChallengeData.findIndex(b => b.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && coinChallengeData.filter(b => b.id == currentUser.id).length > 0 &&
                            coinChallengeData.filter(b => b.id == currentUser.id)[0].eraCoins == 0) {
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && coinChallengeData.filter(b => b.id == currentUser.id).length == 0) {
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == coinChallengeData.findIndex(b => b.eraCoins == 0)) { // first 0 row
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else {
                            return null;
                        }
                    })}
                </tbody>
            </table>}
            {viewMode == 1 && <table className="table table-striped challenge-view-table coin-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th>Тариф</th>
                        <th><i className="fas fa-coins challenge-coin-golden" /></th>
                    </tr>
                </thead>
                <tbody>
                    {coinChallengeTotalData.map((item, i) => {
                        if ((i < 3 || (i > coinChallengeTotalData.filter(b => b.total > 0).length - 4 && i < coinChallengeTotalData.filter(b => b.total > 0).length)
                            || coinChallengeFullView) && item.account.id != currentUser.id) {
                            return (<tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{item.account.name}</span></div>
                                </td>
                                <td>{getClassSingle(item.account.class)}</td>
                                <td>{item.total}</td>
                            </tr>)
                        }
                        else if (item.account.id == currentUser.id) {
                            return (<tr key={i}>
                                <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                <td style={{ color: '#047ff9' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.account.name} (Я)</div>
                                </td>
                                <td style={{ color: '#047ff9' }}>{getClassSingle(item.account.class)}</td>
                                <td style={{ color: '#047ff9' }}>{item.total}</td>
                            </tr>)
                        }
                        else if (coinChallengeTotalData.findIndex(b => b.account.id == currentUser.id) > -1
                            && coinChallengeTotalData.findIndex(b => b.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={2}>...</td>
                            </tr>)
                        }
                        else if (coinChallengeTotalData.findIndex(b => b.account.id == currentUser.id) > coinChallengeTotalData.filter(b => b.total > 0).length - 4
                            && coinChallengeTotalData.findIndex(b => b.account.id == currentUser.id) < coinChallengeTotalData.filter(b => b.total > 0).length && i == 3) { // me at bottom 3: break is one row
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={2}>...</td>
                            </tr>)
                        }
                        else if (coinChallengeTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            coinChallengeTotalData.filter(b => b.account.id == currentUser.id)[0].total > 0 &&
                            Math.abs(i - coinChallengeTotalData.findIndex(b => b.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={2}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && coinChallengeTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            coinChallengeTotalData.filter(b => b.account.id == currentUser.id)[0].total == 0) {
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={2}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && coinChallengeTotalData.filter(b => b.account.id == currentUser.id).length == 0) {
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == coinChallengeTotalData.findIndex(b => b.total == 0)) { // first 0 row
                            return (<tr key={i} onClick={() => setCoinChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={2}>...</td>
                            </tr>)
                        }
                        else {
                            return null;
                        }
                    })}
                </tbody>
            </table>}
        </div>
    )
};

export default CoinChallengeTableView;

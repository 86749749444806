import * as React from 'react';

interface CharityAddPeriodFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const CharityAddPeriodForm: React.FC<CharityAddPeriodFormProps> = ({ onBack, productionMode }) => {

    const [selectedDate, setSelectedDate] = React.useState<string>(new Date().toISOString().split('T')[0]);

    const clearForm = () => {
        setSelectedDate('');
    }

    const validate = (): boolean => {
        return selectedDate.length > 0;
    }

    const doAdd = () => {
        //send information to server
        var formData = new FormData();
        formData.append('SelectedDate', selectedDate);
        fetch('api/admin/addCharityPeriod', {
            method: 'POST',
            body: formData
        })
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ message: 'Период добавлен успешно.' });
                }
                else {
                    alert('Период добавлен успешно.');
                }
                onBack();
            })
            .catch(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                }
                else {
                    alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                }
            });
    }

    const getSurroundingDates = (days: number): string[] => {
        const dates = [];
        const currentDate = new Date();

        var weekDays = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

        // Add previous dates
        for (let i = days; i > 0; i--) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            dates.push(date.toISOString().split('T')[0] + ' (' + weekDays[date.getDay()] + ')'); // Format as 'YYYY-MM-DD'
        }

        // Add the current date
        dates.push(currentDate.toISOString().split('T')[0] + ' (' + weekDays[currentDate.getDay()] + ')');

        // Add future dates
        for (let i = 1; i <= days; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            dates.push(date.toISOString().split('T')[0] + ' (' + weekDays[date.getDay()] + ')');
        }

        return dates;
    }

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>ОТКРЫТЬ НОВЫЙ ПЕРИОД</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Дата</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setSelectedDate(e.target.value)}>
                            {getSurroundingDates(7).map((d, i) => (
                                <>
                                    {i == 7 && <option value={d.split(' ')[0]} selected>{d}</option>}
                                    {i != 7 && <option value={d.split(' ')[0]}>{d}</option>}
                                </>
                            ))}
                        </select>
                    </div>
                    <div className="reg-module-submit">
                        <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>Добавить</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CharityAddPeriodForm;

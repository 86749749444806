import * as React from 'react';
import ReactQuill from 'react-quill';
import InputComponent from './InputComponent';

const TestComponent: React.FC = () => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const quillRef = React.useRef<ReactQuill>(null);

    const onFocus = () => {
        if (quillRef.current != null && wrapperRef.current != null && window.Telegram.WebApp.platform === 'ios') {
            wrapperRef.current.style.paddingBottom = '290px';
        }
    }

    const onBlur = () => {
        if (quillRef.current != null && wrapperRef.current != null && window.Telegram.WebApp.platform === 'ios') {
            wrapperRef.current.style.paddingBottom = '';
        }
    }

    return (
        <div style={{ width: '100%', height: window.innerHeight, position: 'relative' }}>
            <div style={{ position: 'absolute', bottom: '0', width: '100%' }} ref={wrapperRef}>
                <InputComponent inputRef={quillRef} placeholder="Введите текст..." onInput={() => { }} onFocus={onFocus} onBlur={onBlur} />
            </div>
        </div>
        )
};

export default TestComponent;

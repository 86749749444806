import * as React from 'react';

const RegDeny: React.FC = () => {
    const denySupportText = "Здравствуйте! Мой платёж не был принят. Помогите, пожалуйста, выяснить причину.";
    const link = "https://wa.me/77052042585?text=" + denySupportText;

    return (
        <div className="reg-module-container" style={{ height: "calc(100% - 44px)"}}>
            <div className="stepper-empty-space">
                <div className="reg-module-thx">
                    К сожалению, Ваш платёж не был принят.
                    <br />
                    Пожалуйста, обратитесь в <a href={link}>Службу Заботы</a> по данному вопросу.
                </div>
            </div>
        </div>
        )
};

export default RegDeny;

import * as React from 'react';
import { Account } from '../../../../../store/Models';
import { CostInfo, getAvailableSubscriptionsForClass, getSubscriptionCodeByClass, subscriptionsInfo } from '../../../../../subscriptions';
import { formatCost } from '../../../../../utils';

interface StepperRenewal1Props {
    currentUser: Account;
    onBack: () => void;
    onNext: (selectedOption: CostInfo) => void;
}

const StepperRenewal1: React.FC<StepperRenewal1Props> = ({ currentUser, onBack, onNext }) => {
    const subscriptionOptions: CostInfo[] = getAvailableSubscriptionsForClass(currentUser.class);

    const handleSubscriptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selected = subscriptionsInfo[selectedValue];
        setSelectedSubscription(selected);
    };

    const [selectedSubscription, setSelectedSubscription] = React.useState<CostInfo>(subscriptionsInfo[getSubscriptionCodeByClass(currentUser.class) + "_RENEWAL"]);

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-select-caption">Выберите тариф</div>
                <div className="stepper-select-wrapper">
                    <select className="stepper-select" onChange={handleSubscriptionSelect}>
                        {subscriptionOptions.map((option) => (
                            <option key={option.code} value={option.code} selected={option.code === getSubscriptionCodeByClass(currentUser.class) + "_RENEWAL"}>
                                {option.displayName} (₸{formatCost(option.cost)})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="stepper-info-text">
                    Сумма: {selectedSubscription ? "₸" + formatCost(selectedSubscription.cost) : '—'}
                </div>
            </div>
            <div className="stepper-button-container">
                {(currentUser.daysLeft != null && currentUser.daysLeft >= 0) && <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>}
                <button className="stepper-button" onClick={() => onNext(selectedSubscription)} disabled={!selectedSubscription}>
                    К оплате
                </button>
            </div>
        </React.Fragment>
    )
};

export default StepperRenewal1;

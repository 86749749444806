import * as React from 'react';
import { Account } from '../../store/Models';
import { getClassSingle, getMembersCountTextRussian, makeAgeString, makeDateTimeRussian, makeYearsAndMonths } from '../../utils';
import CuratorSVG from './svg/CuratorSVG';
import FirstLineSVG from './svg/FirstLineSVG';
import CommandSVG from './svg/CommandSVG';

interface AccountViewProps {
    account: Account;
    onClose: () => void;
}

const AccountView: React.FC<AccountViewProps> = ({ account, onClose }) => {

    const [firstLineCount, setFirstLineCount] = React.useState<number | null>(null);
    const [totalCount, setTotalCount] = React.useState<number | null>(null);

    const [curatorName, setCuratorName] = React.useState<string | null>(null);
    const [balance, setBalance] = React.useState<number>(0);

    const [viewMode, setViewMode] = React.useState<number>(1);

    React.useEffect(() => {
        fetch('api/account/getCommandInfo?id=' + account.id)
            .then(r => r.json())
            .then(data => {
                setFirstLineCount(data.firstLineCount);
                setTotalCount(data.totalCount);
            });

        fetch('api/challenge/getCurator?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                if (data.account != null) {
                    setCuratorName(data.account.name);
                }
            });

        fetch('api/account/getAccountBalance?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                if (data.balance != null) {
                    setBalance(data.balance);
                }
            });
    }, []);

    return (
        <div className="account-view">
            <div className="account-profile-back-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="account-profile-container">
                <div className="account-profile-container-top">
                    <div className="account-profile-photo" style={{
                        background:
                            `url('${'https://pakhomov1907-001-site1.ctempurl.com/api/account/getAvatar?userId=' + account.id}') no-repeat center`,
                        backgroundSize: 'cover'
                    }}>
                        <img width={100} height={100} src={"https://pakhomov1907-001-site1.ctempurl.com/api/account/getLevelImage?level=" + account.level} />
                    </div>
                    <div className="account-profile-panel">
                        <table style={{ width: '100%', height: '100%' }}>
                            <tbody>
                                <tr>
                                    <td className="account-profile-panel-name">{account.name}</td>
                                    <td className="account-profile-panel-points"><b>{balance}</b> у.е.</td>
                                </tr>
                                <tr>
                                    <td className="account-profile-panel-class">{getClassSingle(account.class)}</td>
                                    <td className="account-profile-panel-coins"><b>{account.eraCoins || 0}</b> ERA Coin</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="account-profile-container-bottom">
                    <div className="account-profile-container-toggle">
                        <div className={viewMode == 1 ? "active" : ""} onClick={() => setViewMode(1)}>Личная информация</div>
                        <div className={viewMode == 2 ? "active" : ""} onClick={() => setViewMode(2)}>Работа с командой</div>
                    </div>
                    {viewMode == 1 && <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td><i className="fas fa-cake-candles" /></td>
                                <td>Дата рождения</td>
                                <td>{makeAgeString(account.birthday)}</td>
                            </tr>
                            <tr>
                                <td><i className="fas fa-city" /></td>
                                <td>Город</td>
                                <td>{account.city || 'Не указан'}</td>
                            </tr>
                            <tr>
                                <td><i className="fas fa-briefcase" /></td>
                                <td>Род деятельности</td>
                                <td>{account.businessKind || 'Не указан'}</td>
                            </tr>
                            <tr>
                                <td><i className="fas fa-briefcase" /></td>
                                <td>Сфера деятельности</td>
                                <td>{account.businessSphere || 'Не указана'}</td>
                            </tr>
                            <tr>
                                <td><i className="fa-brands fa-instagram" /></td>
                                <td>Instagram</td>
                                <td>{account.instaProfile ? <a target="_blank" href={'https://instagram.com/' + account.instaProfile}>{account.instaProfile}</a> : 'Не указан'}</td>
                            </tr>
                        </tbody>
                    </table>}
                    {viewMode == 2 && <div>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td><FirstLineSVG /></td>
                                    <td>1-я линия</td>
                                    <td>{firstLineCount != null ? <b>{firstLineCount}</b> : '?'} {getMembersCountTextRussian(firstLineCount != null ? firstLineCount.toString() : '')}</td>
                                </tr>
                                <tr>
                                    <td><CommandSVG /></td>
                                    <td>Вся команда</td>
                                    <td>{totalCount != null ? <b>{totalCount}</b> : '?'} {getMembersCountTextRussian(totalCount != null ? totalCount.toString() : '')}</td>
                                </tr>
                                <tr>
                                    <td><CuratorSVG /></td>
                                    <td>Куратор</td>
                                    <td>{curatorName || 'Не выбран'}</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-wallet" /></td>
                                    <td>Баланс</td>
                                    <td><b>{balance}</b> у.е.</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-coins" /></td>
                                    <td>Баланс ERA Coin*</td>
                                    <td><b>{account.eraCoins || 0}</b> ERA Coin</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="account-profile-info-message"><a href="https://t.me/sib_era">* <span>Условия получения и использования ERA Coin</span></a></div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default AccountView;

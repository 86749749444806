import * as React from 'react';
import { Account } from '../../store/Models';
import { getClassSingle, getResidentsText } from '../../utils';
import AccountAvatar from './AccountAvatar';
import FirstLineSVG from './svg/FirstLineSVG';

interface ClassicViewProps {
    onAccountClick: (account: Account) => void;
    onClose: () => void;
}

const ClassicShortView: React.FC<ClassicViewProps> = ({ onAccountClick, onClose }) => {

    const [line, setLine] = React.useState<Account[]>([]);

    React.useEffect(() => {
        fetch('/api/challenge/getFirstLine')
            .then(r => r.json() as Promise<Account[]>)
            .then(data => {
                setLine(data);
            });
    }, []);

    return (
        <div className="classic-view">
            <div className="classic-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="classic-view-header"><b>КЛАССИЧЕСКАЯ СХЕМА</b><br /><em>(количество Резидентов 1-й линии: <b>{line.length}</b> чел.)</em></div>
            <div className="classic-view-table-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Резидент</th>
                            <th>Тариф</th>
                        </tr>
                    </thead>
                    <tbody>
                        {line.length == 0 &&
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>}
                        {line.map((item, i) =>
                            <tr>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item) }}>{item.name}</span></div>
                                </td>
                                <td>{getClassSingle(item.class)}</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default ClassicShortView;

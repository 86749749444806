import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account } from '../../store/Models';
import { eventTarget, getPtsTextRussian, saveCookie } from '../../utils';
import AccountAvatar from './AccountAvatar';
import DanceSVG from './svg/DanceSVG';

interface BasicChallengeTableViewProps {
    currentUser: Account;
    viewMode: number;
    onAccountClick: (account: Account) => void;
}

interface BasicChallenge {
    account: Account;
    dances: number;
    reflections: number;
}

interface BasicChallengeTotal {
    account: Account;
    totalDances: number;
    totalReflections: number;
    totalScore: number;
}

const BasicChallengeTableView: React.FC<BasicChallengeTableViewProps> = ({ currentUser, viewMode, onAccountClick }) => {
    const fetch = useFetch();

    const [basicChallengeFullView, setBasicChallengeFullView] = React.useState<boolean>(false);

    const [basicChallengeData, setBasicChallengeData] = React.useState<BasicChallenge[]>([]);
    const [basicChallengeTotalData, setBasicChallengeTotalData] = React.useState<BasicChallengeTotal[]>([]);

    const tz = - new Date().getTimezoneOffset() / 60;

    const [dailySortColumn, setDailySortColumn] = React.useState<string>('');
    const [totalSortColumn, setTotalSortColumn] = React.useState<string>('');

    React.useEffect(() => {
        fetch('/api/challenge/getBasicChallengeDailyResults?tz=' + tz)
            .then(response => response.json() as Promise<BasicChallenge[]>)
            .then(data => {
                setBasicChallengeData(data);
            });
        fetch('/api/challenge/getBasicChallengeTotalResults?tz=' + tz)
            .then(response => response.json() as Promise<BasicChallengeTotal[]>)
            .then(data => {
                setBasicChallengeTotalData(data);
                eventTarget.dispatchEvent(new CustomEvent('read_basic_challenge'));
            });
    }, []);

    React.useEffect(() => {
        setBasicChallengeFullView(false);
    }, [viewMode]);

    const sortDailyByDances = () => {
        setBasicChallengeData(x => x.sort((a, b) => b.dances - a.dances));
        setDailySortColumn('Dances');
    }

    const sortDailyByReflections = () => {
        setBasicChallengeData(x => x.sort((a, b) => b.reflections - a.reflections));
        setDailySortColumn('Reflections');
    }

    const sortDailyByTotal = () => {
        setBasicChallengeData(x => x.sort((a, b) => b.dances + b.reflections - a.dances - a.reflections));
        setDailySortColumn('');
    }

    const sortTotalByDances = () => {
        setBasicChallengeTotalData(x => x.sort((a, b) => b.totalDances - a.totalDances));
        setTotalSortColumn('TotalDances');
    }

    const sortTotalByReflections = () => {
        setBasicChallengeTotalData(x => x.sort((a, b) => b.totalReflections - a.totalReflections));
        setTotalSortColumn('TotalReflections');
    }

    const sortTotalByTotal = () => {
        setBasicChallengeTotalData(x => x.sort((a, b) => b.totalScore - a.totalScore));
        setTotalSortColumn('');
    }

    //to do make as Telegram popup with buttons and info
    const showSetDancePopup = () => {
        let dancePts = window.prompt("Введите количество баллов за танцы");
        if (dancePts != null) {
            fetch('/api/challenge/setDances?value=' + dancePts);
            //to do manually set dance pts to interface data table
        }
    }

    return (
        <div className="challenge-table-container">
            {viewMode == 0 && <table className="table table-striped challenge-view-table basic-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th className={dailySortColumn === 'Dances' ? 'total-sort-column' : ''} onClick={sortDailyByDances}>
                            <DanceSVG />
                        </th>
                        <th className={dailySortColumn === 'Reflections' ? 'total-sort-column' : ''} onClick={sortDailyByReflections}>
                            <i className="fas fa-file-signature" /></th>
                        <th className={dailySortColumn === '' ? 'total-sort-column' : ''} onClick={sortDailyByTotal}>И</th>
                    </tr>
                </thead>
                <tbody>
                    {basicChallengeData.map((item, i) => {
                        if ((i < 3 || (i > basicChallengeData.filter(b => b.dances + b.reflections > 0).length - 4 && i < basicChallengeData.filter(b => b.dances + b.reflections > 0).length)
                            || basicChallengeFullView) && item.account.id != currentUser.id) {
                            return (<tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{item.account.name}</span></div>
                                </td>
                                <td style={{ fontWeight: dailySortColumn === 'Dances' ? 'bold' : 'normal' }}>{item.dances == 0 ? '-' : item.dances.toString()}</td>
                                <td style={{ fontWeight: dailySortColumn === 'Reflections' ? 'bold' : 'normal' }}>{item.reflections}</td>
                                <td style={{ fontWeight: dailySortColumn === '' ? 'bold' : 'normal' }}>{item.dances + item.reflections}</td>
                            </tr>)
                        }
                        else if (item.account.id == currentUser.id) {
                            return (<tr key={i}>
                                <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                <td style={{ color: '#047ff9' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.account.name} (Я)</div>
                                </td>
                                <td style={{ color: '#047ff9', fontWeight: dailySortColumn === 'Dances' ? 'bold' : 'normal' }}>{item.dances == 0 ? '-' : item.dances.toString()}</td>
                                <td style={{ color: '#047ff9', fontWeight: dailySortColumn === 'Reflections' ? 'bold' : 'normal' }}>{item.reflections}</td>
                                <td style={{ color: '#047ff9', fontWeight: dailySortColumn === '' ? 'bold' : 'normal' }}>{item.dances + item.reflections}</td>
                            </tr>)
                        }
                        else if (basicChallengeData.findIndex(b => b.account.id == currentUser.id) > -1
                            && basicChallengeData.findIndex(b => b.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (basicChallengeData.findIndex(b => b.account.id == currentUser.id) > basicChallengeData.filter(b => b.dances + b.reflections > 0).length - 4
                            && basicChallengeData.findIndex(b => b.account.id == currentUser.id) < basicChallengeData.filter(b => b.dances + b.reflections > 0).length && i == 3) { // me at bottom non-zero result 3: break is one row
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (basicChallengeData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            basicChallengeData.filter(b => b.account.id == currentUser.id)[0].dances + basicChallengeData.filter(b => b.account.id == currentUser.id)[0].reflections > 0
                            && Math.abs(i - basicChallengeData.findIndex(b => b.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && basicChallengeData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            basicChallengeData.filter(b => b.account.id == currentUser.id)[0].dances + basicChallengeData.filter(b => b.account.id == currentUser.id)[0].reflections == 0) {
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && basicChallengeData.filter(b => b.account.id == currentUser.id).length == 0) {
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == basicChallengeData.findIndex(b => b.dances + b.reflections == 0)) { // first 0 row
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else {
                            return null;
                        }
                    })}
                </tbody>
            </table>}
            {viewMode == 1 && <table className="table table-striped challenge-view-table basic-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th className={totalSortColumn === 'TotalDances' ? 'total-sort-column' : ''} onClick={sortTotalByDances}>
                            <DanceSVG />
                        </th>
                        <th className={totalSortColumn === 'TotalReflections' ? 'total-sort-column' : ''} onClick={sortTotalByReflections}>
                            <i className="fas fa-file-signature" /></th>
                        <th className={totalSortColumn === '' ? 'total-sort-column' : ''} onClick={sortTotalByTotal}>И</th>
                    </tr>
                </thead>
                <tbody>
                    {basicChallengeTotalData.map((item, i) => {
                        if ((i < 3 || (i > basicChallengeTotalData.filter(b => b.totalScore > 0).length - 4 && i < basicChallengeTotalData.filter(b => b.totalScore > 0).length)
                            || basicChallengeFullView) && item.account.id != currentUser.id) {
                            return (<tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{item.account.name}</span></div>
                                </td>
                                <td style={{ fontWeight: totalSortColumn === 'TotalDances' ? 'bold' : 'normal' }}>{item.totalDances}</td>
                                <td style={{ fontWeight: totalSortColumn === 'TotalReflections' ? 'bold' : 'normal' }}>{item.totalReflections}</td>
                                <td style={{ fontWeight: totalSortColumn === '' ? 'bold' : 'normal' }}>{item.totalScore}</td>
                            </tr>)
                        }
                        else if (item.account.id == currentUser.id) {
                            return (<tr key={i}>
                                <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                <td style={{ color: '#047ff9' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.account.name} (Я)</div>
                                </td>
                                <td style={{ color: '#047ff9', fontWeight: totalSortColumn === 'TotalDances' ? 'bold' : 'normal' }}>{item.totalDances}</td>
                                <td style={{ color: '#047ff9', fontWeight: totalSortColumn === 'TotalReflections' ? 'bold' : 'normal' }}>{item.totalReflections}</td>
                                <td style={{ color: '#047ff9', fontWeight: totalSortColumn === '' ? 'bold' : 'normal' }}>{item.totalScore}</td>
                            </tr>)
                        }
                        else if (basicChallengeTotalData.findIndex(b => b.account.id == currentUser.id) > -1
                            && basicChallengeTotalData.findIndex(b => b.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (basicChallengeTotalData.findIndex(b => b.account.id == currentUser.id) > basicChallengeTotalData.filter(b => b.totalScore > 0).length - 4
                            && basicChallengeTotalData.findIndex(b => b.account.id == currentUser.id) < basicChallengeTotalData.filter(b => b.totalScore > 0).length && i == 3) { // me at bottom 3: break is one row
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (basicChallengeTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            basicChallengeTotalData.filter(b => b.account.id == currentUser.id)[0].totalScore > 0 &&
                            Math.abs(i - basicChallengeTotalData.findIndex(b => b.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && basicChallengeTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            basicChallengeTotalData.filter(b => b.account.id == currentUser.id)[0].totalScore == 0) {
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && basicChallengeTotalData.filter(b => b.account.id == currentUser.id).length == 0) {
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else if (i == basicChallengeTotalData.findIndex(b => b.totalScore == 0)) { // first 0 row
                            return (<tr key={i} onClick={() => setBasicChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={4}>...</td>
                            </tr>)
                        }
                        else {
                            return null;
                        }
                    })}
                </tbody>
            </table>}
        </div>
    )
};

export default BasicChallengeTableView;

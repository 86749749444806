import * as React from 'react';

const CommandSVG: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2319 32.9425C13.5233 32.4198 13.8832 31.8884 14.2946 31.3914C13.7975 30.9115 12.9748 30.5687 12.2978 30.2859C17.2255 27.1407 14.9802 19.325 9.06695 19.325C5.78468 19.325 3.11944 21.9988 3.11944 25.2811C3.11675 26.2759 3.36509 27.2553 3.84145 28.1286C4.31782 29.0019 5.00682 29.741 5.84467 30.2773C2.42528 31.5885 0 34.8879 0 38.7615V44.0577C0 44.9833 0.754151 45.7545 1.69684 45.7545H11.5608C11.5522 45.6774 11.5522 45.6089 11.5522 45.5317V39.1729C11.5522 36.979 12.1264 34.8279 13.2319 32.9425ZM42.1553 30.2773C47.083 27.1493 44.8463 19.3164 38.933 19.3164C35.6508 19.3164 32.977 21.9902 32.977 25.2725C32.977 27.3635 34.0568 29.2061 35.7022 30.2687C34.9823 30.543 34.2967 30.9115 33.6797 31.3485C35.4708 33.5082 36.4478 36.3191 36.4478 39.1557V45.5146C36.4478 45.5917 36.4478 45.6688 36.4392 45.7374H46.3032C47.2373 45.7374 48 44.9747 48 44.0406V38.7444C47.9914 34.8879 45.5661 31.5885 42.1553 30.2773Z" />
            <path d="M27.5089 29.0434C29.5828 27.8265 30.9797 25.5811 30.9797 23.0016C30.9797 19.1366 27.8431 16 23.9866 16C16.8479 16.0428 14.3884 25.4869 20.473 29.0348C16.2052 30.5174 13.2486 34.6052 13.2657 39.1644V45.5233C13.2657 45.6004 13.2743 45.669 13.2828 45.7461C13.3348 46.2032 13.553 46.6251 13.8959 46.9317C14.2388 47.2383 14.6825 47.408 15.1425 47.4087H32.8393C33.7906 47.4087 34.5962 46.6888 34.699 45.7461C34.7076 45.669 34.7162 45.6004 34.7162 45.5233V39.1644C34.7419 34.6138 31.7767 30.526 27.5089 29.0434Z" />
            <path d="M47.6934 18.401C47.4336 18.7746 47.0363 19.03 46.5885 19.1111C46.1407 19.1922 45.6791 19.0925 45.3047 18.8337L24.9383 4.73524C24.6505 4.53674 24.3091 4.43044 23.9595 4.43044C23.6098 4.43044 23.2685 4.53674 22.9806 4.73524L2.67431 18.8337C2.29997 19.085 1.84183 19.179 1.3988 19.0954C0.955765 19.0118 0.563383 18.7573 0.306366 18.3869C0.049349 18.0165 -0.0516834 17.5598 0.0250818 17.1156C0.101847 16.6713 0.350251 16.275 0.716664 16.0123L21.023 1.91382C21.886 1.31926 22.9092 1.00061 23.9572 1C25.0052 0.999388 26.0288 1.31684 26.8925 1.91038L47.2589 16.0089C47.4447 16.1373 47.6034 16.301 47.7258 16.4908C47.8482 16.6806 47.932 16.8926 47.9724 17.1148C48.0127 17.337 48.0088 17.565 47.961 17.7857C47.9131 18.0064 47.8222 18.2155 47.6934 18.401Z" />
        </svg>
    );
};

export default CommandSVG;

import * as React from 'react';
import { CostInfo } from '../../../../../subscriptions';
import { copyToClipboard, formatCost } from '../../../../../utils';

interface Stepper2Props {
    selectedSubscription: CostInfo;
    onBack: () => void;
    onNext: (selectedOption: CostInfo|null) => void;
}

const Stepper2: React.FC<Stepper2Props> = ({ selectedSubscription, onBack, onNext }) => {
    const delay = 90;

    const paymentOptions = [
        {
            Key: 'Kaspi',
            Name: 'Kaspi',
            PaymentLink: 'https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&subservice_id=16836&region_id=19',
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <br />
                    <p>1. Город и Название компании — <em>НЕ МЕНЯЕМ!</em></p>
                    <p>2. ФИО учащегося — <em>заполнить Фамилию Имя Отчество Участника, за которого делается оплата.</em></p>
                    <p>3. Номер телефона — <em>номер телефона Участника (вводить 11 цифр, начиная с цифры "8" или "7", без "+", скобок, пробелов и других символов).</em></p>
                    <p>4. Назначение платежа — <em>Клуб MillionERA.</em></p>
                    <p>5. Пакет/Категория билета — <em>{selectedSubscription.displayName}.</em></p>
                    <p>6. Город участия — <em>Город, в котором проживает Участник.</em></p>
                    <p>7. Сумма платежа — <em>₸{formatCost(selectedSubscription.cost)}.</em></p>
                </>
            )
        },
        {
            Key: 'VisaMC',
            Name: 'Visa/MasterCard',
            PaymentLink: (selectedSubscription: CostInfo) =>
                `https://auth.robokassa.kz/Merchant/Index.aspx?MerchantLogin=SmartInternationalBusiness&InvId=0&Culture=ru&Encoding=utf-8&OutSum=${selectedSubscription.cost}&shp_interface=link&SignatureValue=${selectedSubscription.signatureValue}`,
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <br />
                    <p>1. Заполните E-Mail для отправки квитанции.</p>
                    <p>2. Введите 16 цифр номера карты.</p>
                    <p>3. Введите срок действия и CVV-код.</p>
                    <p>4. На экране подтверждения платежа банка, выпустившего Вашу карту, выполните необходимые действия (ввести SMS или другие способы подтверждения).</p>
                </>
            )
        },
        {
            Key: 'Sberbank',
            Name: 'Сбербанк',
            PaymentLink: null, // No payment link
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <br/>
                    <p>ПЕРЕВОД НА СБЕРБАНК РФ</p>
                    <p><em>(только для граждан РФ)</em></p>
                    <br />
                    <p>Номер карты:</p>
                    <p><b>4279 3800 2192 4487</b> (<span style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                        onClick={() => { copyToClipboard('4279380021924487', 'Номер карты скопирован'); window.setTimeout(() => setLinkTapped(true), delay * 1000) }}>скопировать</span>)</p>
                    <br />
                    <p>Получатель:</p>
                    <p><b>Ерлан Бахытович А.</b></p>
                    <br />
                    <p>Сумма перевода:</p>
                    <p><b>₽{selectedSubscription ? formatCost(selectedSubscription.rubCost) : '—'}</b></p>
                </>
            )
        }
    ];

    const handlePaymentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selected = paymentOptions.find(pay => pay.Name === selectedValue);
        setSelectedPayment(selected || null);
    };

    const [selectedPayment, setSelectedPayment] = React.useState<any>(null);
    const [linkTapped, setLinkTapped] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <div className="stepper-empty-space-top">
                <div className="stepper-select-caption">Выберите способ оплаты</div>
                <div className="stepper-select-wrapper">
                    <select className="stepper-select" onChange={handlePaymentSelect} defaultValue="">
                        <option value="" style={{ display: 'none' }}></option>
                        {paymentOptions.map((option) => (
                            <option key={option.Name} value={option.Name}>
                                {option.Name}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedPayment && selectedPayment.PaymentLink && (
                    <a
                        href={typeof selectedPayment.PaymentLink === 'function' ? selectedPayment.PaymentLink(selectedSubscription) : selectedPayment.PaymentLink}
                        className="stepper-payment-link"
                        target="_blank"
                        onClick={(e) => {
                            e.preventDefault();
                            window.Telegram.WebApp.openLink(e.currentTarget.href, { confirm: false });
                            window.setTimeout(() => setLinkTapped(true), delay * 1000)
                        }}
                    >
                        Ссылка для оплаты {selectedPayment.Key === 'Kaspi' ? 'через Каспи' :
                            selectedPayment.Key === 'VisaMC' ? 'картой Visa/Mastercard' : ''
                        }
                    </a>
                )}

                <div className={selectedPayment == null || selectedPayment.Key === 'Sberbank' ? "stepper-payment-instructions no-top" : "stepper-payment-instructions"}>
                    {selectedPayment ? (
                        <>
                            {selectedPayment.Key === 'Sberbank' ? <br /> : ''}
                            <b>Инструкция по оплате {selectedPayment.Key === 'Kaspi' ? 'через Каспи' :
                                selectedPayment.Key === 'VisaMC' ? 'картой Visa/Mastercard' :
                                    selectedPayment.Key === 'Sberbank' ? 'переводом на Сбербанк РФ' : ''
                                }:</b>
                            {typeof selectedPayment.Instructions === 'function'
                                ? selectedPayment.Instructions(selectedSubscription)
                                : <p>{selectedPayment.Instructions}</p>}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div style={{ 'marginBottom': '20px' }}>
                <div className="stepper-button-container-nomargin">
                    <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                    <button className="stepper-button" onClick={() => onNext(selectedPayment)} disabled={!selectedPayment || !linkTapped}>
                        Далее
                    </button>

                </div>
                <div className="stepper-terms">
                    Производя оплату, Вы соглашаетесь<br />с условиями <a>Договора-оферты</a>
                </div>
            </div>

        </React.Fragment>
    )
};

export default Stepper2;

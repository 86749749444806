import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, MillionaireMorningItem } from '../../store/Models';
import { makeDateTimeRussian } from '../../utils';
import ReflectionFilter from '../ReflectionFilter';
import ReflectionComponent from '../Reflection';
import ReflectionModal from '../ReflectionModal';
import ReactionsMenu from './ReactionsMenu';
import LongPressMenu from './LongPressMenu';
import MillionaireMorningItemComponent from '../MillionaireMorningItem';

interface MillionaireMorningLongPressMenuProps {
    visible: boolean;
    millionaireMorningItem: MillionaireMorningItem;
    user: Account;
    onStartEdit: () => void;
    onDelete: () => void;
    onClose: () => void;
}

const MillionaireMorningLongPressMenu: React.FC<MillionaireMorningLongPressMenuProps> = ({ visible, millionaireMorningItem, user,
    onStartEdit, onDelete, onClose }) => {
    if (!visible) return null;
    if (user.id != millionaireMorningItem.postPreview.post.publishAccount.id) return null;

    return (
        <div className="focus-longpress-menu" onClick={onClose}>
            <div style={{ 'position': 'relative', 'top': '25%', 'marginLeft': '15px' }}>
                <div onClick={(e) => { e.stopPropagation(); }}>
                    <MillionaireMorningItemComponent currentUser={user} millionaireMorningItem={millionaireMorningItem}
                        onContextMenu={() => { }}
                        onTouchMove={() => { }}
                        onTouchStop={() => { }}
                    />
                </div>
                <div className="longpress-menu" style={{ marginTop: '5px', right: '10px' }}>
                    <div className="longpress-item" onClick={onStartEdit}><i className="fas fa-pen" /> Редактировать</div>
                    <div className="longpress-item" onClick={onDelete}><i className="fas fa-trash" /> Удалить</div>
                </div>
            </div>
        </div>
    );
};

export default MillionaireMorningLongPressMenu;

import * as React from 'react';

interface FilterMenuProps {
    visible: boolean;
    position: number;
    mode: string;
    onSelectAll: () => void;
    onSelectNotLowerThanMine: () => void;
    onSelectMy: () => void;
    onClose: () => void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({ visible, position, mode, onSelectAll, onSelectNotLowerThanMine, onSelectMy, onClose }) => {
    if (!visible) return null;

    const reflectionsView = {
        All: 'ALL',
        NotLowerThanMine: 'NOT_LOWER_THAN_MINE',
        My: 'MY'
    }

    /*
     * Go to date: <div className="longpress-item-separator"></div>
            <div className="longpress-item-inactive">
                Перейти к дате:
                <input type="date" className="form-control" onChange={(e) => { }} />
            </div>
     * 
     */ 

    return (
        <div
            className="longpress-menu"
            style={{ top: 40, right: screen.availWidth - position }}
            onClick={onClose}
        >
            <div style={{ 'background': '#ccc', 'fontWeight': 'bold' }} className="longpress-item-inactive">Показать сообщения:</div>
            <div className="longpress-item-separator"></div>
            <div className="longpress-item" onClick={onSelectAll}>{mode === reflectionsView.All ? <i className="fas fa-check" /> : ""} Все</div>
            <div className="longpress-item" onClick={onSelectNotLowerThanMine}>{mode === reflectionsView.NotLowerThanMine ? <i className="fas fa-check" /> : ""} Моего тарифа и выше</div>
            <div className="longpress-item" onClick={onSelectMy}>{mode === reflectionsView.My ? <i className="fas fa-check" /> : ""} Мои</div>
        </div>
    );
};

export default FilterMenu;

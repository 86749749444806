import * as React from 'react';
import PaymentApprovals from '../approvals/PaymentApprovals';
import AdminChallengeManagement from './AdminChallengeManagement';
import AdminCharityManagement from './AdminCharityManagement';
import AdminCuratorBindings from './AdminCuratorBindings';

interface AdminMainScreenProps {
    productionMode: boolean;
}

const AdminMainScreen: React.FC<AdminMainScreenProps> = ({ productionMode }) => {

    const [selectedPage, setSelectedPage] = React.useState<number>(0);

    return (
        <>
            {selectedPage == 0 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>АДМИН. ПАНЕЛЬ</b></div>
                <div className="admin-main-screen-options">
                    <div className="admin-main-screen-option" onClick={() => setSelectedPage(1)}>
                        Поступления<br />Выплаты
                    </div>
                    <div className="admin-main-screen-option" onClick={() => setSelectedPage(2)}>
                        Назначение кураторов<br />ERA Coin
                    </div>
                    <div className="admin-main-screen-option" onClick={() => setSelectedPage(3)}>
                        Челлендж месяца<br />(танцы, рефлексии)
                    </div>
                    <div className="admin-main-screen-option" onClick={() => setSelectedPage(4)}>
                        Благотворительность<br />(ERA Charity)
                    </div>
                </div>
            </div>}
            {selectedPage == 1 && <PaymentApprovals productionMode={productionMode} onBack={() => setSelectedPage(0)} />}
            {selectedPage == 2 && <AdminCuratorBindings productionMode={productionMode} onBack={() => setSelectedPage(0)} />}
            {selectedPage == 3 && <AdminChallengeManagement productionMode={productionMode} onBack={() => setSelectedPage(0)} />}
            {selectedPage == 4 && <AdminCharityManagement productionMode={productionMode} onBack={() => setSelectedPage(0)} />}
        </>
    )
};

export default AdminMainScreen;

import * as React from 'react';

const SpendSVG: React.FC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" id="sign-out-left-2" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className="icon flat-line">
            <polyline id="primary" points="6 15 3 12 6 9" style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }}></polyline>
            <line id="primary-2" data-name="primary" x1="3" y1="12" x2="17" y2="12" style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }}></line>
            <path id="primary-3" data-name="primary" d="M10,8V5a1,1,0,0,1,1-1h9a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V16" style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }}></path></svg>
    );
};

export default SpendSVG;

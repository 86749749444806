import * as React from 'react';
import useFetch from '../../../hooks/useFetch';
import { Account } from '../../../store/Models';
import { eventTarget, getCookie, saveCookie } from '../../../utils';
import AccountAvatar from '../AccountAvatar';
import ChallengeContextMenu from '../ChallengeContextMenu';

interface BottomPanelProps {
    currentUser: Account;
    activeState: string;
    millionaireMorningUnreadCount: number;
    streamsUnreadCount: number;
    reflectionsUnreadCount: number;
    onSelectMillionaireMorning: () => void;
    onSelectStreams: () => void;
    onSelectReflections: () => void;
    onSelectChallenge: () => void;
    onSelectAccountProfile: () => void;
    onSelectAdmin: () => void;
    showChallengeContextMenu: boolean;
    onShowChallengeModes: () => void;
    onSelectChallengeMode: (mode: number) => void;
}

interface Ranks {
    basicChallengeRank: number;
    firstLineChallengeRank: number;
    commandChallengeRank: number;
    coinsChallengeCurrentBalanceRank: number;
    coinsChallengeTotalEarnedRank: number;
}

const BottomPanel: React.FC<BottomPanelProps> = ({ currentUser, activeState, millionaireMorningUnreadCount, streamsUnreadCount, reflectionsUnreadCount,
    onSelectMillionaireMorning, onSelectStreams, onSelectReflections, onSelectChallenge, onSelectAccountProfile, onSelectAdmin,
    showChallengeContextMenu, onShowChallengeModes, onSelectChallengeMode }) => {
    const fetch = useFetch();

    const appState = {
        MillionaireMorning: 'MILLIONAIRE_MORNING',
        Streams: 'STREAMS',
        Reflections: 'REFLECTIONS',
        Challenge: 'CHALLENGE',
        AccountProfile: 'ACCOUNT_PROFILE',
        Admin: 'ADMIN',
        Test: 'TEST',
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        RegDeny: 'DENY',
        Renewal: 'RENEWAL'
    }

    const touchTimerRef = React.useRef<number>(0);

    const challengeTouchStart = () => {

        touchTimerRef.current = window.setTimeout(() => {
            onShowChallengeModes();
        }, 200);
    }

    const challengeTouchEnd = () => {
        window.clearTimeout(touchTimerRef.current);
    }

    const challengeTouchMove = () => {
        window.clearTimeout(touchTimerRef.current);
    }

    const tz = - new Date().getTimezoneOffset() / 60;

    const [basicChallengeUpdates, setBasicChallengeUpdates] = React.useState<boolean>(false);
    const [commandChallengeUpdates, setCommandChallengeUpdates] = React.useState<boolean>(false);
    const [coinChallengeUpdates, setCoinChallengeUpdates] = React.useState<boolean>(false);

    const currentRanksRef = React.useRef<Ranks | null>(null);

    React.useEffect(() => {

        const readBasicChallenge = () => {
            setBasicChallengeUpdates(false);
            if (currentRanksRef.current != null) {
                saveCookie('basic_challenge_rank', currentRanksRef.current.basicChallengeRank.toString(), 30);
            }
        }

        const readCommandChallenge = () => {
            setCommandChallengeUpdates(false);
            if (currentRanksRef.current != null) {
                saveCookie('first_challenge_rank', currentRanksRef.current.firstLineChallengeRank.toString(), 120);
                saveCookie('command_challenge_rank', currentRanksRef.current.commandChallengeRank.toString(), 120);
            }
        }

        const readCoinChallenge = () => {
            setCoinChallengeUpdates(false);
            if (currentRanksRef.current != null) {
                saveCookie('coin_challenge_balance_rank', currentRanksRef.current.coinsChallengeCurrentBalanceRank.toString(), 360);
                saveCookie('coin_challenge_earned_rank', currentRanksRef.current.coinsChallengeTotalEarnedRank.toString(), 360);
            }
        }

        if (currentUser.isAdmin == 0) {
            fetch('/api/challenge/getCurrentRanks?accountId=' + currentUser.id + '&tz=' + tz)
                .then(response => response.json())
                .then(data => {
                    currentRanksRef.current = data;
                    var basicChallengeRankOld = parseInt(getCookie("basic_challenge_rank") || '0');
                    var firstLineChallengeRankOld = parseInt(getCookie("first_challenge_rank") || '0');
                    var commandChallengeRankOld = parseInt(getCookie("command_challenge_rank") || '0');
                    var coinChallengeBalanceRankOld = parseInt(getCookie("coin_challenge_balance_rank") || '0');
                    var coinChallengeEarnedRankOld = parseInt(getCookie("coin_challenge_earned_rank") || '0');
                    if (data.basicChallengeRank - basicChallengeRankOld != 0) {
                        setBasicChallengeUpdates(true);
                    }
                    if (data.firstLineChallengeRank - firstLineChallengeRankOld != 0 || data.commandChallengeRank - commandChallengeRankOld != 0) {
                        setCommandChallengeUpdates(true);
                    }
                    if (data.coinsChallengeCurrentBalanceRank - coinChallengeBalanceRankOld != 0 || data.coinsChallengeTotalEarnedRank - coinChallengeEarnedRankOld != 0) {
                        setCoinChallengeUpdates(true);
                    }
                });
        }
        eventTarget.addEventListener('read_basic_challenge', readBasicChallenge);
        eventTarget.addEventListener('read_command_challenge', readCommandChallenge);
        eventTarget.addEventListener('read_coin_challenge', readCoinChallenge);
        return () => {
            eventTarget.removeEventListener('read_basic_challenge', readBasicChallenge);
            eventTarget.removeEventListener('read_command_challenge', readCommandChallenge);
            eventTarget.removeEventListener('read_coin_challenge', readCoinChallenge);
        }
    }, []);

    return (
        <nav style={{ display: activeState === appState.Reg0 || activeState === appState.Reg1 || activeState === appState.Reg2 || activeState === appState.RegDeny || activeState === appState.Renewal ? 'none' : '' }} className="navbar navbar-light bg-light bottom-panel" id="bottom">
            <div className="container-fluid">
                <div className="bottom-panel-layout">
                    <div className={activeState == appState.MillionaireMorning ? "bottom-panel-layout-millionaire-morning active"
                        : "bottom-panel-layout-millionaire-morning"} onClick={onSelectMillionaireMorning}>
                        {millionaireMorningUnreadCount > 0 && <div className="unread-sticker">{millionaireMorningUnreadCount}</div>}
                        <i className="fas fa-sun" /><br />Утро
                    </div>
                    <div className={activeState == appState.Reflections ? "bottom-panel-layout-reflections active"
                        : "bottom-panel-layout-reflections"} onClick={onSelectReflections}>
                        {reflectionsUnreadCount > 0 && <div className="unread-sticker">{reflectionsUnreadCount}</div>}
                        <i className="fas fa-file-signature" /><br />Рефлексии
                    </div>
                    <div className={activeState == appState.Streams ? "bottom-panel-layout-streams active"
                        : "bottom-panel-layout-streams"} onClick={onSelectStreams}>
                        {streamsUnreadCount > 0 && <div className="unread-sticker">{streamsUnreadCount}</div>}
                        <i className="fas fa-microphone-lines" /><br />Эфиры
                    </div>
                    <div className={activeState == appState.Challenge ? "bottom-panel-layout-challenge active"
                        : "bottom-panel-layout-challenge"} onClick={onSelectChallenge} onContextMenu={(e) => { e.preventDefault(); onShowChallengeModes(); }}
                        onTouchStart={challengeTouchStart} onTouchEnd={challengeTouchEnd} onTouchMove={challengeTouchMove}>
                        {((basicChallengeUpdates ? 1 : 0) + (commandChallengeUpdates ? 1 : 0) + (coinChallengeUpdates ? 1 : 0) > 0) && <div className="unread-sticker">
                            {(basicChallengeUpdates ? 1 : 0) + (commandChallengeUpdates ? 1 : 0) + (coinChallengeUpdates ? 1 : 0)}</div>}
                        <i className="fas fa-trophy" style={{ color: 'goldenrod' }}/><br />Призы
                    </div>
                    <div className={activeState == appState.AccountProfile ? "bottom-panel-layout-account-profile active"
                        : "bottom-panel-layout-account-profile"} onClick={onSelectAccountProfile}>
                        <div className="bottom-panel-avatar">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <AccountAvatar size={19} account={currentUser} showAsAdmin={false} classArg={null}
                                    onClick={() => { }}
                                />
                            </div>
                        </div>
                        Кабинет
                    </div>
                    {currentUser.isAdmin > 0 && <div className={activeState == appState.Admin ? "bottom-panel-layout-admin active"
                        : "bottom-panel-layout-admin"} onClick={onSelectAdmin}>
                        <i className="fas fa-user-gear" /><br />Админ
                    </div>}
                </div>
            </div>
            {showChallengeContextMenu && <ChallengeContextMenu visible={true} onSelectMode={onSelectChallengeMode}
                basicChallengeUpdates={basicChallengeUpdates}
                commandChallengeUpdates={commandChallengeUpdates}
                coinChallengeUpdates={coinChallengeUpdates}
            />}
        </nav>
    );
};

export default BottomPanel;

import * as React from 'react';
import { AccountLookUp, PaymentReward } from '../../../../../store/Models';
import { subscriptionsInfo } from '../../../../../subscriptions';
import { makeDateTime } from '../../../../../utils';
import Autocomplete from '../../simple/Autocomplete';

interface EvenSaleRewardProps {
    onBack: () => void;
    accounts: AccountLookUp[];
}

const EvenSaleReward: React.FC<EvenSaleRewardProps> = ({ onBack, accounts }) => {

    const [selectedAccount, setSelectedAccount] = React.useState<AccountLookUp | null>(null);
    const [selectedPayment1, setSelectedPayment1] = React.useState<PaymentReward | null>(null);
    const [selectedPayment2, setSelectedPayment2] = React.useState<PaymentReward | null>(null);

    const [payment1Items, setPayment1Items] = React.useState<PaymentReward[]>([]);
    const [payment2Items, setPayment2Items] = React.useState<PaymentReward[]>([]);

    const validate = (): boolean => {
        return selectedAccount != null && selectedPayment1 != null && selectedPayment2 != null;
    }

    const createEvenSaleReward = () => {
        //send reward and return back
        if (selectedAccount != null && selectedPayment1 != null && selectedPayment2 != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Payment1ID', selectedPayment1.id.toString());
            formData.append('Payment2ID', selectedPayment2.id.toString());
            formData.append('RewardValue', Math.min(selectedPayment1.rewardValue, selectedPayment2.rewardValue).toString());
            fetch('api/reward/processEvenSaleReward', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    onBack();
                })
                .catch(() => {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                });
        }
    }

    React.useEffect(() => {
        if (selectedAccount != null) {
            fetch('/api/reward/getAvailableEvenSalePaymentsForAccount?id=' + selectedAccount.id)
                .then(res => res.json() as Promise<PaymentReward[]>)
                .then((data) => {
                    setPayment1Items(data);
                });
        }
        else setPayment1Items([]);
    }, [selectedAccount]);

    React.useEffect(() => {
        if (selectedAccount != null && selectedPayment1 != null) {
            fetch('/api/reward/getEvenSalePairCandidates?accountId=' + selectedAccount.id + '&payment1Id=' + selectedPayment1.id + '&payment1SubscriptionCode=' + selectedPayment1.subscriptionCode)
                .then(res => res.json() as Promise<PaymentReward[]>)
                .then((data) => {
                    setPayment2Items(data);
                });
        }
        else setPayment2Items([]);
    }, [selectedPayment1]);

    return (
        <>
            <div className="reg-module-view-header"><b>НАЧИСЛИТЬ ЧЁТНУЮ ПРОДАЖУ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.displayString, value: a.id.toString() } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.displayString}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={() => { setSelectedAccount(null); }}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Продажа 1 (Дата и время/ID/Резидент/Тариф)</div>
                    <div>
                        <Autocomplete options={payment1Items.map(p => {
                            return {
                                label: makeDateTime(p.paymentDateTime) + "/" + p.account.id + "/" + p.account.humanName + "/" + subscriptionsInfo[p.subscriptionCode].displayName,
                                value: p.id.toString()
                            }
                        })}
                            placeholder="Выберите платёж 1" customValuesAllowed={false}
                            onValueSelected={(id) => setSelectedPayment1(payment1Items.filter(x => x.id.toString() === id)[0])} />
                    </div>
                </div>}
                {(selectedAccount && selectedPayment1) && <div className="reg-module-block">
                    <div className="reg-module-label">Продажа 2 (Дата и время/ID/Резидент/Тариф)</div>
                    <div>
                        <Autocomplete options={payment2Items.map(p => {
                            return {
                                label: makeDateTime(p.paymentDateTime) + "/" + p.account.id + "/" + p.account.humanName + "/" + subscriptionsInfo[p.subscriptionCode].displayName,
                                value: p.id.toString()
                            }
                        })}
                            placeholder="Выберите платёж 2" customValuesAllowed={false}
                            onValueSelected={(id) => setSelectedPayment2(payment2Items.filter(x => x.id.toString() === id)[0])} />
                    </div>
                </div>}
                {(selectedAccount && selectedPayment1 && selectedPayment2) && <div className="reg-module-block">
                    <div className="reg-module-label">Сумма вознаграждения за выбранную пару</div>
                    <div>
                        {Math.min(selectedPayment1.rewardValue, selectedPayment2.rewardValue)}
                    </div>
                </div>}

                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={createEvenSaleReward}>Начислить</button>
                </div>
            </div>
        </>
    )
};

export default EvenSaleReward;

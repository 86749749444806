import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, MillionaireMorningItem, ReactionExtended } from '../../store/Models';
import { getReactionByCode, getReactionsTextRussian, makeDateTimeForReaction } from '../../utils';
import AccountAvatar from './AccountAvatar';
import ReactionsTable from './components/simple/ReactionsTable';

interface LongPressMenuProps {
    visible: boolean;
    my: boolean;
    replyTask: MillionaireMorningItem | null;
    visibilityMode: number;
    onWriteReply: () => void;
    onGoToProfile: () => void;
    onStartEdit: () => void;
    onDelete: () => void;
    postId: number;
}

const LongPressMenu: React.FC<LongPressMenuProps> = ({ visible, my, replyTask, visibilityMode, onWriteReply, onGoToProfile, onStartEdit, onDelete, postId }) => {
    if (!visible) return null;

    const reflectionVisibilityRussian = (reflectionVisibility: number): string => {
        switch (reflectionVisibility) {
            case 0: return 'По тарифу';
            case 1: return 'Всем';
            case 2: return 'Только Администрации';
            case 3: return 'Только Ерлану';
            case 10: return 'Ерлан: Базовый и выше';
            case 20: return 'Ерлан: Стандарт и выше';
            case 25: return 'Ерлан: Стандарт Плюс и выше';
            case 30: return 'Ерлан: Бизнес и выше';
            case 40: return 'Ерлан: только Бизнес Плюс';
            case 110: return 'Всем';
            case 120: return 'Стандарт и выше';
            case 125: return 'Стандарт Плюс и выше';
            case 130: return 'Бизнес и выше';
            case 140: return 'Бизнес Плюс';
            case 210: return 'Только Базовый';
            case 220: return 'Только Стандарт';
            case 225: return 'Только Стандарт Плюс';
            case 230: return 'Только Бизнес';
            default: return '';
        }
    };

    const fetch = useFetch();

    const [reactions, setReactions] = React.useState<ReactionExtended[]>([]);

    const [reactionMode, setReactionMode] = React.useState<number>(0);

    React.useEffect(() => {
        fetch('api/reflection/getReactions?postId=' + postId)
            .then(res => res.json() as Promise<ReactionExtended[]>)
            .then(data => {
                setReactions(data);
            });
    }, []);

    /*
     * go to profile
     * {visibilityMode < 100 && <div className="longpress-item" onClick={onGoToProfile}><i className="fas fa-address-card" /> Перейти в профиль</div>}
     */

    return (
        <>
            {reactionMode == 0 && <div className="longpress-menu" style={{ marginTop: '5px', right: my ? '10px' : '40px' }} onClick={() => setReactionMode(1)}>
                {reactions.length > 0 && <div className="longpress-item">
                    <div>
                        <i className="fa-regular fa-heart" />&nbsp;
                        <span>{reactions.length} {getReactionsTextRussian(reactions.length)}</span>
                        <div style={{ display: 'inline-block', position: 'relative', width: '40px', height: '16px' }}>
                            <div style={{ position: 'absolute', right: '5px' }}>
                                {reactions.length > 0 && <AccountAvatar account={reactions[0].account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />}
                            </div>
                            <div style={{ position: 'absolute', right: '-5px' }}>
                                {reactions.length > 1 && <AccountAvatar account={reactions[1].account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />}
                            </div>
                            <div style={{ position: 'absolute', right: '-15px' }}>
                                {reactions.length > 2 && <AccountAvatar account={reactions[2].account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />}
                            </div>
                        </div>
                    </div>
                </div>}
                {reactions.length > 0 && <div className="longpress-item-separator"></div>}
                {visibilityMode < 100 && <div className="longpress-item" onClick={onWriteReply}><i className="fas fa-reply" /> Ответить</div>}
                {my && <div className="longpress-item" onClick={onStartEdit}><i className="fas fa-pen" /> Редактировать</div>}
                {my && <div className="longpress-item" onClick={onDelete}><i className="fas fa-trash" /> Удалить</div>}
                {my && <div className="longpress-item-separator"></div>}
                {
                    replyTask != null && <div className="longpress-item" onClick={() => {
                        location.href = "https://t.me/c/1639867222/35691/53024";
                    }}><i className="fas fa-square-arrow-up-right" /> Перейти к заданию</div>
                }
                <div className="longpress-item-inactive"><i className="fas fa-eye" /> {reflectionVisibilityRussian(visibilityMode)}</div>
            </div>}
            {reactionMode == 1 && <div className="longpress-menu" style={{ marginTop: '5px', right: my ? '10px' : '40px' }}>
                <div style={{ padding: '10px' }} onClick={() => setReactionMode(0)}><i className="fas fa-arrow-left" /> Назад</div>
                <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {(reactions.length > 10 && reactions.findIndex(r => r.reactionCode != reactions[0].reactionCode) > 0)
                        &&
                        <ReactionsTable source={reactions} />
                    }
                    {(reactions.length <= 10 || reactions.findIndex(r => r.reactionCode != reactions[0].reactionCode) <= 0) && reactions.map(r => (
                        <>
                            <div className="longpress-item-separator"></div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AccountAvatar account={r.account} size={30} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                    <div style={{ flex: '1', paddingLeft: '5px' }}>
                                        <div style={{ textOverflow: 'ellipsis', lineHeight: '16px' }}>{r.account.name}</div>
                                        {r.reactionDateTime != null && <div className="reaction-datetime"><i className="fa-regular fa-heart" /> {makeDateTimeForReaction(r.reactionDateTime)}</div>}
                                    </div>
                                    <div>{getReactionByCode(r.reactionCode)}</div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>}
        </>
    );
};

export default LongPressMenu;

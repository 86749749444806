import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account, MillionaireMorningItem, Post, PostFragment } from '../store/Models';
import AudioPlayer from '../components/AudioPlayer';
import YouTubeEmbed from '../components/YouTubeEmbed';
import useFetch from '../hooks/useFetch';
import { makeDateTime } from '../utils';

interface MillionaireMorningItemProps {
    currentUser: Account;
    millionaireMorningItem: MillionaireMorningItem;
    onContextMenu: (millionaireMorningItem: MillionaireMorningItem, isImmediate: boolean) => void;
    onTouchMove: () => void;
    onTouchStop: () => void;
}

const MillionaireMorningItemComponent: React.FC<MillionaireMorningItemProps> = ({ currentUser, millionaireMorningItem, onContextMenu, onTouchMove, onTouchStop }) => {
    const fetch = useFetch();

    const [expandText, setExpandText] = React.useState<boolean>(false);

    const mainElementRef = React.useRef<HTMLDivElement>(null);

    const openText = () => {
        //not allow if item is not available for user
        if (currentUser.activeFrom == null || new Date(currentUser.activeFrom) > new Date(millionaireMorningItem.postPreview.post.publishDateTime)) {
            showNewbieRestrictionModal();
        }
        else {
            setExpandText(true);
        }
        //in any case, read if unread and not mine
        if (millionaireMorningItem.postPreview.post.publishAccount.id != currentUser.id && millionaireMorningItem.isRead == 0) {
            millionaireMorningItem.isRead = 1;
            fetch('/api/counter/readPost?postId=' + millionaireMorningItem.postPreview.post.id);
        }
    }

    const expandTextStyle: React.CSSProperties = {
        color: '#047ff9',
        cursor: 'pointer',
        fontStyle: 'italic'
    }

    const renderTextFull = (text: string) => {
        if (expandText) {
            return renderTextInstant(text);
        }
        else {
            var cutText = text.split(' ').slice(0, 10).join(' '); //10 words
            var splittedText = cutText.split(/\\n+/);
            return <div>{
                splittedText.map((paragraph, index) =>
                    <p key={index}><span dangerouslySetInnerHTML={{ __html: paragraph.length > 0 ? paragraph : '&nbsp;' }}></span>{cutText.length < text.length && index == splittedText.length - 1 ? <span>...<span style={expandTextStyle}
                        onClick={openText}>&nbsp;Читать далее</span></span> : ""}</p>)
            }
            </div>
        }
    }

    const dangerouslySetInnerHtmlAtInstant = (paragraph: string) : string => {
        var parsedSpacesParagraph = paragraph.split(' ').map((t, i) => {
            if (t.length > 0) return `<span key=${i}>${t} </span>`;
            else return `<span key=${i}>&nbsp;</span>`;
        }).join('');

        //find and parse links
        if (millionaireMorningItem.postPreview.post.publishAccount.isAdmin) {
            return parsedSpacesParagraph.replace(/(http||https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, t => `<a href=${t} target="_blank">${t}</a>`);
        }
        else {
            return parsedSpacesParagraph.replace(/(http||https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, t => `(ссылка скрыта)`);
        }
    }

    const renderTextInstant = (text: string) => {
        return <div>{text.split(/\\n+/).map((paragraph, index) =>
            <p key={index} dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHtmlAtInstant(paragraph) }}></p>)}</div>
    }

    const renderFragment = (fragment: PostFragment): React.ReactNode => {
        switch (fragment.contentType.toString()) {
            case '0': //text
                return <div key={fragment.id}>
                    <div className="full-post">
                        {renderTextFull(fragment.content)}
                    </div>
                </div>
            case '1': //audio
                return <AudioPlayer key={fragment.id} src={'/assets/audio/' + fragment.content} />
            case '2': //youtube
                return <YouTubeEmbed key={fragment.id} videoId={fragment.content} width="320" height="180" />
            case '4': //image
                return <img key={fragment.id} width="320" height="200" src={'/assets/images/posters/' + fragment.content} />
            default:
                return <p key={fragment.id}>Unknown fragment content Type!</p>
        }
    }

    const renderPost = (post: Post) => {
        switch (post.contentType.toString()) {
            case '0': //text
                return <div>
                    <div className="full-post">
                        {renderTextFull(post.contentText)}
                    </div>
                </div>
            case '1': //audio
                return <AudioPlayer src={'/assets/audio/' + post.contentRef} />
            case '2': //youtube
                return <YouTubeEmbed videoId={post.contentRef} width="320" height="180" />
            case '4': //image
                return <img width="320" height="200" src={'/assets/images/posters/' + post.contentRef} />
            case '5': //complex
                return <div>
                    {post.fragments.map((fragment: PostFragment) => {
                        return renderFragment(fragment);
                    })
                    }
                </div>
            default:
                return <p>Unknown post content Type!</p>
        }
    }

    const showNewbieRestrictionModal = () => {
        window.Telegram.WebApp.showPopup({
            title: "Упс... Доступ ограничен)", message: "К сожалению,\nу Вас нет возможности просматривать и выполнять задания, опубликованные до Вашего вступления в Клуб.", buttons: [{ text: "Понятно" }]
        });
    }

    /*
     * Touch Events 
     */
    React.useEffect(() => {

        const handleTouchStart = () => {
            if (mainElementRef.current != null) {
                onContextMenu(millionaireMorningItem, false);
            }
        }

        const handleRightClick = (event: MouseEvent) => {
            event.preventDefault();
            if (mainElementRef.current != null) {
                onContextMenu(millionaireMorningItem, true);
            }
        }

        if (window.Telegram.WebApp.platform === 'ios') {
            if (mainElementRef.current != null) {
                mainElementRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
                mainElementRef.current.addEventListener('touchmove', onTouchMove, { passive: false });
                mainElementRef.current.addEventListener('touchend', onTouchStop, { passive: false });

            }
        }
        else {
            if (mainElementRef.current != null) {
                mainElementRef.current.addEventListener('contextmenu', handleRightClick, { passive: false });
            }
        }

        return () => {
            if (window.Telegram.WebApp.platform === 'ios') {
                if (mainElementRef.current != null) {
                    mainElementRef.current.removeEventListener('touchstart', handleTouchStart);
                    mainElementRef.current.removeEventListener('touchmove', onTouchMove);
                    mainElementRef.current.removeEventListener('touchend', onTouchStop);
                }
            }
            else {
                if (mainElementRef.current != null) {
                    mainElementRef.current.removeEventListener('contextmenu', handleRightClick);
                }
            }
        }
    }, []);

    /*
     * old web content
     * 
     * <div className={readState ? "card-body" : "card-body unread"}>
            <PostPreviewComponent preview={millionaireMorningItem.postPreview} onRead={tryOpenRead} isCompleted={millionaireMorningItem.isCompleted > 0} />
            <DetailCard show={showDetail}>
                <h4>{millionaireMorningItem.taskHeader}</h4>
                {renderPost(millionaireMorningItem.postPreview.post)}
                <button className="btn btn-success" onClick={() => { }}>Выполнить</button>
                <br />
                <button className="btn btn-outline-secondary" style={{ 'marginTop': '10px' }} onClick={closeDetail}>&lt; Назад</button>
            </DetailCard>
            <ReflectionModal show={showModal} onClose={closeModal} title='Написание рефлексии' onSubmit={submitModal} replyTask={millionaireMorningItem.taskHeader} />
            <CustomModal show={showWatchModal} onClose={closeWatchModal} title='Поздравляем!'>
                <div className="modal-body">Вы открываете это задание {millionaireMorningItem.postPreview.post.watchCount + 1}-м!</div>
                <div className="modal-footer justify-content-center">
                    <button className="btn btn-primary" onClick={closeWatchModal}>Супер! Перейти к заданию!</button>
                </div>
            </CustomModal>
        </div>
     * 
     */

    const avatarWrapperStyle: React.CSSProperties = {
        'display': 'flex',
        'alignItems': 'flex-end',
        'justifyContent': 'center',
        'marginRight': '1px',
        'marginBottom': '1px'
    }

    const avatarStyle: React.CSSProperties = {
        'width': '40px',
        'height': '40px',
        'position': 'relative',
        'marginRight': '1px',
        'marginBottom': '1px'
    }

    const cardBodyStyle: React.CSSProperties = {
        'borderRadius': '20px',
        'border': 'none',
        'paddingBottom': '10px',
        'paddingTop': '3px',
        'paddingLeft': '10px',
        'marginRight': '40px'
    }

    const bottomStyle: React.CSSProperties = {
        position: 'absolute',
        right: '7px',
        bottom: '5px',
        fontSize: '12px',
        textAlign: 'right',
        color: '#888'
    }

    return (
        <div ref={mainElementRef} className="millionaire-morning-card">
            {millionaireMorningItem.postPreview.post.publishAccount.id != currentUser.id && <div style={avatarWrapperStyle}>
                <div style={avatarStyle}>
                    <img width={40} height={40} src = {
                        'https://pakhomov1907-001-site1.ctempurl.com/api/account/getAvatar?userId=' + millionaireMorningItem.postPreview.post.publishAccount.id
                    } alt="Avatar" className="avatar" />
                    <div className={
                        millionaireMorningItem.postPreview.post.publishAccount.class == 5 ? "level exclusive" :
                            millionaireMorningItem.postPreview.post.publishAccount.class == 10 ? "level basic" :
                                millionaireMorningItem.postPreview.post.publishAccount.class == 20 ? "level standard" :
                                    millionaireMorningItem.postPreview.post.publishAccount.class == 25 ? "level stdplus" :
                                        millionaireMorningItem.postPreview.post.publishAccount.class == 30 ? "level bus" :
                                            "level busplus"}>{millionaireMorningItem.postPreview.post.publishAccount.isAdmin > 0 ? "A" :
                                            millionaireMorningItem.postPreview.post.publishAccount.level}</div>
                </div>
            </div>}
            <div style={cardBodyStyle} className={millionaireMorningItem.postPreview.post.publishAccount.id == currentUser.id ? "card card-body card-mine" :
                (millionaireMorningItem.isRead == 0 && new Date(millionaireMorningItem.postPreview.post.publishDateTime) > new Date(currentUser.activeFrom)) ? "card card-body unread" :
                "card card-body"}>
                <div className={
                    millionaireMorningItem.postPreview.post.publishAccount.class == 5 ? "name exclusive" :
                        millionaireMorningItem.postPreview.post.publishAccount.class == 10 ? "name basic" :
                            millionaireMorningItem.postPreview.post.publishAccount.class == 20 ? "name standard" :
                                millionaireMorningItem.postPreview.post.publishAccount.class == 25 ? "name stdplus" :
                                    millionaireMorningItem.postPreview.post.publishAccount.class == 30 ? "name bus" :
                                    "name busplus"}>
                    <span>{millionaireMorningItem.postPreview.post.publishAccount.name}</span></div>
                <div className="card-text">{renderPost(millionaireMorningItem.postPreview.post)}</div>
                <div style={bottomStyle}>
                    {makeDateTime(millionaireMorningItem.postPreview.post.publishDateTime.toString())}
                </div>
            </div>
        </div>
    );
};

export default MillionaireMorningItemComponent;
import * as React from 'react';
import { Account } from '../../../../../store/Models';
import DanceAddRecordForm from './forms/DanceAddRecordForm';

interface AdminChallengeManagementProps {
    productionMode: boolean;
    onBack: () => void;
}


interface OperDay {
    operDate: string;
    isClosed: number;
}

interface Dance {
    account: Account;
    recordDateTime: string;
    value: number;
}

const AdminChallengeManagement: React.FC<AdminChallengeManagementProps> = ({ productionMode, onBack }) => {

    const [operDays, setOperDays] = React.useState<OperDay[]>([]);

    const [currentDayIndex, setCurrentDayIndex] = React.useState<number>(0);

    const [dances, setDances] = React.useState<Dance[]>([]);

    const [selectedScreen, setSelectedScreen] = React.useState<number>(0);

    const switchToBeginning = () => {
        setCurrentDayIndex(0);
    }

    const switchToPrev = () => {
        setCurrentDayIndex(x => Math.max(0, x - 1));
    }

    const switchToNext = () => {
        setCurrentDayIndex(x => Math.min(operDays.length - 1, x + 1));
    }

    const switchToEnd = () => {
        setCurrentDayIndex(operDays.length - 1);
    }

    React.useEffect(() => {
        if (selectedScreen == 0) {
            if (operDays.length == 0) {
                fetch('/api/admin/getOperDays')
                    .then(res => res.json() as Promise<OperDay[]>)
                    .then((data) => {
                        setOperDays(data);
                        if (data.length > 0) {
                            setCurrentDayIndex(data.length - 1);
                        }
                    });
            }
            else {
                console.log('a');
                fetch('/api/admin/getDances?date=' + operDays[currentDayIndex].operDate)
                    .then(res => res.json() as Promise<Dance[]>)
                    .then((data2) => {
                        setDances(data2);
                    });
            }
        }
    }, [selectedScreen]);

    React.useEffect(() => {
        setDances([]);
        if (operDays.length > 0) {
            console.log('b');
            fetch('/api/admin/getDances?date=' + operDays[currentDayIndex].operDate)
                .then(res => res.json() as Promise<Dance[]>)
                .then((data) => {
                    setDances(data);
                });
        }
    }, [currentDayIndex]);

    const closeDayInner = () => {
        if (operDays.length > 0) {
            fetch('/api/admin/closeOperDay?date=' + operDays[currentDayIndex].operDate)
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'День успешно закрыт.' });
                    }
                    else {
                        alert('День успешно закрыт.');
                    }
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    const closePeriodInner = () => {
        if (operDays.length > 0) {
            fetch('/api/admin/closePeriod?date=' + operDays[currentDayIndex].operDate)
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Период успешно закрыт.' });
                    }
                    else {
                        alert('Период успешно закрыт.');
                    }
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    const closeDay = () => {
        // in production mode show confirm
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите закрытие дня", message: "Вы действительно хотите закрыть день?",
                buttons: [
                    { id: 'confirm', type: 'default', text: "Подтверждаю" },
                    { id: '', type: 'default', text: "Нет" }]
            }, (id: string) => {
                if (id === 'confirm') {
                    closeDayInner();
                }
            });
        }
        else {
            closeDayInner();
        }
    }

    const closePeriod = () => {
        // in production mode show confirm
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите закрытие периода", message: "Вы действительно хотите закрыть период до выбранного дня?",
                buttons: [
                    { id: 'confirm', type: 'default', text: "Подтверждаю" },
                    { id: '', type: 'default', text: "Нет" }]
            }, (id: string) => {
                if (id === 'confirm') {
                    closePeriodInner();
                }
            });
        }
        else {
            closePeriodInner();
        }
    }

    return (
        <>
            {selectedScreen == 0 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>ЧЕЛЛЕНДЖ, ТАНЦЫ, РЕФЛЕКСИИ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                {operDays.length == 0 && <div style={{ width: "100%", textAlign: "center" }}>В системе отсутствуют незакрытые периоды.</div>}
                {operDays.length > 0 && <div className="challenge-view-mode-description">
                    <i className={"fas fa-angles-left challenge-switcher challenge-switcher-begin" + (currentDayIndex == 0 ? " inactive" : "")}
                        onClick={() => {
                            if (currentDayIndex == 0) return; else switchToBeginning();
                        }}
                    />
                    <i className={"fas fa-angle-left challenge-switcher challenge-switcher-left" + (currentDayIndex == 0 ? " inactive" : "")}
                        onClick={() => {
                            if (currentDayIndex == 0) return; else switchToPrev();
                        }}
                    />
                    <div className="charity-date">{operDays[currentDayIndex].operDate} ({['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'][new Date(operDays[currentDayIndex].operDate).getDay()]})
                        {operDays[currentDayIndex].isClosed > 0 && <br />}
                        {operDays[currentDayIndex].isClosed > 0 && <span>(закрыт)</span>}
                    </div>
                    <i className={"fas fa-angle-right challenge-switcher challenge-switcher-right" + (currentDayIndex == operDays.length - 1 ? " inactive" : "")}
                        onClick={() => {
                            if (currentDayIndex == operDays.length - 1) return; else switchToNext();
                        }}
                    />
                    <i className={"fas fa-angles-right challenge-switcher challenge-switcher-end" + (currentDayIndex == operDays.length - 1 ? " inactive" : "")}
                        onClick={() => {
                            if (currentDayIndex == operDays.length - 1) return; else switchToEnd();
                        }}
                    />
                </div>}
                {operDays.length > 0 && <button style={{ marginTop: '10px', marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={() => setSelectedScreen(1)}>Доб. зап. по танцам</button>}
                {(operDays.length > 0 && operDays[currentDayIndex].isClosed == 0) && <button style={{ marginTop: '10px', marginBottom: '10px' }} className="btn btn-warning btn-payment-add-manual" onClick={closeDay}>Закрыть день</button>}
                {operDays.length > 0 &&
                    <div style={{ height: "calc(100% - 126px)", overflowY: 'auto' }}>
                        <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                            <tbody>
                                {dances.map((dance, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{dance.account.humanName}</td>
                                        <td>{dance.value}</td>
                                    </tr>
                                ))}
                                {dances.length == 0 && <tr>
                                    <td style={{ textAlign: 'center' }}>Танцы за выбранный день не заносились в систему.</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>}
            </div>}
            {selectedScreen == 1 && <DanceAddRecordForm onBack={() => setSelectedScreen(0)} selectedDay={operDays[currentDayIndex].operDate} productionMode={productionMode} />}
        </>
    )
};

export default AdminChallengeManagement;

import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, MillionaireMorningItem } from '../../store/Models';
import { makeDateHeaderRussian } from '../../utils';
import MillionaireMorningItemComponent from '../MillionaireMorningItem';
import FocusLongPressMenu from './FocusLongPressMenu';
import MillionaireMorningLongPressMenu from './MillionaireMorningLongPressMenu';

interface MillionaireMorningModuleProps {
    currentUser: Account;
    visible: boolean;
    selectedMillionaireMorningItem: MillionaireMorningItem | null;
    items: MillionaireMorningItem[];
    onSelectMillionaireMorningItem: (item: MillionaireMorningItem | null) => void;
    showContextMenu: boolean;
    onOpenContextMenu: () => void;
    onCloseContextMenu: () => void;
    onDeleteMillionaireMorningItem: (item: MillionaireMorningItem) => void;
    onStartEdit: (item: MillionaireMorningItem) => void;
}

const MillionaireMorningModule: React.FC<MillionaireMorningModuleProps> = ({ currentUser, visible, selectedMillionaireMorningItem, items,
    onSelectMillionaireMorningItem, showContextMenu, onOpenContextMenu, onCloseContextMenu, onDeleteMillionaireMorningItem, onStartEdit }) => {

    const fetch = useFetch();

    const millionaireMorningListRef = React.useRef<HTMLDivElement>(null);

    const [lastScrollPosition, setLastScrollPosition] = React.useState<number>(0);
    const [showScroller, setShowScroller] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (millionaireMorningListRef.current != null) {
            if (visible) {
                millionaireMorningListRef.current.style.display = '';
                let unreadMillionaireMorningItems = getUnreadMillionaireMorningItems();
                if (unreadMillionaireMorningItems.length > 0) { //scroll to first unread message and show scroller with unread counter
                    setToMillionaireMorningItem(unreadMillionaireMorningItems[0].id);
                    window.setTimeout(() => {
                        setShowScroller(true);
                    }, 100);
                }
                else {
                    millionaireMorningListRef.current.scrollTop = millionaireMorningListRef.current.scrollHeight;
                }
            }
            else {
                millionaireMorningListRef.current.style.display = 'none';
            }
        }
    }, [visible]);

    const pressTimer = React.useRef(0);

    const onContextMenu = (millionaireMorningItem: MillionaireMorningItem, isImmediate: boolean) => {
        //prevent window collapsing on iOS
        if (window.Telegram.WebApp.platform === 'ios') {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        }

        if (millionaireMorningItem.id == 0) { //not allow to use context menu on temporary object dummy
            return;
        }

        if (currentUser.id != millionaireMorningItem.postPreview.post.publishAccount.id) return null; //not allow to use context menu for another user

        if (isImmediate == false) {
            pressTimer.current = window.setTimeout(() => {
                onSelectMillionaireMorningItem(millionaireMorningItem);
                onOpenContextMenu();
            }, 800);
        }
        else {
            onSelectMillionaireMorningItem(millionaireMorningItem);
            onOpenContextMenu();
        }
    }

    const onTouchMove = () => {
        if (selectedMillionaireMorningItem != null) {
            onSelectMillionaireMorningItem(null);
        }
        window.clearTimeout(pressTimer.current);
    }

    const onTouchStop = () => {
        window.clearTimeout(pressTimer.current);
    }

    const setToMillionaireMorningItem = (id: number) => {
        var targetElement = document.getElementById(id.toString());

        if (targetElement != null) {
            targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    }

    const scrollToBottom = () => {
        if (millionaireMorningListRef.current != null) {
            millionaireMorningListRef.current.scrollTo({
                top: millionaireMorningListRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
        setShowScroller(false);
    }

    const onScroll = () => {
        if (millionaireMorningListRef.current != null) {
            const scrollTop = millionaireMorningListRef.current.scrollTop;
            const scrollHeight = millionaireMorningListRef.current.scrollHeight;
            const offsetHeight = millionaireMorningListRef.current.offsetHeight;

            if (scrollTop < scrollHeight - offsetHeight - 30) {
                setShowScroller(true);
            } else {
                setShowScroller(false);
            }

            setLastScrollPosition(scrollTop <= 0 ? 0 : scrollTop);
        }
    }

    const getUnreadMillionaireMorningItems = (): MillionaireMorningItem[] => {
        return items.filter(m => m.isRead == 0 && m.postPreview.post.publishAccount.id != currentUser.id &&
            new Date(m.postPreview.post.publishDateTime) > new Date(currentUser.activeFrom));
    }

    const isFirstOnItsDateInLocalTime = (item: MillionaireMorningItem): boolean => {
        // Extract the target date from the target object
        let targetDateCopy = new Date(item.postPreview.post.publishDateTime);
        targetDateCopy.setHours(targetDateCopy.getHours() - targetDateCopy.getTimezoneOffset() / 60); // to local time!
        const targetDate = new Date(targetDateCopy);

        // Filter all objects that share the same date as the target object
        const sameDateObjects = items.filter(r => {
            let dateCopy = new Date(r.postPreview.post.publishDateTime);
            dateCopy.setHours(dateCopy.getHours() - dateCopy.getTimezoneOffset() / 60); // to local time!
            const objDate = new Date(dateCopy);
            return objDate.toDateString() === targetDate.toDateString();
        });

        // Find the object with the minimal date and time among the same date objects
        const minDateObject = sameDateObjects.reduce((minObj, obj) => {
            const objDate = new Date(obj.postPreview.post.publishDateTime);
            return objDate < new Date(minObj.postPreview.post.publishDateTime) ? obj : minObj;
        }, sameDateObjects[0]);

        // Check if the target object is the one with the minimal date
        return item === minDateObject;
    }

    return (
        <div ref={millionaireMorningListRef} className="millionaire-morning-module-layout" onScroll={onScroll}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {items.map((item: MillionaireMorningItem) =>
                            <div className="mt-1 mb-1" key={item.id} data-id={item.id}>
                                {isFirstOnItsDateInLocalTime(item) && <div className="date-header">
                                    <span className="date-header-span-millionaire-morning">{makeDateHeaderRussian(item.postPreview.post.publishDateTime.toString())}</span></div>}
                                <MillionaireMorningItemComponent currentUser={currentUser} millionaireMorningItem={item}
                                    onContextMenu={onContextMenu}
                                    onTouchMove={onTouchMove}
                                    onTouchStop={onTouchStop}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {(showContextMenu && selectedMillionaireMorningItem != null) && <MillionaireMorningLongPressMenu user={currentUser} visible={true}
                millionaireMorningItem={selectedMillionaireMorningItem}
                onStartEdit={() => { onStartEdit(selectedMillionaireMorningItem) }}
                onDelete={() => { onDeleteMillionaireMorningItem(selectedMillionaireMorningItem) }}
                onClose={onCloseContextMenu}
            />}
            {showScroller && <div className={currentUser.isAdmin > 0 ? "bottom-scroller" : "bottom-scroller no-input"} onClick={scrollToBottom}>
                {getUnreadMillionaireMorningItems().length > 0 &&
                    <div className="bottom-scroller-counter">{getUnreadMillionaireMorningItems().length}</div>}
                <i className="fa fa-chevron-down" />
            </div>}
        </div>
        );
};

export default MillionaireMorningModule;

import * as React from 'react';
import { Account } from '../../store/Models';
import { getClassSingle, getResidentsText } from '../../utils';
import AccountAvatar from './AccountAvatar';
import FirstLineSVG from './svg/FirstLineSVG';

interface ClassicViewProps {
    onAccountClick: (account: Account) => void;
    onClose: () => void;
}

interface ClassicLine {
    account: Account;
    line: number;
}

const ClassicView: React.FC<ClassicViewProps> = ({ onAccountClick, onClose }) => {

    const [lines, setLines] = React.useState<ClassicLine[]>([]);
    const [selectedLine, setSelectedLine] = React.useState<number>(1);

    React.useEffect(() => {
        fetch('/api/challenge/getLines')
            .then(r => r.json() as Promise<ClassicLine[]>)
            .then(data => {
                setLines(data);
            });
    }, []);

    return (
        <div className="classic-view">
            <div className="classic-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="classic-view-header"><b>КЛАССИЧЕСКАЯ СХЕМА</b><br /><em>(вся команда до 7-й линии: <b>{lines.length}</b> чел.)</em></div>
            <div className="classic-view-body">
                <div className="classic-view-selector">
                    <div className={selectedLine == 1 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(1)}>
                        <FirstLineSVG /> <span>1-я линия</span>
                    </div>
                    <div className={selectedLine == 2 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(2)}                    >
                        <FirstLineSVG /> <span>2-я линия</span>
                    </div>
                    <div className={selectedLine == 3 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(3)}>
                        <FirstLineSVG /> <span>3-я линия</span>
                    </div>
                </div>
                <div className="classic-view-selector">
                    <div className={selectedLine == 4 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(4)}>
                        <FirstLineSVG /> <span>4-я линия</span>
                    </div>
                    <div className={selectedLine == 5 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(5)}>
                        <FirstLineSVG /> <span>5-я линия</span>
                    </div>
                    <div className={selectedLine == 6 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(6)}>
                        <FirstLineSVG /> <span>6-я линия</span>
                    </div>
                </div>
                <div className="classic-view-selector">
                    <div className={selectedLine == 7 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                        onClick={() => setSelectedLine(7)}>
                        <FirstLineSVG /> <span>7-я линия</span>
                    </div>
                </div>
                <div className="classic-view-table-container">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Резидент</th>
                                <th>Тариф</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lines.filter(l => l.line == selectedLine).length == 0 &&
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>}
                            {lines.filter(l => l.line == selectedLine).map((item, i) =>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{item.account.name}</span></div>
                                    </td>
                                    <td>{getClassSingle(item.account.class)}</td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="classic-view-line-count"><b>{lines.filter(l => l.line == selectedLine).length}</b> чел. — количество Резидентов {selectedLine}-й линии{lines.filter(l => l.line == selectedLine).length > 0 && <span>, из них:</span>}
                {lines.filter(l => l.line == selectedLine).length > 0 &&
                    <span><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<b>{lines.filter(l => l.line == selectedLine && l.account.isActive > 0).length}</b> чел. — с активной подпиской
                        <br />&nbsp;&nbsp;&nbsp;&nbsp;<b>{lines.filter(l => l.line == selectedLine && l.account.isActive == 0).length}</b> чел. — без активной подписки</span>}
                </div>
            </div>
        </div>
    )
};

export default ClassicView;

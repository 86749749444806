import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, MillionaireMorningItem, Reflection } from '../../store/Models';
import { makeDateTimeRussian } from '../../utils';
import ReflectionFilter from '../ReflectionFilter';
import ReflectionComponent from '../Reflection';
import ReflectionModal from '../ReflectionModal';
import ReactionsMenu from './ReactionsMenu';
import LongPressMenu from './LongPressMenu';

interface FocusLongPressMenuProps {
    visible: boolean;
    reflection: Reflection;
    isFirst: boolean;
    isSecond: boolean;
    user: Account;
    onWriteReply: () => void;
    onGoToProfile: (acc: Account) => void;
    onStartEdit: () => void;
    onDelete: () => void;
    onGoToReply: () => void;
    onClose: () => void;
}

const FocusLongPressMenu: React.FC<FocusLongPressMenuProps> = ({ visible, reflection, isFirst, isSecond, user,
    onWriteReply, onGoToProfile, onStartEdit, onDelete, onGoToReply, onClose }) => {
    if (!visible) return null;

    const setReaction = (reactionCode: number) => {
        let isMyReaction =
            reactionCode == 0 && reflection.my0 > 0
            ||
            reactionCode == 1 && reflection.my1 > 0
            ||
            reactionCode == 2 && reflection.my2 > 0
            ||
            reactionCode == 3 && reflection.my3 > 0
            ||
            reactionCode == 4 && reflection.my4 > 0
            ||
            reactionCode == 5 && reflection.my5 > 0;
        var formData = new FormData();
        formData.append('PostID', reflection.post.id.toString());
        formData.append('ReactionCode', reactionCode.toString());

        if (isMyReaction) {
            switch (reactionCode) {
                case 0:
                    reflection.my0 = 0;
                    reflection.reaction0--;
                    break;
                case 1:
                    reflection.my1 = 0;
                    reflection.reaction1--;
                    break;
                case 2:
                    reflection.my2 = 0;
                    reflection.reaction2--;
                    break;
                case 3:
                    reflection.my3 = 0;
                    reflection.reaction3--;
                    break;
                case 4:
                    reflection.my4 = 0;
                    reflection.reaction4--;
                    break;
                case 5:
                    reflection.my5 = 0;
                    reflection.reaction5--;
                    break;
            }
        }
        else {
            switch (reactionCode) {
                case 0:
                    reflection.my0 = 1;
                    reflection.reaction0++;
                    break;
                case 1:
                    reflection.my1 = 1;
                    reflection.reaction1++;
                    break;
                case 2:
                    reflection.my2 = 1;
                    reflection.reaction2++;
                    break;
                case 3:
                    reflection.my3 = 1;
                    reflection.reaction3++;
                    break;
                case 4:
                    reflection.my4 = 1;
                    reflection.reaction4++;
                    break;
                case 5:
                    reflection.my5 = 1;
                    reflection.reaction5++;
                    break;
            }
        }

        fetch(isMyReaction ? 'api/reflection/dropReaction' : 'api/reflection/setReaction', {
            method: 'POST',
            body: formData
        });
    }

    return (
        <div className="focus-longpress-menu" onClick={onClose}>
            <div style={{ 'position': 'relative', 'top': '25%', 'marginLeft': '15px' }} onClick={(e) => { e.stopPropagation(); }}>
                <ReactionsMenu visible={true} my={reflection.post.publishAccount.id === user.id} onSetReaction={(r) => { setReaction(r); onClose(); }} />
                <ReflectionComponent currentUser={user} reflection={reflection}
                    isFirst={isFirst}
                    isSecond={isSecond}
                    isExpanded={false}
                    onContextMenu={() => { }}
                    onReactionContextMenu={() => { }}
                    onTouchMove={() => { }}
                    onTouchStop={() => { }}
                    onGoToReply={onGoToReply}
                    onAccountClick={() => { }}
                    onExpand={() => { }}
                />
                <LongPressMenu visible={true} my={reflection.post.publishAccount.id === user.id} replyTask={reflection.replyTask}
                    visibilityMode={reflection.visibilityMode}
                    onWriteReply={onWriteReply}
                    onGoToProfile={() => onGoToProfile(reflection.post.publishAccount)}
                    onStartEdit={onStartEdit}
                    onDelete={onDelete}
                    postId={reflection.post.id}
                />
            </div>
        </div>
    );
};

export default FocusLongPressMenu;

import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account } from '../../store/Models';
import { getClassSingle } from '../../utils';
import AccountAvatar from './AccountAvatar';

interface LevelChallengeTableViewProps {
    currentUser: Account;
    onAccountClick: (account: Account) => void;
}

const LevelChallengeTableView: React.FC<LevelChallengeTableViewProps> = ({ currentUser, onAccountClick }) => {
    const fetch = useFetch();

    const [levelChallengeData, setLevelChallengeData] = React.useState<Account[]>([]);

    React.useEffect(() => {
        fetch('/api/challenge/GetLevelChallenge')
            .then(response => response.json() as Promise<Account[]>)
            .then(data => {
                setLevelChallengeData(data);
            });
    }, []);

    const uniqueLevels = new Set<number>();

    return (
        <div className="challenge-table-container level-table-container">
            <table className="table table-striped challenge-view-table level-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th>Тариф</th>
                        <th>Ур.</th>
                    </tr>
                </thead>
                <tbody>
                    {levelChallengeData.map((item, i) => {
                        const isNewLevel = !uniqueLevels.has(item.level);
                        if (isNewLevel) {
                            uniqueLevels.add(item.level);
                        }

                        return (
                            <React.Fragment key={item.id}>
                                {isNewLevel && (
                                    <tr>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold' }} colSpan={4}>{item.level} уровень</td>
                                    </tr>
                                )}
                                {item.id != currentUser.id && <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item) }}>{item.name}</span></div>
                                    </td>
                                    <td>{getClassSingle(item.class)}</td>
                                    <td>{item.level}</td>
                                </tr>}
                                {item.id == currentUser.id && <tr key={i}>
                                    <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                    <td style={{ color: '#047ff9' }}>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.name} (Я)</div>
                                    </td>
                                    <td style={{ color: '#047ff9' }}>{getClassSingle(item.class)}</td>
                                    <td style={{ color: '#047ff9' }}>{item.level}</td>
                                </tr>}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default LevelChallengeTableView;

import * as React from 'react';
import useFetch from '../../../hooks/useFetch';
import FilterMenu from '../FilterMenu';

interface TopPanelProps {
    state: string;
    messagesCount: number;
    showFilter: boolean;
    reflectionsViewState: string;
    onOpenFilter: () => void;
    onSelectAll: () => void;
    onSelectNotLowerThanMine: () => void;
    onSelectMy: () => void;
    onCloseFilter: () => void;
}

const TopPanel: React.FC<TopPanelProps> = ({ state, messagesCount, showFilter, reflectionsViewState, onOpenFilter, onSelectAll, onSelectNotLowerThanMine, onSelectMy, onCloseFilter }) => {
    const fetch = useFetch();

    const [filterMenuPosition, setFilterMenuPosition] = React.useState(0);

    const appState = {
        MillionaireMorning: 'MILLIONAIRE_MORNING',
        Streams: 'STREAMS',
        Reflections: 'REFLECTIONS',
        Challenge: 'CHALLENGE',
        AccountProfile: 'ACCOUNT_PROFILE',
        Admin: 'ADMIN',
        Test: 'TEST',
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        RegDeny: 'DENY',
        Renewal: 'RENEWAL'
    }

    const getMessagesTextRussian = (count: number) => {
        if (count % 100 >= 10 && count % 100 <= 20) {
            return 'сообщений';
        }
        else {
            if (count % 10 == 0) return 'сообщений';
            if (count % 10 == 1) return 'сообщение';
            if (count % 10 == 2) return 'сообщения';
            if (count % 10 == 3) return 'сообщения';
            if (count % 10 == 4) return 'сообщения';
            if (count % 10 == 5) return 'сообщений';
            if (count % 10 == 6) return 'сообщений';
            if (count % 10 == 7) return 'сообщений';
            if (count % 10 == 8) return 'сообщений';
            if (count % 10 == 9) return 'сообщений';
        }
    }

    return (
        <nav style={{
            display: state === appState.Challenge || state === appState.AccountProfile || state === appState.Test
                || state === appState.Reg0 || state === appState.Reg1 || state === appState.Reg2 || state === appState.RegDeny || state === appState.Admin || state === appState.Renewal ? 'none' : ''
        }} className="navbar navbar-expand-lg navbar-light bg-light top-panel">
            <div className="container container-fluid">
                <div className="top-panel-layout">
                    <div className="top-panel-layout-center-text">{messagesCount} {getMessagesTextRussian(messagesCount)}</div>
                    {state === appState.Reflections && <div className="top-panel-layout-offset" onClick={(e) => {
                        setFilterMenuPosition(e.clientX); onOpenFilter();
                    }}><i className="fas fa-filter" /></div>}
                </div>
            </div>
            {
                showFilter && <FilterMenu visible={true} mode={reflectionsViewState}
                    position={filterMenuPosition}
                    onSelectAll={onSelectAll}
                    onSelectNotLowerThanMine={onSelectNotLowerThanMine}
                    onSelectMy={onSelectMy}
                    onClose={onCloseFilter} />
            }
        </nav>
        );
};

export default TopPanel;

import React from 'react';

interface DateTimePickerProps {
    label1: string;
    label2: string;
    onValueChanged: (value: string) => void;
}

const Datetimepicker: React.FC<DateTimePickerProps> = ({ label1, label2, onValueChanged }) => {

    const [dayValue, setDayValue] = React.useState<string>('');
    const [monthValue, setMonthValue] = React.useState<string>('');
    const [yearValue, setYearValue] = React.useState<string>('');

    const [hourValue, setHourValue] = React.useState<string>('');
    const [minuteValue, setMinuteValue] = React.useState<string>('');
    const [secondValue, setSecondValue] = React.useState<string>('');

    const days = [];
    for (var i = 0; i < 31; i++) {
        days.push(i + 1);
    }

    const years = [];
    const now = new Date();
    for (var i = now.getFullYear(); i >= now.getFullYear() - 2; i--) {
        years.push(i);
    }

    const hours = [];
    for (var i = 0; i < 24; i++) {
        hours.push(i);
    }

    const minutes = [];
    for (var i = 0; i < 60; i++) {
        minutes.push(i);
    }

    const seconds = [];
    for (var i = 0; i < 60; i++) {
        seconds.push(i);
    }


    const onDaySelect = (value: string) => {
        setDayValue(value);
        if (monthValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + value + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onMonthSelect = (value: string) => {
        setMonthValue(value);
        if (dayValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + value + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onYearSelect = (value: string) => {
        setYearValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(value + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onHourSelect = (value: string) => {
        setHourValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && yearValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + value + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onMinuteSelect = (value: string) => {
        setMinuteValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + value.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onSecondSelect = (value: string) => {
        setSecondValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + value.padStart(2, '0'));
        }
    }

    React.useEffect(() => {
        setDayValue(new Date().getDate().toString());
        setMonthValue((new Date().getMonth() + 1).toString());
        setYearValue(new Date().getFullYear().toString());
        setHourValue("0");
        setMinuteValue("0");
        setSecondValue("0");
        window.setTimeout(() => {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }, 0);
    }, []);

    return (
        <>
            <div className="reg-module-label">{label1}</div>
            <div className="reg-module-datetimepicker">
                <select className="reg-module-datetimepicker-day" onChange={(e) => onDaySelect(e.target.value)} defaultValue={new Date().getDate()}>
                    {days.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>
                <select className="reg-module-datetimepicker-month" onChange={(e) => onMonthSelect(e.target.value)} defaultValue={new Date().getMonth() + 1}>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <select className="reg-module-datetimepicker-year" onChange={(e) => onYearSelect(e.target.value)} defaultValue={new Date().getFullYear()}>
                    {years.map(i => <option value={i}>{i}</option>)}
                </select>
            </div>
            <div className="reg-module-label">{label2}</div>
            <div className="reg-module-datetimepicker">
                <select className="reg-module-datetimepicker-hours" onChange={(e) => onHourSelect(e.target.value)} defaultValue="0">
                    {hours.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>&nbsp;ч.
                <select className="reg-module-datetimepicker-minutes" onChange={(e) => onMinuteSelect(e.target.value)} defaultValue="0">
                    {minutes.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>&nbsp;мин.
                <select className="reg-module-datetimepicker-seconds" onChange={(e) => onSecondSelect(e.target.value)} defaultValue="0">
                    {seconds.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>&nbsp;сек.
            </div>
        </>
    );
};

export default Datetimepicker;
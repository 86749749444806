import * as React from 'react';

const IncomeSVG: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="48" width="48"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 24 24)"><polyline id="primary" points="10 15 7 12 10 9" style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }}></polyline>
            <line id="primary-2" data-name="primary" x1="7" y1="12" x2="21" y2="12" style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }}></line>
            <path id="primary-3" data-name="primary" d="M14,16v3a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V5A1,1,0,0,1,4,4h9a1,1,0,0,1,1,1V8" style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }}></path></g></svg>
    );
};

export default IncomeSVG;

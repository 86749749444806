import * as React from 'react';
import { Account } from '../../store/Models';
import { copyToClipboard, getClassSingle, getMembersCountTextRussian, makeAgeString, makeDateTimeRussian, makeYearsAndMonths } from '../../utils';
import CuratorSVG from './svg/CuratorSVG';
import FirstLineSVG from './svg/FirstLineSVG';
import CommandSVG from './svg/CommandSVG';

interface AccountProfileProps {
    visible: boolean;
    account: Account;
    onShowClassic: (viewMode: number) => void;
    onShowWalletTransactions: () => void;
}

const AccountProfile: React.FC<AccountProfileProps> = ({ visible, account, onShowClassic, onShowWalletTransactions }) => {

    const [commandInfo, setCommandInfo] = React.useState({ firstLineCount: '', totalCount: '' });

    const [viewMode, setViewMode] = React.useState<number>(2);

    const [balance, setBalance] = React.useState<number>(0);

    const curatorRef = React.useRef<Account | null>(null);

    React.useEffect(() => {
        fetch('api/account/getCommandInfo?id=' + account.id)
            .then(r => r.json())
            .then(data => {
                setCommandInfo({
                    firstLineCount: data.firstLineCount,
                    totalCount: data.totalCount
                });
            });
        fetch('api/challenge/getCurator?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                curatorRef.current = data.Account;
            });
        fetch('api/account/getAccountBalance?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                if (data.balance != null) {
                    setBalance(data.balance);
                }
            });
    }, []);

    const clickOnUnset = () => {
        window.Telegram.WebApp.showPopup({
            title: "Данные не указаны", message: "Ваши данные не указаны.\nДля заполнения данных обратитесь\nв Службу Заботы.",
            buttons: [
                { id: 'go_to_service3', type: 'default', text: "Служба Заботы" },
                { id: 'later', type: 'default', text: "Сделаю позже" },
                { id: 'got_it', type: 'default', text: "Понятно" }]
        }, (id: string) => {
            if (id === 'go_to_service3') {
                window.Telegram.WebApp.openTelegramLink('https://t.me/sib_era');
            }
        });
    }

    const clickOnUnsetCurator = () => {
        window.Telegram.WebApp.showPopup({
            title: "Куратор не указан", message: "У Вас не выбран куратор.\nДля выбора куратора обратитесь\nв Службу Заботы.",
            buttons: [
                { id: 'go_to_service4', type: 'default', text: "Служба Заботы" },
                { id: 'later', type: 'default', text: "Сделаю позже" },
                { id: 'got_it', type: 'default', text: "Понятно" }]
        }, (id: string) => {
            if (id === 'go_to_service4') {
                window.Telegram.WebApp.openTelegramLink('https://t.me/sib_era');
            }
        });
    }

    /*
     * <tr>
        <td><i className="fas fa-link" /> Моя ссылка для приглашений</td>
        <td><a href="#">Копировать ссылку</a></td>
       </tr>
     * 
     */

    const processReferralLink = () => {
        if (account.referralLink) {
            copyToClipboard('https://t.me/million_era_bot/club?startapp=' + account.referralLink,
                'Ссылка скопирована в буфер обмена.');
        }
        else {
            fetch('api/account/generateReferralLink?accountId=' + account.id)
                .then(res => res.json())
                .then(data => {
                    account.referralLink = data.linkValue;
                    copyToClipboard('https://t.me/million_era_bot/club?startapp=' + data.linkValue,
                        'Ссылка скопирована в буфер обмена.');
                })
                .catch(() => {
                    alert('Ошибка получения реферральной ссылки. Пожалуйста, попробуйте позднее.');
                });
        }
    }

    return (
        <div className="account-profile" style={{ display: visible ? '' : 'none' }}>
            <div className="account-profile-container">
                <div className="account-profile-container-top">
                    <div className="account-profile-photo" style={{
                        background:
                            `url('${'https://pakhomov1907-001-site1.ctempurl.com/api/account/getAvatar?userId=' + account.id}') no-repeat center`,
                        backgroundSize: 'cover'
                    }}>
                        <img width={100} height={100} src={"https://pakhomov1907-001-site1.ctempurl.com/api/account/getLevelImage?level=" + account.level} />
                    </div>
                    <div className="account-profile-panel">
                        <table style={{ width: '100%', height: '100%' }}>
                            <tbody>
                                <tr>
                                    <td className="account-profile-panel-name">{account.name}</td>
                                    <td className="account-profile-panel-points"><b>{balance}</b> у.е.</td>
                                </tr>
                                <tr>
                                    <td className="account-profile-panel-class">{getClassSingle(account.class)}</td>
                                    <td className="account-profile-panel-coins"><b>{account.eraCoins || 0}</b> ERA Coin</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="account-profile-container-bottom">
                    <div className="account-profile-container-toggle">
                        <div className={viewMode == 1 ? "active" : ""} onClick={() => setViewMode(1)}>Личная информация</div>
                        <div className={viewMode == 2 ? "active" : ""} onClick={() => setViewMode(2)}>Работа с командой</div>
                    </div>
                    {viewMode == 1 && <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td><i className="fas fa-cake-candles" /></td>
                                <td>Дата рождения</td>
                                {account.birthday == null && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указана</td>}
                                {account.birthday != null && <td>{makeAgeString(account.birthday)}</td>}
                            </tr>
                            <tr>
                                <td><i className="fas fa-city" /></td>
                                <td>Город</td>
                                {!account.city && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указан</td>}
                                {account.city && <td>{account.city}</td>}
                            </tr>
                            <tr>
                                <td><i className="fas fa-briefcase" /></td>
                                <td>Занятость</td>
                                {!account.businessKind && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указана</td>}
                                {account.businessKind && <td>{account.businessKind}</td>}
                            </tr>
                            <tr>
                                <td><i className="fas fa-briefcase" /></td>
                                <td>Ниша</td>
                                {!account.businessSphere && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указана</td>}
                                {account.businessSphere && <td>{account.businessSphere}</td>}
                            </tr>
                            <tr>
                                <td><i className="fa-brands fa-instagram" /></td>
                                <td>Instagram</td>
                                {!account.instaProfile && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указан</td>}
                                {account.instaProfile && <td><a target="_blank" href={'https://instagram.com/' + account.instaProfile}>{account.instaProfile}</a></td>}
                            </tr>
                        </tbody>
                    </table>}
                    {viewMode == 2 && <div>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td><i className="fas fa-link" /></td>
                                    <td>Пригласи друга</td>
                                    <td className="account-profile-clickable" onClick={processReferralLink}> Ссылка-приглашение</td>
                                </tr>
                                <tr>
                                    <td><FirstLineSVG /></td>
                                    <td>1-я линия</td>
                                    <td className="account-profile-clickable" onClick={() => onShowClassic(1)}>{commandInfo.firstLineCount !== '' ? <b>{commandInfo.firstLineCount}</b> : '?'} {getMembersCountTextRussian(commandInfo.firstLineCount)}</td>
                                </tr>
                                <tr>
                                    <td><CommandSVG /></td>
                                    <td>Вся команда</td>
                                    <td className="account-profile-clickable" onClick={() => onShowClassic(2)}>{commandInfo.totalCount !== '' ? <b>{commandInfo.totalCount}</b> : '?'} {getMembersCountTextRussian(commandInfo.totalCount)}</td>
                                </tr>
                                <tr>
                                    <td><CuratorSVG /></td>
                                    <td>Мой куратор</td>
                                    {curatorRef.current == null && <td className="account-profile-data-tbd" onClick={clickOnUnsetCurator}>Не выбран</td>}
                                    {curatorRef.current != null && <td>{curatorRef.current.name}</td>}
                                </tr>
                                <tr>
                                    <td><i className="fas fa-wallet" /></td>
                                    <td>Баланс</td>
                                    <td className="account-profile-clickable" onClick={onShowWalletTransactions}><b>{balance}</b> у.е.</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-coins" /></td>
                                    <td>Баланс ERA Coin*</td>
                                    <td><b>{account.eraCoins || 0}</b> ERA Coin</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="account-profile-info-message"><a href="https://t.me/sib_era">* <span>Условия получения и использования ERA Coin</span></a></div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default AccountProfile;

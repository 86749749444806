import * as React from 'react';
import AudioPlayer from './AudioPlayer';

interface FileViewProps {
    reflectionID: string;
    file: string;
}

const FileView: React.FC<FileViewProps> = ({ reflectionID, file }) => {
    const extension: string = file.split('.')[1];

    return (
        <tr>
            <td>
                {extension.toLowerCase() === 'jpg' && <img width="15%" height="15%" src={'/api/file/getFile?reflectionID=' + reflectionID + '&name=' + file} />}
                {extension.toLowerCase() === 'mp3' && <AudioPlayer src={'/api/file/getFile?reflectionID=' + reflectionID + '&name=' + file} />}
                &nbsp;&nbsp;&nbsp;&nbsp;{file}
            </td>
            <td>
                <button className="btn btn-sm btn-outline-secondary"
                    onClick={() => { }}><i className="fas fa-trash" /></button>
            </td>
        </tr>
    )
};

export default FileView;

import * as React from 'react';
import { Account } from '../../../../store/Models';
import { getDaysTextRussian } from '../../../../utils';

interface PaymentReminderProps {
    currentUser: Account;
    onRenewal: () => void;
    onClose: () => void;
}

const PaymentReminder: React.FC<PaymentReminderProps> = ({ currentUser, onRenewal, onClose }) => {

    if (currentUser.daysLeft == null)
        return null;
    else {
        var expirationText = currentUser.daysLeft > 0 ? `через ${currentUser.daysLeft} ${getDaysTextRussian(currentUser.daysLeft)}` : "сегодня";
        return (
            <div className="payment-reminder">
                <div className="payment-reminder-close-btn" onClick={onClose}><i className="fas fa-xmark" /></div>
                <div><i className="fas fa-triangle-exclamation" /> Внимание! Ваша подписка заканчивается {expirationText}! <u onClick={onRenewal}>Продлить</u></div>
            </div>
        )
    }
};

export default PaymentReminder;

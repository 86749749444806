import * as React from 'react';
import useFetch from '../../../hooks/useFetch';
import { Account, MillionaireMorningItem, Reflection } from '../../../store/Models';
import { getCookie, getDaysTextRussian, saveCookie } from '../../../utils';
import AccountProfile from '../AccountProfile';
import AccountView from '../AccountView';
import ChallengeView from '../ChallengeView';
import ClassicView from '../Classic';
import ClassicShortView from '../ClassicShort';
import TestComponent from '../components/simple/TestComponent';
import MillionaireMorningModule from '../MillionaireMorningModule';
import PaymentReminder from '../components/simple/PaymentReminder';
import ReflectionModule from '../ReflectionModule';
import RegModule from '../components/complex/reg/RegModule';
import StreamsModule from '../StreamsModule';
import WalletTransactionsView from '../WalletTransactions';
import BottomPanel from './BottomPanel';
import InputPanel from './InputPanel';
import TopPanel from './TopPanel';
import Renewal from '../components/complex/renewal/Renewal';
import AdminMainScreen from '../components/complex/admin/AdminMainScreen';

interface MainLayoutProps {
    productionMode: boolean;
}

interface ReferralData {
    referrerID: number;
    companyEventID: number;
}

const MainLayout: React.FC<MainLayoutProps> = ({ productionMode }) => {
    const fetch = useFetch();

    const appState = {
        MillionaireMorning: 'MILLIONAIRE_MORNING',
        Streams: 'STREAMS',
        Reflections: 'REFLECTIONS',
        Challenge: 'CHALLENGE',
        AccountProfile: 'ACCOUNT_PROFILE',
        Admin: 'ADMIN',
        Test: 'TEST',
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        Renewal: 'RENEWAL',
        Deny: 'DENY'
    }

    const [state, setState] = React.useState<string>('');

    const userRef = React.useRef<Account>({
        id: 0, class: -1, isActive: 0, name: '',
        isAdmin: 0, level: 0, profileUrl: '', isAmbassador: 0, birthday: '',
        username: '', city: '', businessKind: '', businessSphere: '', eraCoins: 0,
        instaProfile: '', activeFrom: '2024-08-01', canEvaluate: 0, humanName: '', accessGmail: '', daysLeft: null, referralLink: null,
        displayAchievements: null, bonusClass: null
    })

    const [millionaireMorningItems, setMillionaireMorningItems] = React.useState<MillionaireMorningItem[]>([]);
    const reflectionsInitialRef = React.useRef<Reflection[]>([]);
    const [reflections, setReflections] = React.useState<Reflection[]>([]);
    const [streams, setStreams] = React.useState<Reflection[]>([]);

    const mainLayoutRef = React.useRef<HTMLDivElement>(null);

    const [showSendMenu, setShowSendMenu] = React.useState<boolean>(false);
    const [showReflectionContextMenu, setShowReflectionContextMenu] = React.useState<boolean>(false);
    const [showMillionaireMorningItemContextMenu, setShowMillionaireMorningItemContextMenu] = React.useState<boolean>(false);
    const [showFormattingMenu, setShowFormattingMenu] = React.useState<boolean>(false);
    const [showChallengeModes, setShowChallengeModes] = React.useState<boolean>(false);
    const [hidePaymentReminder, setHidePaymentReminder] = React.useState<boolean>(false);
    const [showExpirationText, setShowExpirationText] = React.useState<boolean>(false);

    const editModeRef = React.useRef<boolean>(false);
    const replyModeRef = React.useRef<boolean>(false);

    const [selectedReflection, setSelectedReflection] = React.useState<Reflection | null>(null);
    const [selectedMillionaireMorningItem, setSelectedMillionaireMorningItem] = React.useState<MillionaireMorningItem | null>(null);
    const [selectedChallengeMode, setSelectedChallengeMode] = React.useState<number>(0);
    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);

    const [showClassic, setShowClassic] = React.useState<number>(0);
    const [showWalletTransactions, setShowWalletTransactions] = React.useState<boolean>(false);
    const [referrerID, setReferrerID] = React.useState<number>(0);
    const [companyEventID, setCompanyEventID] = React.useState<number>(0);

    const reflectionsView = {
        All: 'ALL',
        NotLowerThanMine: 'NOT_LOWER_THAN_MINE',
        My: 'MY'
    }

    const [reflectionsViewState, setReflectionsViewState] = React.useState<string>('');
    const [showFilter, setShowFilter] = React.useState<boolean>(false);

    const onSelectMillionaireMorning = () => {
        setState(appState.MillionaireMorning);
        saveCookie("state", appState.MillionaireMorning, 1);
    }

    const onSelectStreams = () => {
        setState(appState.Streams);
        saveCookie("state", appState.Streams, 1);
    }

    const onSelectReflections = () => {
        setState(appState.Reflections);
        saveCookie("state", appState.Reflections, 1);
    }

    const onSelectChallenge = () => {
        setState(appState.Challenge);
        saveCookie("state", appState.Challenge, 1);
    }

    const onSelectAccountProfile = () => {
        setState(appState.AccountProfile);
        saveCookie("state", appState.AccountProfile, 1);
    }

    const onSelectAdmin = () => {
        setState(appState.Admin);
    }

    const onAddDefaultMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setMillionaireMorningItems(x => x.concat(item));
        window.setTimeout(() => {
            var targetElement = document.getElementById('0');

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 200);
    }

    const onEnrichMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setMillionaireMorningItems(x => x.filter(m => m.id != 0).concat(item));
    }

    const onAddDefaultStream = (item: Reflection) => {
        setStreams(x => x.concat(item));
        window.setTimeout(() => {
            var targetElement = document.getElementById('0');

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 200);
    }

    const onEnrichStream = (item: Reflection) => {
        setStreams(x => x.filter(s => s.id != 0).concat(item));
    }

    const onAddDefaultReflection = (item: Reflection) => {
        //enrich reply reflection data
        if (item.replyReflection != null) {
            item.replyReflection = reflections.filter(r => item.replyReflection != null && r.id == item.replyReflection.id)[0];
        }
        setReflections(x => x.concat(item));

        if (item.replyReflection != null) {
            window.setTimeout(() => {
                var targetElement = document.getElementById('0');

                if (targetElement != null) {
                    targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
                }
            }, 200);
        }
        else {
            window.setTimeout(() => {
                var targetElement = document.getElementById('0');

                if (targetElement != null) {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 200);
        }
    }

    const onEnrichReflection = (item: Reflection) => {
        //enrich reply reflection data
        if (item.replyReflection != null) {
            item.replyReflection = reflections.filter(r => item.replyReflection != null && r.id == item.replyReflection.id)[0];
        }
        setReflections(x => x.filter(r => r.id != 0).concat(item));
        reflectionsInitialRef.current.push(item);
    }

    const telegram = productionMode ? window.Telegram.WebApp :
    { initDataUnsafe: { user: { id: "408557182", username: "va_pakhomoff", first_name: "Владимир", last_name: "Пахомов" } } };
    //{ initDataUnsafe: { user: { id: "425780936", username: "sib_era", first_name: "Служба Заботы", last_name: "ERA" } } };
    //{ initDataUnsafe: { user: { id: "539788557", username: "aliya_alkenova", first_name: "Aliya", last_name: "" } } };
    //{ initDataUnsafe: { user: { id: "994471866", username: "ZairaAlievaAbaj", first_name: "Заира", last_name: "Алиева" } } };
    //{ initDataUnsafe: { user: { id: "870314314", username: "Zhuldyzma", first_name: "Zhuldyz", last_name: "M" } } };
    //{ initDataUnsafe: { user: { id: "541104986", username: "dolcevitahappy", first_name: "Jamilya", last_name: "" } } };
    //{ initDataUnsafe: { user: { id: "1123946539", username: "Star_player1", first_name: "Ayan | 🐊", last_name: "" } } };
    //{ initDataUnsafe: { user: { id: "531165293", username: "Karlitta1", first_name: "Карлыгаш", last_name: "" } } };
    //{ initDataUnsafe: { user: { id: "997099832", username: "ZhaniyaZhA", first_name: "Жания", last_name: "" } } };

    const setError = (message: string) => {
        window.Telegram.WebApp.showPopup({ message: message });
    }

    const selectAllReflections = () => {
        if (userRef.current != null) {
            setReflections(reflectionsInitialRef.current.filter(r =>
                r.visibilityMode < 100
            ));
            setReflectionsViewState(reflectionsView.All);
        }
    }

    const selectNotLowerThanMine = () => {
        if (userRef.current != null) {
            setReflections(reflectionsInitialRef.current.filter(r =>
                (r.class >= Math.max(userRef.current.class, userRef.current.bonusClass || 0) && r.visibilityMode < 100)
            ));
            setReflectionsViewState(reflectionsView.NotLowerThanMine);
        }
    }

    const selectMy = () => {
        if (userRef.current != null) {
            setReflections(reflectionsInitialRef.current.filter(r =>
                r.post.publishAccount.id === userRef.current.id && r.visibilityMode < 100));
            setReflectionsViewState(reflectionsView.My);
        }
    }

    const selectStreams = () => {
        setStreams(reflectionsInitialRef.current.filter(r =>
            (userRef.current.isAdmin > 0 && r.visibilityMode > 100)
            ||
            (r.visibilityMode > 100 && 100 + Math.max(userRef.current.class, userRef.current.bonusClass || 0) >= r.visibilityMode)
            ||
            (r.visibilityMode == 110 && userRef.current.class == 5) // for exclusive
            ||
            (r.visibilityMode > 200 && 200 + Math.max(userRef.current.class, userRef.current.bonusClass || 0) == r.visibilityMode)
            ||
            (r.visibilityMode == 210 && userRef.current.class == 5) // for exclusive
        ));
    }

    const processView = () => {
        var cookieState = getCookie("state");
        if (cookieState != null) {
            setState(cookieState);
        }
        else {
            onSelectMillionaireMorning();
        }
    }

    const getInitialData = () => {
        /*
        const params = new URLSearchParams({
            dateFrom: filterState != null && filterState.dateFrom ? filterState.dateFrom : '',
            dateTo: filterState != null && filterState.dateTo ? filterState.dateTo : ''
        });
        */
        fetch(`api/millionaireMorningItem`)
            .then(response => response.json() as Promise<MillionaireMorningItem[]>)
            .then(data => {
                setMillionaireMorningItems(x => x.concat(data));
                fetch(`api/reflection/getReflections`)
                    .then(response => response.json() as Promise<Reflection[]>)
                    .then(data => {
                        // enrich reply reflections with their all data
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].replyReflection != null) {
                                let replyReflection = data[i].replyReflection;
                                data[i].replyReflection = data.filter(r => replyReflection != null && r.id == replyReflection.id)[0];
                            }
                        }
                        reflectionsInitialRef.current = data;
                        selectStreams();
                        selectAllReflections();

                        if (userRef.current.daysLeft != null && userRef.current.daysLeft >= 0 && userRef.current.daysLeft <= 10) {
                            window.setTimeout(processView, 1000);
                        }
                        else if (userRef.current.daysLeft != null && userRef.current.daysLeft < 0) {
                            window.setTimeout(() => setState(appState.Renewal), 1000);
                        }
                        else {
                            processView();
                        }
                    }).catch(e => {
                        console.log(e);
                        setError(e);
                    });
            })
            .catch(e => {
                console.log(e);
                setError(e);
            });
    }

    const processRegViews = (status: number) => {
        switch (status) {
            case 10:
                setState(appState.Reg0);
                break;
            case 20:
                setState(appState.Reg1);
                break;
            case 30:
                setState(appState.Reg2);
                break;
            case -30:
                setState(appState.Deny);
                break;
            default:
                window.Telegram.WebApp.showPopup({
                    title: "Упс... Доступ ограничен)", message: "Для доступа к приложению оплатите подписку в Клубе MillionERA и/или обратитесь\nв Службу Заботы.",
                    buttons: [{ text: "Служба Заботы", callback_data: "go_to_service" }]
                });
                break;
        }
    }

    const processLogin = (formData: FormData) => {
        fetch('/api/account/login', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.status === 200) {
                response.json().then(loginData => {
                    var classValue = parseInt(loginData.class);
                    if (classValue < 0) {
                        if (formData.has('ReferralHash')) {
                            fetch('api/account/getReferralInfo?hash=' + formData.get('ReferralHash'))
                                .then(response => {
                                    if (response.status === 200) {
                                        (response.json() as Promise<ReferralData>).then(referralData => {
                                            setReferrerID(referralData.referrerID);
                                            setCompanyEventID(referralData.companyEventID);
                                            processRegViews(parseInt(loginData.regStatus));
                                        });
                                    }
                                    else if (response.status === 400) {
                                        setError('Ваша ссылка недействительна. Пожалуйста, обратитесь в Службу Заботы.');
                                    }
                                    else {
                                        setError('Ошибка сервера. Пожалуйста, попробуйте позднее.');
                                    }
                                })
                                .catch(e => {
                                    setError('Ошибка обработки запроса. Пожалуйста, попробуйте позднее.');
                                });
                        }
                        else {
                            processRegViews(parseInt(loginData.regStatus));
                        }
                    }
                    else {
                        if (loginData.isActive > 0 || loginData.isActive) {
                            userRef.current = loginData as Account;
                            if (userRef.current.daysLeft != null && userRef.current.daysLeft <= 10) {
                                setShowExpirationText(true);
                            }
                            getInitialData();
                        }
                        else {
                            // to do go to Reg2 (prolong)
                            window.Telegram.WebApp.showPopup({
                                title: "Упс... Доступ ограничен)", message: "Ваша подписка истекла. Для доступа к приложению продлите подписку в Клубе MillionERA и/или обратитесь\nв Службу Заботы.",
                                buttons: [{ text: "Служба Заботы", callback_data: "go_to_service" }]
                            });
                        }
                    }
                });
            }
            else if (response.status === 400) {
                setError('Данная ссылка не может быть использована Вами для регистрации в приложении. Пожалуйста, обратитесь в Службу Заботы.');
            }
            else {
                setError('Ошибка сервера при авторизации. Пожалуйста, попробуйте позднее.');
            }
        }).catch(e => {
            setError('Ошибка обработки запроса при авторизации. Пожалуйста, попробуйте позднее.');
        });
    }

    const getStartParam = (): string | null => {
        if (productionMode) {
            const params = new URLSearchParams(window.Telegram.WebApp.initData);
            return params.get('start_param');
        }
        else return null;
    }

    /* first, get current user */
    React.useEffect(() => {
        window.onerror = () => {
            window.onerror = (e, u, l) => {
                setError(e + ' URL:' + u + ' Line:' + l);
            };
        };

        try {
            var formData = new FormData();
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready();
                if (telegram != null && telegram.initDataUnsafe != null && telegram.initDataUnsafe.user != null) {
                    if (telegram.initDataUnsafe.user.id == null) {
                        setError('Не удалось загрузить данные пользователя (код ошибки: 4). Пожалуйста, попробуйте позднее.');
                    }
                    else {
                        var userId = telegram.initDataUnsafe.user.id != null ? telegram.initDataUnsafe.user.id : '';
                        formData.append('TelegramId', userId);
                        var username = telegram.initDataUnsafe.user.username != null ? telegram.initDataUnsafe.user.username : '';
                        formData.append('Username', username);
                        var firstName = telegram.initDataUnsafe.user.first_name;
                        formData.append('FirstName', firstName);
                        var lastName = telegram.initDataUnsafe.user.last_name;
                        formData.append('LastName', lastName);
                        var referralHash = getStartParam();
                        if (referralHash != null) {
                            formData.append('ReferralHash', referralHash);
                        }
                        processLogin(formData);
                    }
                }
                else {
                    if (telegram == null) {
                        setError('Не удалось загрузить данные пользователя (код ошибки: 1). Пожалуйста, попробуйте позднее.');
                    }
                    else if (telegram.initDataUnsafe == null) {
                        setError('Не удалось загрузить данные пользователя (код ошибки: 2). Пожалуйста, попробуйте позднее.');
                    }
                    else if (telegram.initDataUnsafe.user == null) {
                        setError('Не удалось загрузить данные пользователя (код ошибки: 3). Пожалуйста, попробуйте позднее.');
                    }
                }
            }
            else {
                setError('Приложение недоступно. Пожалуйста, попробуйте позднее.');
            }
        }
        catch (e) {
            setError('Не удалось загрузить данные пользователя (код ошибки: 5). Пожалуйста, попробуйте позднее.');
        }
    }, []);

    React.useEffect(() => {
        if (productionMode) {
            telegram.onEvent('popupClosed', function (event: any) {
                if (event.button_id && event.button_id === 'go_to_service') {
                    location.href = 'https://t.me/sib_era';
                    telegram.close();
                } else {
                    if (userRef.current.class < 0 || userRef.current.isActive == 0) {
                        location.href = 'https://t.me/sib_era';
                        telegram.close();
                    }
                }
            });
        }
    }, []);

    React.useEffect(() => {
        const handleClickOutside = () => {
            if (showSendMenu) {
                setShowSendMenu(false);
            }
            if (showReflectionContextMenu || showMillionaireMorningItemContextMenu) {
                onCloseContextMenu();
            }
            if (showFilter) {
                setShowFilter(false);
            }
            if (showFormattingMenu) {
                setShowFormattingMenu(false);
            }
            if (showChallengeModes) {
                setShowChallengeModes(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showSendMenu, showReflectionContextMenu, showMillionaireMorningItemContextMenu, showFilter, showFormattingMenu, showChallengeModes]);

    const onDeleteReflection = (item: Reflection) => {
        setReflections(x => x.filter(r => r.id !== item.id));
        reflectionsInitialRef.current = reflectionsInitialRef.current.filter(r => r.id !== item.id);
        fetch('/api/reflection/deletePost?id=' + item.post.id + '&reflectionId=' + item.id);
    };

    const onDeleteStream = (item: Reflection) => {
        setStreams(x => x.filter(r => r.id !== item.id));
        fetch('/api/reflection/deletePost?id=' + item.post.id + '&reflectionId=' + item.id);
    }

    const onDeleteMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setMillionaireMorningItems(x => x.filter(m => m.id !== item.id));
        fetch('/api/millionairemorningitem/deletePost?id=' + item.postPreview.post.id);
    }

    const onCloseContextMenu = () => {
        if (selectedReflection != null && !editModeRef.current && !replyModeRef.current) {
            setSelectedReflection(null);
        }
        if (selectedMillionaireMorningItem && !editModeRef.current) {
            setSelectedMillionaireMorningItem(null);
        }

        setShowReflectionContextMenu(false);
        setShowMillionaireMorningItemContextMenu(false);
    }

    const setEditMode = (mode: boolean) => {
        editModeRef.current = mode;
    }

    const setReplyMode = (mode: boolean) => {
        replyModeRef.current = mode;
    }

    const onStartEditReflection = (item: Reflection) => {
        setSelectedReflection(item);
        setEditMode(true);
    }

    const onStartEditMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setSelectedMillionaireMorningItem(item);
        setEditMode(true);
    }

    const onStartReply = (item: Reflection) => {
        setSelectedReflection(item);
        setReplyMode(true);
    }

    const onStopEdit = () => {
        setEditMode(false);
        setSelectedReflection(null);
        setSelectedMillionaireMorningItem(null);
    }

    const onStopReply = () => {
        setReplyMode(false);
        setSelectedReflection(null);
    }

    const getMessagesCount = () => {
        switch (state) {
            case appState.MillionaireMorning:
                return millionaireMorningItems.length;
            case appState.Streams:
                return streams.length;
            case appState.Reflections:
                return reflections.length;
            default:
                return 0;
        }
    }

    if (state === '') {
        var expirationText = userRef.current.daysLeft != null &&
            userRef.current.daysLeft > 0 ? `заканчивается через ${userRef.current.daysLeft} ${getDaysTextRussian(userRef.current.daysLeft)}` :
            userRef.current.daysLeft == 0 ? "заканчивается сегодня" : "закончилась";

        return (
            <div className="main-loader-cover">
                <div className="main-loader-cover-message">Ожидайте.<br />Идёт загрузка данных...</div>
                {showExpirationText && <div className="main-loader-cover-expiration"><i className="fas fa-triangle-exclamation" /> Внимание! Ваша подписка {expirationText}!</div>}
            </div>
        )
    }
    else if (state === appState.Test) {
        return (
            <TestComponent />
        )
    }
    else return (
        <div className="main-layout" ref={mainLayoutRef}>
            {(state !== '' && state !== appState.Renewal && !hidePaymentReminder && userRef.current && userRef.current.daysLeft != null && userRef.current.daysLeft <= 10) &&
                <PaymentReminder currentUser={userRef.current} onRenewal={() => setState(appState.Renewal)} onClose={() => setHidePaymentReminder(true)} />}
            <RegModule state={state} visible={state === appState.Reg0 || state === appState.Reg1 || state === appState.Reg2 || state === appState.Deny}
                referrerID={referrerID} companyEventID={companyEventID} onReg0={() => setState(appState.Reg0)} onFinishReg={() => { parent.location.reload(); }} />
            <TopPanel state={state} messagesCount={getMessagesCount()} reflectionsViewState={reflectionsViewState} showFilter={showFilter} onOpenFilter={() => setShowFilter(true)}
                onSelectAll={selectAllReflections} onSelectNotLowerThanMine={selectNotLowerThanMine} onSelectMy={selectMy} onCloseFilter={() => setShowFilter(false)} />
            <MillionaireMorningModule visible={state === appState.MillionaireMorning} currentUser={userRef.current} items={millionaireMorningItems}
                showContextMenu={showMillionaireMorningItemContextMenu} onOpenContextMenu={() => setShowMillionaireMorningItemContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteMillionaireMorningItem={onDeleteMillionaireMorningItem} onStartEdit={onStartEditMillionaireMorningItem} selectedMillionaireMorningItem={selectedMillionaireMorningItem}
                onSelectMillionaireMorningItem={setSelectedMillionaireMorningItem}
            />
            <StreamsModule visible={state === appState.Streams} currentUser={userRef.current} items={streams}
                showContextMenu={showReflectionContextMenu} onOpenContextMenu={() => setShowReflectionContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteReflection={onDeleteStream} onStartEdit={onStartEditReflection} onStartReply={onStartReply} selectedReflection={selectedReflection}
                onSelectReflection={setSelectedReflection} onAccountClick={setSelectedAccount}
            />
            <ReflectionModule visible={state === appState.Reflections} currentUser={userRef.current} items={reflections}
                showContextMenu={showReflectionContextMenu} onOpenContextMenu={() => setShowReflectionContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteReflection={onDeleteReflection} onStartEdit={onStartEditReflection} onStartReply={onStartReply} selectedReflection={selectedReflection}
                onSelectReflection={setSelectedReflection} onAccountClick={setSelectedAccount}
            />
            <ChallengeView visible={state === appState.Challenge} currentUser={userRef.current} mode={selectedChallengeMode} onAccountClick={setSelectedAccount} />
            <AccountProfile visible={state === appState.AccountProfile} account={userRef.current} onShowClassic={setShowClassic} onShowWalletTransactions={() => setShowWalletTransactions(true)} />
            {state === appState.Admin && <AdminMainScreen productionMode={productionMode} />}
            <InputPanel state={state} currentUser={userRef.current}
                selectedReflection={selectedReflection} selectedMillionaireMorningItem={selectedMillionaireMorningItem}
                onAddDefaultMillionaireMorningItemCallback={onAddDefaultMillionaireMorningItem}
                onEnrichMillionaireMorningItemCallback={onEnrichMillionaireMorningItem}
                onAddDefaultStreamCallback={onAddDefaultStream}
                onEnrichStreamCallback={onEnrichStream}
                onAddDefaultReflectionCallback={onAddDefaultReflection}
                onEnrichReflectionCallback={onEnrichReflection}
                showSendMenu={showSendMenu} onOpenContextMenu={() => setShowSendMenu(true)} onCloseContextMenu={() => setShowSendMenu(false)}
                editMode={editModeRef.current} replyMode={replyModeRef.current} onStopEdit={onStopEdit} onStopReply={onStopReply}
                mainLayoutRef={mainLayoutRef}
            />
            <BottomPanel currentUser={userRef.current} activeState={state}
                millionaireMorningUnreadCount={millionaireMorningItems.filter(m => m.isRead == 0 && m.postPreview.post.publishAccount.id != userRef.current.id && new Date(m.postPreview.post.publishDateTime) > new Date(userRef.current.activeFrom)).length}
                streamsUnreadCount={streams.filter(s => s.isRead == 0 && s.post.publishAccount.id != userRef.current.id && new Date(s.post.publishDateTime) > new Date(userRef.current.activeFrom)).length}
                reflectionsUnreadCount={reflections.filter(r => r.isRead == 0 && r.post.publishAccount.id != userRef.current.id && new Date(r.post.publishDateTime) > new Date(userRef.current.activeFrom)).length}
                onSelectMillionaireMorning={() => { setSelectedAccount(null); setShowClassic(0); setShowWalletTransactions(false); onSelectMillionaireMorning(); }}
                onSelectStreams={() => { setSelectedAccount(null); setShowClassic(0); setShowWalletTransactions(false); onSelectStreams(); }}
                onSelectReflections={() => { setSelectedAccount(null); setShowClassic(0); setShowWalletTransactions(false); onSelectReflections(); }}
                onSelectChallenge={() => { setSelectedAccount(null); setShowClassic(0); setShowWalletTransactions(false); onSelectChallenge(); }}
                onSelectAccountProfile={() => { setSelectedAccount(null); setShowClassic(0); setShowWalletTransactions(false); onSelectAccountProfile(); }}
                onSelectAdmin={() => { setSelectedAccount(null); setShowClassic(0); setShowWalletTransactions(false); onSelectAdmin(); }}
                showChallengeContextMenu={showChallengeModes}
                onShowChallengeModes={() => setShowChallengeModes(true)} onSelectChallengeMode={(m) => { setState(appState.Challenge); setSelectedChallengeMode(m); }}
            />
            {selectedAccount != null && <AccountView account={selectedAccount} onClose={() => setSelectedAccount(null)} />}
            {showClassic == 1 && <ClassicShortView onAccountClick={setSelectedAccount} onClose={() => { setShowClassic(0); }} />}
            {showClassic == 2 && <ClassicView onAccountClick={setSelectedAccount} onClose={() => { setShowClassic(0); }} />}
            {showWalletTransactions && <WalletTransactionsView currentUser={userRef.current} onAccountClick={setSelectedAccount} onClose={() => { setShowWalletTransactions(false); }} />}
            {state === appState.Renewal && <Renewal onBack={processView} currentUser={userRef.current} onFinishPayment={() => { parent.location.reload(); }} />}
        </div>
    );
};

export default MainLayout;

import * as React from 'react';
import { ReactionExtended } from '../../../../store/Models';
import { getReactionByCode, makeDateTimeForReaction } from '../../../../utils';
import AccountAvatar from '../../AccountAvatar';

interface ReactionsTableProps {
    source: ReactionExtended[];
}


const ReactionsTable: React.FC<ReactionsTableProps> = ({ source }) => {

    const [selectedReaction, setSelectedReaction] = React.useState(-1);

    const reactions0 = source.filter(s => s.reactionCode == 0);
    const reactions1 = source.filter(s => s.reactionCode == 1);
    const reactions2 = source.filter(s => s.reactionCode == 2);
    const reactions3 = source.filter(s => s.reactionCode == 3);
    const reactions4 = source.filter(s => s.reactionCode == 4);
    const reactions5 = source.filter(s => s.reactionCode == 5);

    return (
        <div>
            <div className="longpress-item-separator"></div>
            <div style={{ padding: '10px' }}>
                <div className={selectedReaction == -1 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(-1)}><i className="fa-regular fa-heart" /> {source.length}</div>
                {reactions0.length > 0 && <div className={selectedReaction == 0 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(0)}>{getReactionByCode(0)}{reactions0.length}</div>}
                {reactions1.length > 0 && <div className={selectedReaction == 1 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(1)}>{getReactionByCode(1)}{reactions1.length}</div>}
                {reactions2.length > 0 && <div className={selectedReaction == 2 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(2)}>{getReactionByCode(2)}{reactions2.length}</div>}
                {reactions3.length > 0 && <div className={selectedReaction == 3 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(3)}>{getReactionByCode(3)}{reactions3.length}</div>}
                {reactions4.length > 0 && <div className={selectedReaction == 4 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(4)}>{getReactionByCode(4)}{reactions4.length}</div>}
                {reactions5.length > 0 && <div className={selectedReaction == 5 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(5)}>{getReactionByCode(5)}{reactions5.length}</div>}
            </div>        
            {selectedReaction == -1 && <div>
                {source.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AccountAvatar account={r.account} size={30} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px' }}>
                                    <div style={{ textOverflow: 'ellipsis', lineHeight: '16px' }}>{r.account.name}</div>
                                    {r.reactionDateTime != null && <div className="reaction-datetime"><i className="fa-regular fa-heart" /> {makeDateTimeForReaction(r.reactionDateTime)}</div>}
                                </div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction == 0 && <div>
                {reactions0.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{r.account.name}</div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction == 1 && <div>
                {reactions1.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{r.account.name}</div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction == 2 && <div>
                {reactions2.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{r.account.name}</div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction == 3 && <div>
                {reactions3.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{r.account.name}</div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction == 4 && <div>
                {reactions4.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{r.account.name}</div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction == 5 && <div>
                {reactions5.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{r.account.name}</div>
                                <div>{getReactionByCode(r.reactionCode)}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
        </div>
    )
};

export default ReactionsTable;

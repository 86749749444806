import * as React from 'react';
import { AccountLookUp, PaymentReward } from '../../../../../store/Models';
import { getNominalBySubscriptionCode, getSubscriptionCodeByClass, subscriptionsInfo } from '../../../../../subscriptions';
import { makeDateTime } from '../../../../../utils';
import Autocomplete from '../../simple/Autocomplete';

interface FastStartRewardProps {
    onBack: () => void;
    accounts: AccountLookUp[];
}

const FastStartReward: React.FC<FastStartRewardProps> = ({ onBack, accounts }) => {

    const [selectedAccount, setSelectedAccount] = React.useState<AccountLookUp | null>(null);
    const [selectedPayment1, setSelectedPayment1] = React.useState<PaymentReward | null>(null);
    const [selectedPayment2, setSelectedPayment2] = React.useState<PaymentReward | null>(null);
    const [selectedPayment3, setSelectedPayment3] = React.useState<PaymentReward | null>(null);
    const [selectedPayment4, setSelectedPayment4] = React.useState<PaymentReward | null>(null);

    const [payment1Items, setPayment1Items] = React.useState<PaymentReward[]>([]);
    const [payment2Items, setPayment2Items] = React.useState<PaymentReward[]>([]);
    const [payment3Items, setPayment3Items] = React.useState<PaymentReward[]>([]);
    const [payment4Items, setPayment4Items] = React.useState<PaymentReward[]>([]);

    const validate = (): boolean => {
        return selectedAccount != null && selectedPayment1 != null && selectedPayment2 != null
            && selectedPayment3 != null && selectedPayment4 != null;
    }

    const getRewardValues = (selectedAccount: AccountLookUp, payment1: PaymentReward, payment2: PaymentReward, payment3: PaymentReward, payment4: PaymentReward) => {
        var paymentForReward = [payment1, payment2, payment3, payment4]
            .find(p => p.rewardValue == Math.min(payment1.rewardValue, payment2.rewardValue,
                payment3.rewardValue, payment4.rewardValue));
        if (paymentForReward != null) {
            var potentialReward = getNominalBySubscriptionCode(paymentForReward.subscriptionCode);
            var maximalReward = getNominalBySubscriptionCode(getSubscriptionCodeByClass(selectedAccount.class));

            var ecp = Math.min(maximalReward, potentialReward);
            var ncp = Math.max(0, potentialReward - maximalReward);
            return {
                ecp: ecp,
                ncp: ncp
            }
        }
        else return {
            ecp: 0,
            ncp: 0
        };
    }

    const createFastStartReward = () => {
        //send reward and return back
        if (selectedAccount != null && selectedPayment1 != null && selectedPayment2 != null && selectedPayment3 != null && selectedPayment4 != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Payment1ID', selectedPayment1.id.toString());
            formData.append('Payment2ID', selectedPayment2.id.toString());
            formData.append('Payment3ID', selectedPayment3.id.toString());
            formData.append('Payment4ID', selectedPayment4.id.toString());

            var rewards = getRewardValues(selectedAccount, selectedPayment1, selectedPayment2, selectedPayment3, selectedPayment4);
            if (rewards.ecp > 0) {
                formData.append('RewardValue', rewards.ecp.toString());
                if (rewards.ncp > 0) {
                    formData.append('MissedValue', rewards.ncp.toString());
                }
                fetch('api/reward/processFastStartReward', {
                    method: 'POST',
                    body: formData
                })
                    .then(() => {
                        onBack();
                    })
                    .catch(() => {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    });
            }
        }
    }

    React.useEffect(() => {
        if (selectedAccount != null) {
            fetch('/api/reward/getAvailableFastStartPaymentsForAccount?id=' + selectedAccount.id)
                .then(res => res.json() as Promise<PaymentReward[]>)
                .then((data) => {
                    setPayment1Items(data);
                });
        }
        else setPayment1Items([]);
    }, [selectedAccount]);

    React.useEffect(() => {
        if (selectedAccount != null && selectedPayment1 != null) {
            fetch('/api/reward/getFastStartPackCandidates?accountId=' + selectedAccount.id + '&payment1Id=' + selectedPayment1.id)
                .then(res => res.json() as Promise<PaymentReward[]>)
                .then((data) => {
                    setPayment2Items(data);
                });
        }
        else setPayment2Items([]);
    }, [selectedPayment1]);

    React.useEffect(() => {
        if (selectedAccount != null && selectedPayment1 != null && selectedPayment2 != null) {
            fetch('/api/reward/getFastStartPackCandidates?accountId=' + selectedAccount.id
                + '&payment1Id=' + selectedPayment1.id
                + '&payment2Id=' + selectedPayment2.id)
                .then(res => res.json() as Promise<PaymentReward[]>)
                .then((data) => {
                    setPayment3Items(data);
                });
        }
        else setPayment3Items([]);
    }, [selectedPayment2]);

    React.useEffect(() => {
        if (selectedAccount != null && selectedPayment1 != null && selectedPayment2 != null && selectedPayment3 != null) {
            fetch('/api/reward/getFastStartPackCandidates?accountId=' + selectedAccount.id
                + '&payment1Id=' + selectedPayment1.id
                + '&payment2Id=' + selectedPayment2.id
                + '&payment3Id=' + selectedPayment3.id)
                .then(res => res.json() as Promise<PaymentReward[]>)
                .then((data) => {
                    setPayment4Items(data);
                });
        }
        else setPayment4Items([]);
    }, [selectedPayment3]);

    return (
        <>
            <div className="reg-module-view-header"><b>НАЧИСЛИТЬ БОНУС БЫСТРОГО СТАРТА</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.displayString, value: a.id.toString() } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.displayString}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={() => { setSelectedAccount(null); }}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Продажа 1 (Дата и время/ID/Резидент/Тариф)</div>
                    <div>
                        <Autocomplete options={payment1Items.map(p => {
                            return {
                                label: makeDateTime(p.paymentDateTime) + "/" + p.account.id + "/" + p.account.humanName + "/" + subscriptionsInfo[p.subscriptionCode].displayName,
                                value: p.id.toString()
                            }
                        })}
                            placeholder="Выберите платёж 1" customValuesAllowed={false}
                            onValueSelected={(id) => setSelectedPayment1(payment1Items.filter(x => x.id.toString() === id)[0])} />
                    </div>
                </div>}
                {(selectedAccount && selectedPayment1) && <div className="reg-module-block">
                    <div className="reg-module-label">Продажа 2 (Дата и время/ID/Резидент/Тариф)</div>
                    <div>
                        <Autocomplete options={payment2Items.map(p => {
                            return {
                                label: makeDateTime(p.paymentDateTime) + "/" + p.account.id + "/" + p.account.humanName + "/" + subscriptionsInfo[p.subscriptionCode].displayName,
                                value: p.id.toString()
                            }
                        })}
                            placeholder="Выберите платёж 2" customValuesAllowed={false}
                            onValueSelected={(id) => setSelectedPayment2(payment2Items.filter(x => x.id.toString() === id)[0])} />
                    </div>
                </div>}
                {(selectedAccount && selectedPayment1 && selectedPayment2) && <div className="reg-module-block">
                    <div className="reg-module-label">Продажа 3 (Дата и время/ID/Резидент/Тариф)</div>
                    <div>
                        <Autocomplete options={payment3Items.map(p => {
                            return {
                                label: makeDateTime(p.paymentDateTime) + "/" + p.account.id + "/" + p.account.humanName + "/" + subscriptionsInfo[p.subscriptionCode].displayName,
                                value: p.id.toString()
                            }
                        })}
                            placeholder="Выберите платёж 3" customValuesAllowed={false}
                            onValueSelected={(id) => setSelectedPayment3(payment3Items.filter(x => x.id.toString() === id)[0])} />
                    </div>
                </div>}
                {(selectedAccount && selectedPayment1 && selectedPayment2 && selectedPayment3) && <div className="reg-module-block">
                    <div className="reg-module-label">Продажа 4 (Дата и время/ID/Резидент/Тариф)</div>
                    <div>
                        <Autocomplete options={payment4Items.map(p => {
                            return {
                                label: makeDateTime(p.paymentDateTime) + "/" + p.account.id + "/" + p.account.humanName + "/" + subscriptionsInfo[p.subscriptionCode].displayName,
                                value: p.id.toString()
                            }
                        })}
                            placeholder="Выберите платёж 3" customValuesAllowed={false}
                            onValueSelected={(id) => setSelectedPayment4(payment4Items.filter(x => x.id.toString() === id)[0])} />
                    </div>
                </div>}
                {(selectedAccount && selectedPayment1 && selectedPayment2 && selectedPayment3 && selectedPayment4) && <div className="reg-module-block">
                    <div className="reg-module-label">Сумма вознаграждения за выбранную четвёрку</div>
                    <div>
                        <span style={{ color: 'green' }}>{getRewardValues(selectedAccount, selectedPayment1, selectedPayment2, selectedPayment3, selectedPayment4).ecp}</span>
                        {getRewardValues(selectedAccount, selectedPayment1, selectedPayment2, selectedPayment3, selectedPayment4).ncp > 0 &&
                            <span style={{ color: 'red' }}>{getRewardValues(selectedAccount, selectedPayment1, selectedPayment2, selectedPayment3, selectedPayment4).ncp}
                            </span>}
                    </div>
                </div>}

                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={createFastStartReward}>Начислить</button>
                </div>
            </div>
        </>
    )
};

export default FastStartReward;

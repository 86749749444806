import * as React from 'react';
import { Account } from '../../../../../store/Models';
import Autocomplete from '../../simple/Autocomplete';
import Datepicker from '../../simple/Datepicker';

interface Reg0Props {
    visible: boolean;
    referrerID: number;
    companyEventID: number;
    onFinish: () => void;
}

const Reg0: React.FC<Reg0Props> = ({ visible, referrerID, companyEventID, onFinish }) => {

    const businessKindOptions = [
        { value: '', label: 'Занятость (выберите из списка)' },
        { value: 'Свой бизнес', label: 'Свой бизнес' },
        { value: 'Фриланс/самозанятый', label: 'Фриланс/самозанятый' },
        { value: 'Найм', label: 'Найм' },
        { value: 'Домохозяйка', label: 'Домохозяйка' },
    ]

    const businessSphereOptions = [
        { value: '', label: 'Ниша (выберите из списка)' },
        { value: 'Образование', label: 'Образование' },
        { value: 'Строительство', label: 'Строительство' },
        { value: 'Логистика', label: 'Логистика' },
        { value: 'Медицина', label: 'Медицина' },
        { value: 'Финансовые услуги', label: 'Финансовые услуги' },
        { value: 'ИТ и технологии', label: 'ИТ и технологии' },
        { value: 'Ресторанный бизнес', label: 'Ресторанный бизнес' },
        { value: 'Туризм и путешествия', label: 'Туризм и путешествия' },
        { value: 'Мода', label: 'Мода' },
        { value: 'Недвижимость', label: 'Недвижимость' },
        { value: 'Производство', label: 'Производство' },
        { value: 'Экологические услуги', label: 'Экологические услуги' },
        { value: 'Агроиндустрия', label: 'Агроиндустрия' },
        { value: 'Маркетинг и реклама', label: 'Маркетинг и реклама' },
        { value: 'Финансовый консалтинг', label: 'Финансовый консалтинг' },
        { value: 'Здравоохранение', label: 'Здравоохранение' },
        { value: 'Транспорт', label: 'Транспорт' },
        { value: 'Гостиничный бизнес', label: 'Гостиничный бизнес' },
        { value: 'Культура и искусство', label: 'Культура и искусство' },
        { value: 'Спортивные услуги', label: 'Спортивные услуги' },
        { value: 'Юридические услуги', label: 'Юридические услуги' },
        { value: 'Обслуживание клиентов', label: 'Обслуживание клиентов' },
        { value: 'Домашние услуги', label: 'Домашние услуги' },
        { value: 'Образование взрослых', label: 'Образование взрослых' },
        { value: 'Торговля', label: 'Торговля' },
        { value: 'Графический дизайн', label: 'Графический дизайн' },
        { value: 'Автосервис', label: 'Автосервис' },
        { value: 'IT-консалтинг', label: 'IT-консалтинг' },
        { value: 'Стартапы', label: 'Стартапы' },
        { value: 'Фриланс', label: 'Фриланс' },
        { value: 'Ремесленные изделия', label: 'Ремесленные изделия' },
        { value: 'Курьерские услуги', label: 'Курьерские услуги' },
        { value: 'Психология', label: 'Психология' },
        { value: 'Туристическое сопровождение', label: 'Туристическое сопровождение' },
        { value: 'Аудит', label: 'Аудит' },
        { value: 'Пиар', label: 'Пиар' },
        { value: 'Киноиндустрия', label: 'Киноиндустрия' },
        { value: 'Музыкальная индустрия', label: 'Музыкальная индустрия' },
        { value: 'Книжное издательство', label: 'Книжное издательство' },
        { value: 'Обслуживание систем', label: 'Обслуживание систем' },
        { value: 'Веб-дизайн', label: 'Веб-дизайн' },
        { value: 'Техническое обслуживание', label: 'Техническое обслуживание' },
        { value: 'Ландшафтный дизайн', label: 'Ландшафтный дизайн' },
        { value: 'Образование для детей', label: 'Образование для детей' },
        { value: 'Энергетика', label: 'Энергетика' },
        { value: 'Торговля услугами', label: 'Торговля услугами' },
        { value: 'Зарубежное консульство', label: 'Зарубежное консульство' },
        { value: 'Массаж и spa', label: 'Массаж и spa' },
        { value: 'Кулинарные курсы', label: 'Кулинарные курсы' },
        { value: 'Изготовление мебели', label: 'Изготовление мебели' },
        { value: 'Услуги для домашних животных', label: 'Услуги для домашних животных' },
        { value: 'Услуги по безопасности', label: 'Услуги по безопасности' },
        { value: 'Инвестиции', label: 'Инвестиции' },
        { value: 'Частные медицинские услуги', label: 'Частные медицинские услуги' },
        { value: 'Финансовые технологии (FinTech)', label: 'Финансовые технологии (FinTech)' },
        { value: 'Виртуальные услуги', label: 'Виртуальные услуги' },
        { value: 'Агентства по трудоустройству', label: 'Агентства по трудоустройству' },
        { value: 'Гостиничные услуги', label: 'Гостиничные услуги' },
        { value: 'Медицинская техника', label: 'Медицинская техника' },
        { value: 'Логистика и складирование', label: 'Логистика и складирование' },
        { value: 'Проведение мероприятий', label: 'Проведение мероприятий' },
        { value: 'Архивное дело', label: 'Архивное дело' },
        { value: 'Клининговые услуги', label: 'Клининговые услуги' },
        { value: 'Услуги по организации праздников', label: 'Услуги по организации праздников' },
        { value: 'Здоровый образ жизни', label: 'Здоровый образ жизни' },
        { value: 'Услуги для бизнеса', label: 'Услуги для бизнеса' },
        { value: 'Обслуживание корпоративных клиентов', label: 'Обслуживание корпоративных клиентов' },
        { value: 'Электронная коммерция', label: 'Электронная коммерция' },
        { value: 'Служба поддержки клиентов', label: 'Служба поддержки клиентов' },
        { value: 'Реставрация и ремонт', label: 'Реставрация и ремонт' },
        { value: 'Промышленное оборудование', label: 'Промышленное оборудование' },
        { value: 'Ремонт техники', label: 'Ремонт техники' },
        { value: 'Продвижение в социальных сетях', label: 'Продвижение в социальных сетях' },
        { value: 'Оборудование для бизнеса', label: 'Оборудование для бизнеса' },
        { value: 'Автоматизация процессов', label: 'Автоматизация процессов' },
        { value: 'Рынок труда', label: 'Рынок труда' },
        { value: 'Оценка недвижимости', label: 'Оценка недвижимости' },
        { value: 'Эко-услуги', label: 'Эко-услуги' },
        { value: 'Изучение иностранных языков', label: 'Изучение иностранных языков' },
        { value: 'Развлечения и досуг', label: 'Развлечения и досуг' },
        { value: 'Охрана окружающей среды', label: 'Охрана окружающей среды' },
        { value: 'Научные исследования', label: 'Научные исследования' },
        { value: 'Косметические услуги', label: 'Косметические услуги' },
        { value: 'Производственные услуги', label: 'Производственные услуги' },
        { value: 'Климатические технологии', label: 'Климатические технологии' },
        { value: 'Услуги по разработке продуктов', label: 'Услуги по разработке продуктов' },
        { value: 'Пищевая промышленность', label: 'Пищевая промышленность' },
        { value: 'Специализированное обучение', label: 'Специализированное обучение' },
        { value: 'Агентства по управлению имуществом', label: 'Агентства по управлению имуществом' },
        { value: 'Страхование', label: 'Страхование' },
        { value: 'Технический консалтинг', label: 'Технический консалтинг' },
        { value: 'Финансовое планирование', label: 'Финансовое планирование' },
        { value: 'Техническая поддержка', label: 'Техническая поддержка' },
        { value: 'Культурное сотрудничество', label: 'Культурное сотрудничество' },
        { value: 'Услуги по управлению проектами', label: 'Услуги по управлению проектами' },
        { value: 'Энергетическое консультирование', label: 'Энергетическое консультирование' },
        { value: 'Услуги по снабжению', label: 'Услуги по снабжению' },
        { value: 'Техническое обучение', label: 'Техническое обучение' },
        { value: 'Секретарские услуги', label: 'Секретарские услуги' },
        { value: 'Анализ данных', label: 'Анализ данных' }
    ];

    const [reg0firstName, setReg0FirstName] = React.useState<string>('');
    const [reg0lastName, setReg0LastName] = React.useState<string>('');
    const [reg0gmail, setReg0Gmail] = React.useState<string>('');
    const [reg0birthday, setReg0Birthday] = React.useState<string>('');
    const [reg0city, setReg0City] = React.useState<string>('');
    const [reg0businessKind, setReg0BusinessKind] = React.useState<string>('');
    const [reg0businessSphere, setReg0BusinessSphere] = React.useState<string>('');
    const [reg0instagram, setReg0Instagram] = React.useState<string>('');

    const [reg0sendingState, setReg0sendingState] = React.useState<boolean>(false);

    const validate = () => {
        return reg0firstName.length > 0 &&
            reg0lastName.length > 0 &&
            reg0gmail.length > 0 &&
            reg0birthday.length > 0 &&
            reg0city.length > 0 &&
            reg0businessKind.length > 0 &&
            reg0businessSphere.length > 0;
    }

    const sendRegInfo = () => {
        var formData = new FormData();
        formData.append('firstName', reg0firstName);
        formData.append('lastName', reg0lastName);
        formData.append('gmail', reg0gmail + '@gmail.com');
        formData.append('birthday', reg0birthday);
        formData.append('city', reg0city);
        formData.append('businessKind', reg0businessKind);
        formData.append('businessSphere', reg0businessSphere);
        formData.append('instagram', reg0instagram);
        if (referrerID != 0) {
            formData.append('referrer', referrerID.toString());
        }
        if (companyEventID != 0) {
            formData.append('event', companyEventID.toString());
        }
        //send information to server
        setReg0sendingState(true);
        fetch('api/account/sendRegInfo', {
            method: 'POST',
            body: formData
        })
            .then(onFinish)
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            })
            .finally(() => {
                setReg0sendingState(false);
            })
    }

    return (
        <div className="reg-module-container" style={{ height: "calc(100% - 54px)", display: visible ? "" : "none"}}>
            <div className="reg-module-space">
                <div className="reg-module-block">
                    <div><input placeholder="Введите Ваше имя" className="reg-module-input" onChange={(e) => setReg0FirstName(e.target.value)} value={reg0firstName} disabled={reg0sendingState} /></div>
                </div>
                <div className="reg-module-block">
                    <div><input placeholder="Введите Вашу фамилию" className="reg-module-input" onChange={(e) => setReg0LastName(e.target.value)} value={reg0lastName} disabled={reg0sendingState} /></div>
                </div>
                <div className="reg-module-block">
                    <div style={{ display: 'flex' }}><div style={{ flex: '1' }}>
                        <input placeholder="Введите эл. почту Google" className="reg-module-input" onChange={(e) => setReg0Gmail(e.target.value)} value={reg0gmail} disabled={reg0sendingState} /></div><div>@gmail.com</div></div>
                </div>
                <div className="reg-module-block">
                    <div className="reg-module-label">Дата рождения</div>
                    <div>
                        <Datepicker onValueChanged={setReg0Birthday} />
                    </div>
                </div>
                <div className="reg-module-block">
                    <div><input placeholder="Введите город проживания" className="reg-module-input" onChange={(e) => setReg0City(e.target.value)} value={reg0city} disabled={reg0sendingState} /></div>
                </div>
                <div className="reg-module-block">
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setReg0BusinessKind(e.target.value)}>
                            {businessKindOptions.map(bko => (
                                <option value={bko.value}>{bko.label}</option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className="reg-module-block">
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setReg0BusinessSphere(e.target.value)}>
                            {businessSphereOptions.map(bso => (
                                <option value={bso.value}>{bso.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="reg-module-block">
                    <div><input placeholder="Введите Instagram (без @)*" className="reg-module-input" onChange={(e) => setReg0Instagram(e.target.value)} value={reg0instagram} disabled={reg0sendingState} /></div>
                </div>
                <div className="reg-module-optional"><em>*Не обязательно</em></div>
            </div>
            <div className="reg-module-submit">
                <button className="btn btn-success" disabled={!validate()} onClick={sendRegInfo}>Отправить</button>
            </div>
            <div className="reg-module-privacy">
                Заполняя форму и нажимая кнопку «Отправить»,<br />Вы соглашаетесь с нашей<br />
                <a target="_blank" href="http://prodengi.site/privacy">Политикой конфиденциальности</a>
            </div>
        </div>
    )
};

export default Reg0;

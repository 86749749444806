import React from 'react';

// TypeScript types for the component props
interface YouTubeEmbedProps {
    videoId: string;
    width?: string | number;
    height?: string | number;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({
    videoId,
    width = '100%',
    height = '400px'
}) => {
    // YouTube embed URL
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div style={{ position: 'relative', paddingBottom: '25%', height: 0 }}>
            <iframe
                src={embedUrl}
                title="YouTube video player"
                width={width}
                height={height}
                frameBorder="0"
                style={{ position: 'absolute', top: 0, left: 0 }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default YouTubeEmbed;
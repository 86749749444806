import * as React from 'react';

interface ReactionsMenuProps {
    visible: boolean;
    my: boolean;
    onSetReaction: (reactionCode: number) => void;
}

const ReactionsMenu: React.FC<ReactionsMenuProps> = ({ visible, my, onSetReaction }) => {
    if (!visible) return null;

    return (
        <div style={{
            'display': 'flex', 'fontSize': '24px', 'background': 'white', 'borderRadius': '20px', 'position': 'absolute',
            marginTop: '-46px', right: my ? '10px' : '40px', 'paddingTop': '3px', 'paddingBottom': '3px'
        }}>
            <div style={{ 'cursor': 'pointer', 'paddingLeft': '7px' }} onClick={() => onSetReaction(0)}>👍</div>
            <div style={{ 'cursor': 'pointer', 'paddingLeft': '3px' }} onClick={() => onSetReaction(1)}>🔥</div>
            <div style={{ 'cursor': 'pointer', 'paddingLeft': '3px' }} onClick={() => onSetReaction(2)}>😁</div>
            <div style={{ 'cursor': 'pointer', 'paddingLeft': '3px' }} onClick={() => onSetReaction(3)}>😂</div>
            <div style={{ 'cursor': 'pointer', 'paddingLeft': '3px' }} onClick={() => onSetReaction(4)}>❤️</div>
            <div style={{ 'cursor': 'pointer', 'paddingLeft': '3px', 'paddingRight': '7px' }} onClick={() => onSetReaction(5)}>❤️‍🔥</div>
        </div>
    )
};

export default ReactionsMenu;

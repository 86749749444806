import DOMPurify from 'dompurify';
import * as React from 'react';
import ReactQuill from 'react-quill';
import App from '../../../App';
import useFetch from '../../../hooks/useFetch';
import useFetchWithTimeout from '../../../hooks/useFetchWithTimeout';
import { Account, MillionaireMorningItem, Reflection } from '../../../store/Models';
import { debounce, generateUUID, makeDateTime } from '../../../utils';
import InputComponent from '../components/simple/InputComponent';
import SendMenu from '../SendMenu';

interface InputPanelProps {
    state: string;
    currentUser: Account;
    selectedReflection: Reflection | null;
    selectedMillionaireMorningItem: MillionaireMorningItem | null;
    onAddDefaultMillionaireMorningItemCallback: (item: MillionaireMorningItem) => void;
    onEnrichMillionaireMorningItemCallback: (item: MillionaireMorningItem) => void;
    onAddDefaultStreamCallback: (item: Reflection) => void;
    onEnrichStreamCallback: (item: Reflection) => void;
    onAddDefaultReflectionCallback: (item: Reflection) => void;
    onEnrichReflectionCallback: (item: Reflection) => void;
    showSendMenu: boolean;
    onOpenContextMenu: () => void;
    onCloseContextMenu: () => void;
    editMode: boolean;
    replyMode: boolean;
    onStopEdit: () => void;
    onStopReply: () => void;
    mainLayoutRef: React.RefObject<HTMLDivElement>;
}

const InputPanel: React.FC<InputPanelProps> = ({ state, currentUser, selectedReflection, selectedMillionaireMorningItem,
    onAddDefaultMillionaireMorningItemCallback, onEnrichMillionaireMorningItemCallback,
    onAddDefaultStreamCallback, onEnrichStreamCallback,
    onAddDefaultReflectionCallback, onEnrichReflectionCallback,
    showSendMenu, onOpenContextMenu, onCloseContextMenu,
    editMode, replyMode, onStopEdit, onStopReply,
    mainLayoutRef }) => {

    const fetch = useFetch();
    const fetchWithTimeout = useFetchWithTimeout();

    const editorWrapperRef = React.useRef<HTMLDivElement | null>(null);
    const editableAreaRef = React.useRef<HTMLDivElement | null>(null);

    const quillRef = React.useRef<ReactQuill>(null);

    const [currentText, setCurrentText2] = React.useState<string>('');

    const pressTimer = React.useRef(0);

    const testAccountIds = [1000029, 1000122, 1000129];

    const sendProcessing = React.useRef<boolean>(false);

    const appState = {
        MillionaireMorning: 'MILLIONAIRE_MORNING',
        Streams: 'STREAMS',
        Reflections: 'REFLECTIONS',
        Challenge: 'CHALLENGE',
        AccountProfile: 'ACCOUNT_PROFILE',
        Admin: 'ADMIN',
        Test: 'TEST',
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        RegDeny: 'DENY',
        Renewal: 'RENEWAL'
    }
    /*
    if (state === appState.MillionaireMorning && currentUser.isAdmin == 0 || state === appState.Streams && currentUser.isAdmin == 0) {
        return null;
    }
    */

    const setCurrentText = (text: string) => {
        if (editableAreaRef.current != null) {
            editableAreaRef.current.innerHTML = text;
        }
        else if (quillRef.current != null) {
            quillRef.current.getEditor().root.innerHTML = text;
        }
        setCurrentText2(text);
    };

    const getPlainTextLength = (): number => {
        if (quillRef.current != null) {
            var textNode = quillRef.current.getEditingArea().textContent;
            if (textNode != null) {
                return textNode.length;
            }
        }
        return 0;
    }

    const sendClick = (visibilityMode: number) => {
        if (selectedReflection != null) {
            sendEdit(visibilityMode);
        }
        else {
            write(visibilityMode);
        }
    };

    const processWriteTask = (formData: FormData) => {
        fetchWithTimeout('api/millionairemorningitem/writeTask', {
            method: 'POST',
            body: formData
        }, 5000)
            .then(response => response.json() as Promise<MillionaireMorningItem>)
            .then(data => onEnrichMillionaireMorningItemCallback(data))
            .catch(() => {
                processWriteTask(formData);
            });
    };

    const processWriteStream = (formData: FormData) => {
        fetchWithTimeout('api/reflection/writeReflection', {
            method: 'POST',
            body: formData
        }, 5000)
            .then(response => response.json() as Promise<Reflection>)
            .then(data => onEnrichStreamCallback(data))
            .catch(() => {
                processWriteStream(formData);
            });
    }

    const processWriteReflection = (formData: FormData) => {
        fetchWithTimeout('api/reflection/writeReflection', {
            method: 'POST',
            body: formData
        }, 5000)
            .then(response => response.json() as Promise<Reflection>)
            .then(data => onEnrichReflectionCallback(data))
            .catch(() => {
                processWriteReflection(formData);
            });
    }

    const write = (visibilityMode: number) => {
        var formData = new FormData();
        formData.append('ReflectionContent', currentText);
        if (state === appState.Streams || state === appState.Reflections) {
            formData.append('ReflectionVisibilityMode', visibilityMode.toString());
            formData.append('TrackID', generateUUID());
        }

        setCurrentText(''); //clear input and immediately add item to list visually, then scroll to bottom of list (via on add callback)
        sendProcessing.current = false;

        let defaultDate = new Date();
        defaultDate.setHours(defaultDate.getHours() + defaultDate.getTimezoneOffset() / 60);

        switch (state) {
            case appState.MillionaireMorning:
                onAddDefaultMillionaireMorningItemCallback({
                    id: 0,
                    postPreview: {
                        id: 0,
                        post: {
                            id: 0,
                            publishAccount: currentUser,
                            publishDateTime: defaultDate,
                            contentType: '0',
                            contentText: currentText,
                            contentRef: '',
                            watchCount: 0,
                            readCount: 0,
                            fragments: []
                        },
                        previewHeader: '',
                        previewImageFileName: '',
                        previewText: ''
                    },
                    taskHeader: '',
                    isCompleted: 0,
                    isUnread: false,
                    isRead: 0
                });
                processWriteTask(formData);
                break;
            case appState.Streams:
                onAddDefaultStreamCallback({
                    id: 0,
                    post: {
                        id: 0,
                        publishAccount: currentUser,
                        publishDateTime: defaultDate,
                        contentType: '0',
                        contentText: currentText,
                        contentRef: '',
                        watchCount: 0,
                        readCount: 0,
                        fragments: []
                    },
                    replyTask: null,
                    visibilityMode: visibilityMode,
                    class: currentUser.class,
                    isModified: 0,
                    replyReflection: null,
                    fileCount: 0,
                    reaction0: 0,
                    reaction1: 0,
                    reaction2: 0,
                    reaction3: 0,
                    reaction4: 0,
                    reaction5: 0,
                    my0: 0,
                    my1: 0,
                    my2: 0,
                    my3: 0,
                    my4: 0,
                    my5: 0,
                    isRead: 0,
                    value: null
                });
                processWriteStream(formData);
                break;
            case appState.Reflections:
                onAddDefaultReflectionCallback({
                    id: 0,
                    post: {
                        id: 0,
                        publishAccount: currentUser,
                        publishDateTime: defaultDate,
                        contentType: '0',
                        contentText: currentText,
                        contentRef: '',
                        watchCount: 0,
                        readCount: 0,
                        fragments: []
                    },
                    replyTask: null,
                    visibilityMode: visibilityMode,
                    class: currentUser.class,
                    isModified: 0,
                    replyReflection: null,
                    fileCount: 0,
                    reaction0: 0,
                    reaction1: 0,
                    reaction2: 0,
                    reaction3: 0,
                    reaction4: 0,
                    reaction5: 0,
                    my0: 0,
                    my1: 0,
                    my2: 0,
                    my3: 0,
                    my4: 0,
                    my5: 0,
                    isRead: 0,
                    value: null
                });
                processWriteReflection(formData);
                break;
            default:
                break;
        }
    }

    const sendEdit = (reflectionVisibilityMode: number) => {
        if (selectedReflection != null) {
            var formData = new FormData();
            formData.append('ReflectionID', selectedReflection.id.toString());
            formData.append('PostID', selectedReflection.post.id.toString());
            formData.append('ReflectionContent', currentText);
            formData.append('ReflectionVisibilityMode', reflectionVisibilityMode.toString());
            formData.append('IsContentChanged', (currentText != selectedReflection.post.contentText.replace(/\\n/g, "\n")).toString());

            //manually and immediately edit reflection on user interface
            if (selectedReflection != null) {
                selectedReflection.post.contentText = currentText;
                selectedReflection.isModified = formData.get("IsContentChanged") == "true" ? 1 : 0;
                selectedReflection.visibilityMode = reflectionVisibilityMode;
            }

            stopEdit();

            //send information to server
            fetch('api/reflection/editReflection', {
                method: 'POST',
                body: formData
            });
        }
    }

    const sendEditMillionaireMorningItem = () => {
        if (selectedMillionaireMorningItem != null) {
            var formData = new FormData();
            formData.append('PostID', selectedMillionaireMorningItem.postPreview.post.id.toString());
            formData.append('ReflectionContent', currentText);

            selectedMillionaireMorningItem.postPreview.post.contentText = currentText;

            stopEdit();

            //send information to server
            fetch('api/millionairemorningitem/editTask', {
                method: 'POST',
                body: formData
            });
        }
    }

    const planeClick = () => {
        if (selectedReflection != null) {
            if (editMode) {
                sendEdit(selectedReflection.visibilityMode);
            }
            else if (replyMode) {
                sendReply();
            }
        }
        else if (selectedMillionaireMorningItem != null) {
            sendEditMillionaireMorningItem();
        }
        else {
            if (state === appState.Streams) {
                sendClick(110);
            }
            else {
                sendClick(1);
            }
        }
    }

    const sendReply = () => {
        var formData = new FormData();
        formData.append('ReflectionContent', currentText);
        formData.append('ReflectionVisibilityMode', '1');
        formData.append('TrackID', generateUUID());
        if (selectedReflection != null) {
            formData.append('ReplyReflectionID', selectedReflection.id.toString());
        }

        stopReply();

        let defaultDate = new Date();
        defaultDate.setHours(defaultDate.getHours() + defaultDate.getTimezoneOffset() / 60);

        onAddDefaultReflectionCallback({
            id: 0,
            post: {
                id: 0,
                publishAccount: currentUser,
                publishDateTime: defaultDate,
                contentType: '0',
                contentText: currentText,
                contentRef: '',
                watchCount: 0,
                readCount: 0,
                fragments: []
            },
            replyTask: null,
            visibilityMode: 0,
            class: currentUser.class,
            isModified: 0,
            replyReflection: selectedReflection,
            fileCount: 0,
            reaction0: 0,
            reaction1: 0,
            reaction2: 0,
            reaction3: 0,
            reaction4: 0,
            reaction5: 0,
            my0: 0,
            my1: 0,
            my2: 0,
            my3: 0,
            my4: 0,
            my5: 0,
            isRead: 0,
            value: null
        });
        processWriteReflection(formData);
    }

    const onSendTouchStart = () => {
        if (state === appState.MillionaireMorning || replyMode)
            return;
        pressTimer.current = window.setTimeout(() => {
            onOpenContextMenu();
        }, 800);
    }

    const onSendContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (state === appState.MillionaireMorning || replyMode)
            return;
        onOpenContextMenu();
    }

    const onSendTouchEnd = () => {
        if (state === appState.MillionaireMorning || replyMode)
            return;
        window.clearTimeout(pressTimer.current);
    }

    const stopEdit = () => {
        setCurrentText('');
        sendProcessing.current = false;
        onStopEdit();
    }

    const stopReply = () => {
        setCurrentText('');
        sendProcessing.current = false;
        onStopReply();
    }

    const textToValue = (text: string): string => {
        return text.replace(/\\n/g, "\n");
    }

    const textToRows = (text: string): number => {
        var split = text.split('\\n');
        return Math.min(10, split.length);
    }

    React.useEffect(() => {
        if (editMode || replyMode) {
            if (editableAreaRef.current != null) {
                editableAreaRef.current.focus();
                if (editMode && selectedReflection != null) {
                    setCurrentText(selectedReflection.post.contentText);
                    editableAreaRef.current.scrollTop = editableAreaRef.current.scrollHeight;
                }
            }
            else if (quillRef.current != null) {
                var editor = quillRef.current.getEditor();
                editor.focus();
                if (editMode && selectedReflection != null) {
                    setCurrentText(selectedReflection.post.contentText);
                    window.setTimeout(() => {
                        editor.setSelection(editor.getLength(), 0);
                    }, 0);
                }
            }
        }
    }, [editMode, replyMode]);

    const restoreLayout = () => {
        window.scrollTo(0, 0);
        if (quillRef.current != null && editorWrapperRef.current != null && window.Telegram.WebApp.platform === 'ios') {
            document.body.style.overflow = '';
            editorWrapperRef.current.style.paddingBottom = '';
            if (mainLayoutRef.current != null) {
                mainLayoutRef.current.style.position = ''; //iOS lifehacks
                mainLayoutRef.current.style.top = '';
            }
        }
        if (quillRef.current != null) {
            quillRef.current.getEditingArea().scrollTop = quillRef.current.getEditingArea().scrollHeight;
        }
    }

    const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        // Try to get HTML content
        let html = event.clipboardData.getData('text/html');

        // Fallback to plain text if HTML is not available
        if (!html) {
            html = event.clipboardData.getData('text/plain');
            html = DOMPurify.sanitize(html);
            document.execCommand('insertText', false, html);
        } else {
            html = DOMPurify.sanitize(html, { ALLOWED_TAGS: ['b', 'i', 'strong', 'em', 'br', 'div', 'p'], ALLOWED_ATTR: [] });
            document.execCommand('insertHTML', false, html);
        }
    }

    const onFocus = () => {
        if (quillRef.current != null && editorWrapperRef.current != null && window.Telegram.WebApp.platform === 'ios') {
            document.body.style.overflow = 'hidden';
            editorWrapperRef.current.style.setProperty('padding-bottom', Math.ceil(0.367 * window.innerHeight) + 'px', 'important');
            if (mainLayoutRef.current != null && state !== appState.Streams) {
                mainLayoutRef.current.style.position = 'fixed'; //iOS lifehacks
                mainLayoutRef.current.style.top = '0px';
            }
        }
    }

    /*
    const onContentEditableSelect = (event: React.FormEvent<HTMLDivElement>) => {
        const selection = window.getSelection();

        if (!selection || selection.rangeCount === 0) {
            setShowFormattingMenu(false);
            return;
        }

        const range = selection.getRangeAt(0); // Get the first range of the selection

        if (event.currentTarget) {
            const startOffset = getCaretCharacterOffsetWithin(event.currentTarget, range, true);
            const endOffset = getCaretCharacterOffsetWithin(event.currentTarget, range, false);
            if (endOffset - startOffset > 0) {
                setShowFormattingMenu(true);
            }
            else {
                setShowFormattingMenu(false);
            }
        }
    }
    */

    /*
     * textarea block
     * <textarea ref={textareaRef} className="form-control" id="messageInput" value={textToValue(currentText)}
                    onChange={(e) => onTextChange(e.target.value)}
                    onSelect={onTextSelect}
                    rows={textToRows(currentText)} placeholder={state === appState.MillionaireMorning ? "Напишите задание здесь" :
                        state === appState.Streams ? "Разместите сообщение от Администрации здесь" :
                        "Напишите Вашу рефлексию здесь"}
                    onBlur={restoreLayout}></textarea>
     * 
     */

    return (
        <div className="input-panel" style={{
            'display':
                state === appState.MillionaireMorning && currentUser.isAdmin == 0 || state === appState.Streams && currentUser.isAdmin == 0 || state === appState.Challenge || state === appState.AccountProfile || state === appState.Test
                    || state === appState.Reg0 || state === appState.Reg1 || state === appState.Reg2 || state === appState.RegDeny || state === appState.Admin || state === appState.Renewal ? 'none' : ''
        }}>
            {editMode && <div className="edit-group"><div style={{ 'flex': '1', 'fontWeight': 'bold' }}> Редактирование {state === appState.Reflections ? "рефлексии" : appState.Streams ? "сообщения" : "задания"}:</div>
                <div style={{ 'display': 'flex', 'alignItems': 'flex-end', 'justifyContent': 'center' }}>
                    <button className="btn btn-default" type="button" onClick={stopEdit}>
                        <i className="fas fa-xmark"></i>
                    </button>
                </div></div>}
            {(replyMode && selectedReflection != null) && <div className="edit-group"><div style={{ 'flex': '1', 'fontWeight': 'bold' }}> Ответить {selectedReflection.post.publishAccount.name}: </div>
                <div style={{ 'display': 'flex', 'alignItems': 'flex-end', 'justifyContent': 'center' }}>
                    <button className="btn btn-default" type="button" onClick={stopReply}>
                        <i className="fas fa-xmark"></i>
                    </button>
                </div></div>}
            <div style={{ 'width': '100%', 'display': 'flex' }} ref={editorWrapperRef}>
                <InputComponent inputRef={quillRef}
                    placeholder={state === appState.MillionaireMorning ? "Напишите задание здесь" :
                        state === appState.Streams ? "Разместите эфир здесь" :
                            "Напишите Вашу рефлексию здесь"} onInput={setCurrentText2} onFocus={onFocus} onBlur={() => {
                                if (sendProcessing.current) {
                                    window.setTimeout(restoreLayout, 0);
                                }
                                else restoreLayout();
                            }}
                />
                {getPlainTextLength() > 50 && <div className="input-panel-send-block">
                    <div className="btn-send"
                        onTouchStart={onSendTouchStart} onContextMenu={onSendContextMenu} onTouchEnd={onSendTouchEnd}
                        onMouseDown={() => {
                            if (!sendProcessing.current && getPlainTextLength() > 50) {
                                sendProcessing.current = true;
                                planeClick();
                            }
                        }}>
                        <i className="fas fa-arrow-up"></i>
                    </div>
                </div>}
            </div>
            {showSendMenu && <SendMenu visible={true}
                state={state}
                currentMode={selectedReflection != null ? selectedReflection.visibilityMode : - 1}
                currentUser={currentUser}
                onSelectMode={sendClick} onClose={onCloseContextMenu} editorWrapperRef={editorWrapperRef} />}
        </div>
    );
};

export default InputPanel;

import * as React from 'react';

interface CountdownProps {
    startValue: number;
    endValue: number;
    onElapsed: () => void;
}

const Countdown: React.FC<CountdownProps> = ({ startValue, endValue, onElapsed }) => {

    const [remainingSeconds, setRemainingSeconds] = React.useState<number>(startValue);
    const [isRunning, setIsRunning] = React.useState(true);

    const stopInterval = () => setIsRunning(false);

    const tick = () => {
        setRemainingSeconds(x => x - 1);
    }

    React.useEffect(() => {
        if (!isRunning) return;

        const intervalId = window.setInterval(tick, 1000);

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [isRunning]);

    React.useEffect(() => {
        if (remainingSeconds <= endValue) {
            stopInterval();
            onElapsed();
        }
    }, [remainingSeconds]);

    return (
        <img width="100" height="100" src="assets/banners/591.gif" style={{ 'margin': '10px 0' }} />
        )
};

export default Countdown;
